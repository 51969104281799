import * as i0 from '@angular/core';
import { EventEmitter, Component, ViewChild, Input, Output, NgModule } from '@angular/core';
import * as i3 from '@angular/forms';
import { NgControl, NgModel, FormsModule } from '@angular/forms';
import * as i1 from '@capturum/ui/api';
import { ValueAccessorBase, CapturumSharedModule } from '@capturum/ui/api';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i4 from 'primeng/checkbox';
import { CheckboxModule } from 'primeng/checkbox';
function CapturumCheckboxComponent_cap_validator_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "cap-validator", 3);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("control", ctx_r0.control)("customName", ctx_r0.label);
  }
}
class CapturumCheckboxComponent extends ValueAccessorBase {
  constructor(injector, validatorService) {
    super();
    this.injector = injector;
    this.validatorService = validatorService;
    /**
     * When present, it specifies that the element should be disabled
     */
    this.disabled = false;
    /**
     * Allows to select a boolean value instead of multiple values
     */
    this.binary = true;
    /**
     * Index of the element in tabbing order
     */
    this.tabindex = null;
    /**
     * Style class of the component
     */
    this.styleClass = '';
    /**
     * Icon class of the checkbox icon
     */
    this.checkboxIcon = 'pi pi-check';
    /**
     * Value in checked state.
     */
    this.falseValue = false;
    /**
     * Value in unchecked state.
     */
    this.trueValue = true;
    /**
     * Callback to invoke on checkbox click
     */
    this.change = new EventEmitter();
    this.setValueTransform = value => {
      return this.trueValue !== true || this.falseValue !== false ? value : Boolean(+value);
    };
  }
  ngAfterViewInit() {
    setTimeout(() => {
      const ngControl = this.injector.get(NgControl, null);
      this.control = this.validatorService.extractFormControl(ngControl);
    });
  }
  static {
    this.ɵfac = function CapturumCheckboxComponent_Factory(t) {
      return new (t || CapturumCheckboxComponent)(i0.ɵɵdirectiveInject(i0.Injector), i0.ɵɵdirectiveInject(i1.ValidatorService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CapturumCheckboxComponent,
      selectors: [["cap-checkbox"]],
      viewQuery: function CapturumCheckboxComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(NgModel, 7);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.model = _t.first);
        }
      },
      inputs: {
        checkboxValue: "checkboxValue",
        name: "name",
        disabled: "disabled",
        binary: "binary",
        label: "label",
        tabindex: "tabindex",
        inputId: "inputId",
        style: "style",
        styleClass: "styleClass",
        labelStyleClass: "labelStyleClass",
        checkboxIcon: "checkboxIcon",
        falseValue: "falseValue",
        trueValue: "trueValue"
      },
      outputs: {
        change: "change"
      },
      features: [i0.ɵɵProvidersFeature([ValueAccessorBase.getProviderConfig(CapturumCheckboxComponent)]), i0.ɵɵInheritDefinitionFeature],
      decls: 3,
      vars: 14,
      consts: [[1, "form-element"], [3, "onChange", "ngModelChange", "ngModel", "binary", "checkboxIcon", "label", "disabled", "value", "styleClass", "tabIndex", "trueValue", "falseValue", "labelStyleClass"], [3, "control", "customName", 4, "ngIf"], [3, "control", "customName"]],
      template: function CapturumCheckboxComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0)(1, "p-checkbox", 1);
          i0.ɵɵlistener("onChange", function CapturumCheckboxComponent_Template_p_checkbox_onChange_1_listener($event) {
            return ctx.change.emit($event);
          });
          i0.ɵɵtwoWayListener("ngModelChange", function CapturumCheckboxComponent_Template_p_checkbox_ngModelChange_1_listener($event) {
            i0.ɵɵtwoWayBindingSet(ctx.value, $event) || (ctx.value = $event);
            return $event;
          });
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(2, CapturumCheckboxComponent_cap_validator_2_Template, 1, 2, "cap-validator", 2);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵadvance();
          i0.ɵɵstyleMap(ctx.style);
          i0.ɵɵtwoWayProperty("ngModel", ctx.value);
          i0.ɵɵproperty("binary", ctx.binary)("checkboxIcon", ctx.checkboxIcon)("label", ctx.label)("disabled", ctx.disabled)("value", ctx.checkboxValue)("styleClass", ctx.styleClass)("tabIndex", ctx.tabindex)("trueValue", ctx.trueValue)("falseValue", ctx.falseValue)("labelStyleClass", ctx.labelStyleClass);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.control);
        }
      },
      dependencies: [i2.NgIf, i3.NgControlStatus, i3.NgModel, i1.CapturumValidatorComponent, i4.Checkbox]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CapturumCheckboxComponent, [{
    type: Component,
    args: [{
      selector: 'cap-checkbox',
      providers: [ValueAccessorBase.getProviderConfig(CapturumCheckboxComponent)],
      template: "<div class=\"form-element\">\n    <p-checkbox (onChange)=\"change.emit($event)\"\n                [(ngModel)]=\"value\"\n                [binary]=\"binary\"\n                [checkboxIcon]=\"checkboxIcon\"\n                [label]=\"label\"\n                [disabled]=\"disabled\"\n                [value]=\"checkboxValue\"\n                [styleClass]=\"styleClass \"\n                [style]=\"style\"\n                [tabIndex]=\"tabindex\"\n                [trueValue]=\"trueValue\"\n                [falseValue]=\"falseValue\"\n                [labelStyleClass]=\"labelStyleClass\">\n    </p-checkbox>\n\n    <cap-validator *ngIf=\"control\" [control]=\"control\" [customName]=\"label\"></cap-validator>\n</div>\n"
    }]
  }], () => [{
    type: i0.Injector
  }, {
    type: i1.ValidatorService
  }], {
    model: [{
      type: ViewChild,
      args: [NgModel, {
        static: true
      }]
    }],
    checkboxValue: [{
      type: Input
    }],
    name: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    binary: [{
      type: Input
    }],
    label: [{
      type: Input
    }],
    tabindex: [{
      type: Input
    }],
    inputId: [{
      type: Input
    }],
    style: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    labelStyleClass: [{
      type: Input
    }],
    checkboxIcon: [{
      type: Input
    }],
    falseValue: [{
      type: Input
    }],
    trueValue: [{
      type: Input
    }],
    change: [{
      type: Output
    }]
  });
})();
class CapturumCheckboxModule {
  static {
    this.ɵfac = function CapturumCheckboxModule_Factory(t) {
      return new (t || CapturumCheckboxModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CapturumCheckboxModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, FormsModule, CapturumSharedModule, CheckboxModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CapturumCheckboxModule, [{
    type: NgModule,
    args: [{
      declarations: [CapturumCheckboxComponent],
      exports: [CapturumCheckboxComponent],
      imports: [CommonModule, FormsModule, CapturumSharedModule, CheckboxModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CapturumCheckboxComponent, CapturumCheckboxModule };
