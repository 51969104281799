import * as i0 from '@angular/core';
import { EventEmitter, Component, Input, Output, ViewChild, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
const _c0 = ["inputRef"];
function CapturumFileUploadComponent_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "i", 6);
    i0.ɵɵelementStart(1, "p");
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(ctx_r1.label);
  }
}
class CapturumFileUploadComponent {
  constructor() {
    this.maxSize = 0; // 0 (default) to disable limit, in bytes
    this.label = 'Upload';
    this.multiple = false;
    this.onFileChange = new EventEmitter();
  }
  /**
   * Set the dragover property to true when dragging a file over the component
   *
   * @param event
   */
  onDragOver(event) {
    event.preventDefault();
    event.stopPropagation();
    this.dragover = true;
  }
  /**
   * Set the dragover property to false when leaving the component while dragging a file
   *
   * @param event
   */
  onDragLeave(event) {
    event.preventDefault();
    event.stopPropagation();
    this.dragover = false;
  }
  /**
   * Emit drop event when a file is dropped onto the component
   *
   * @param event
   */
  onDrop(event) {
    event.preventDefault();
    event.stopPropagation();
    this.dragover = false;
    const {
      dataTransfer: {
        files
      }
    } = event;
    this.handleFilesChange(files);
  }
  /**
   * Emit change event when file is selected
   *
   * @param event
   */
  onChange(event) {
    const {
      target: {
        files
      }
    } = event;
    this.handleFilesChange(files);
  }
  handleFilesChange(files) {
    if (files?.length > 0 && this.areFileSizesValid(files)) {
      this.onFileChange.emit(Array.from(files));
      this.inputRef.nativeElement.value = '';
    }
  }
  areFileSizesValid(files) {
    if (this.maxSize === 0) {
      return true;
    }
    return Array.from(files).every(file => file.size <= this.maxSize);
  }
  static {
    this.ɵfac = function CapturumFileUploadComponent_Factory(t) {
      return new (t || CapturumFileUploadComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CapturumFileUploadComponent,
      selectors: [["cap-file-upload"]],
      viewQuery: function CapturumFileUploadComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c0, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.inputRef = _t.first);
        }
      },
      inputs: {
        id: "id",
        accept: "accept",
        maxSize: "maxSize",
        label: "label",
        styleClass: "styleClass",
        multiple: "multiple",
        uploaderContent: "uploaderContent"
      },
      outputs: {
        onFileChange: "onFileChange"
      },
      decls: 7,
      vars: 8,
      consts: [["defaultUploaderContent", ""], ["inputRef", ""], [3, "ngClass"], [1, "prime-file-upload", "text-center", 3, "dragleave", "dragover", "drop", "for"], [3, "ngTemplateOutlet"], ["type", "file", 1, "d-none", 3, "change", "multiple", "id", "accept"], [1, "fas", "fa-upload"]],
      template: function CapturumFileUploadComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵelementStart(0, "div", 2)(1, "label", 3);
          i0.ɵɵlistener("dragleave", function CapturumFileUploadComponent_Template_label_dragleave_1_listener($event) {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.onDragLeave($event));
          })("dragover", function CapturumFileUploadComponent_Template_label_dragover_1_listener($event) {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.onDragOver($event));
          })("drop", function CapturumFileUploadComponent_Template_label_drop_1_listener($event) {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.onDrop($event));
          });
          i0.ɵɵelementContainer(2, 4);
          i0.ɵɵtemplate(3, CapturumFileUploadComponent_ng_template_3_Template, 3, 1, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(5, "input", 5, 1);
          i0.ɵɵlistener("change", function CapturumFileUploadComponent_Template_input_change_5_listener($event) {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.onChange($event));
          });
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          const defaultUploaderContent_r3 = i0.ɵɵreference(4);
          i0.ɵɵproperty("ngClass", ctx.styleClass);
          i0.ɵɵadvance();
          i0.ɵɵclassProp("dragover", ctx.dragover);
          i0.ɵɵproperty("for", ctx.id);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngTemplateOutlet", ctx.uploaderContent || defaultUploaderContent_r3);
          i0.ɵɵadvance(3);
          i0.ɵɵproperty("multiple", ctx.multiple)("id", ctx.id)("accept", ctx.accept);
        }
      },
      dependencies: [i1.NgClass, i1.NgTemplateOutlet]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CapturumFileUploadComponent, [{
    type: Component,
    args: [{
      selector: 'cap-file-upload',
      template: "<div [ngClass]=\"styleClass\">\n    <label [for]=\"id\" class=\"prime-file-upload text-center\" (dragleave)=\"onDragLeave($event)\"\n           [class.dragover]=\"dragover\"\n           (dragover)=\"onDragOver($event)\" (drop)=\"onDrop($event)\">\n\n        <ng-container [ngTemplateOutlet]=\"uploaderContent || defaultUploaderContent\"></ng-container>\n\n        <ng-template #defaultUploaderContent>\n            <i class=\"fas fa-upload\"></i>\n\n            <p>{{ label }}</p>\n        </ng-template>\n    </label>\n\n    <input #inputRef\n           type=\"file\"\n           [multiple]=\"multiple\"\n           [id]=\"id\"\n           class=\"d-none\"\n           [accept]=\"accept\"\n           (change)=\"onChange($event)\" />\n</div>\n"
    }]
  }], null, {
    id: [{
      type: Input
    }],
    accept: [{
      type: Input
    }],
    maxSize: [{
      type: Input
    }],
    label: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    multiple: [{
      type: Input
    }],
    uploaderContent: [{
      type: Input
    }],
    onFileChange: [{
      type: Output
    }],
    inputRef: [{
      type: ViewChild,
      args: ['inputRef', {
        static: false
      }]
    }]
  });
})();
class CapturumFileUploadModule {
  static {
    this.ɵfac = function CapturumFileUploadModule_Factory(t) {
      return new (t || CapturumFileUploadModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CapturumFileUploadModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CapturumFileUploadModule, [{
    type: NgModule,
    args: [{
      declarations: [CapturumFileUploadComponent],
      exports: [CapturumFileUploadComponent],
      imports: [CommonModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CapturumFileUploadComponent, CapturumFileUploadModule };
