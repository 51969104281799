import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Optional, Inject, Directive, NgModule } from '@angular/core';
import * as i2 from '@angular/common/http';
import { HttpHeaders, HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BehaviorSubject, Observable, of, interval, throwError, Subject, merge, fromEvent, from, bufferTime, filter as filter$1, tap } from 'rxjs';
import { map, catchError, switchMap, filter, takeUntil, finalize, takeWhile, distinctUntilChanged, tap as tap$1 } from 'rxjs/operators';
import { jwtDecode } from 'jwt-decode';
import * as i2$1 from '@angular/router';
import * as i3 from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
const AUTH_USE_REFRESH_TOKENS = new InjectionToken('use auth refresh token');
class AuthConfig {
  constructor() {
    this.baseUrl = '';
    this.production = false;
    this.authRejectionRoute = '/login';
    this.unauthenticatedRejectionRoute = '/';
    this.authRefreshToken = {
      useRefreshToken: false,
      offsetSeconds: 60,
      intervalSeconds: 3
    };
  }
}
function getJwtTokenExpirationDate(token) {
  if (!token || !token.hasOwnProperty('exp')) {
    return null;
  }
  const date = new Date(0);
  date.setUTCSeconds(token['exp']);
  return date;
}
function isJwtTokenExpired(token, offsetSeconds) {
  if (!token) {
    return true;
  }
  const date = getJwtTokenExpirationDate(token);
  offsetSeconds = offsetSeconds || 0;
  if (date === null) {
    return false;
  }
  return !(date.valueOf() > new Date().valueOf() + offsetSeconds * 1000);
}
class AuthService {
  constructor(config, http, authRefreshToken) {
    this.config = config;
    this.http = http;
    this.authRefreshToken = authRefreshToken;
    this.isRefreshTokenWachterStarted = new BehaviorSubject(false);
    this.authenticationState$ = new BehaviorSubject(this.isAuthenticated());
    this.offsetSeconds = this.authRefreshToken?.offsetSeconds || 60;
    this.intervalSeconds = this.authRefreshToken?.intervalSeconds || 30;
  }
  /**
   * Get the authentication state as Observable
   *
   * @return Observable<boolean>
   */
  getAuthenticationState() {
    return this.authenticationState$.asObservable();
  }
  /**
   * Set the authentication state,
   * this method should not be called manually from application as this has unwanted side effects
   *
   * @param state: boolean
   * @return void
   */
  setAuthenticationState(state) {
    this.authenticationState$.next(state);
  }
  /**
   * Login the user
   *
   * @param email: string
   * @param password: string
   * @param options: any = null
   * @return Observable<boolean>
   */
  login(email, password, options = null) {
    const customHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json'
    });
    return this.http.post(`${this.config.baseUrl}/auth/login`, {
      email: email,
      password: password,
      ...options
    }, {
      headers: this.getHeaders(customHeaders)
    }).pipe(map(result => {
      if (result.status === 'ok') {
        if (result.user) {
          if (result.user.roles !== undefined && result.user.roles.data !== undefined) {
            result.user.roles = result.user.roles.data;
          }
          const user = result.user;
          localStorage.setItem('user', JSON.stringify(user));
        }
        localStorage.setItem('token', result.token);
        if (result.refresh_token) {
          localStorage.setItem('refresh_token', result.refresh_token);
        }
        this.handleSuccessfulLogin(result);
        this.setAuthenticationState(true);
        return result;
      }
      return null;
    }, catchError(this.handleError)));
  }
  /**
   * Logout the user
   *
   * @return Observable<boolean>
   */
  logout() {
    return this.http.post(`${this.config.baseUrl}/auth/logout`, null, {
      headers: this.getHeaders()
    }).pipe(map(result => {
      if (result.success) {
        this.removeStaleCredentials();
        this.setAuthenticationState(false);
        this.isRefreshTokenWachterStarted.next(false);
        return true;
      }
      return false;
    }), catchError(this.handleError));
  }
  /**
   * Recover the users' password
   *
   * @param email: string
   * @return Observable<boolean>
   */
  recoverPassword(email) {
    return this.http.post(`${this.config.baseUrl}/auth/recovery`, {
      email: email
    }, {
      headers: this.getHeaders()
    }).pipe(map(result => {
      return result.status === 'ok';
    }), catchError(this.handleError));
  }
  /**
   * Reset the users' password
   *
   * @param data: SetPasswordData
   * @return Observable<any>
   */
  resetPassword(data) {
    return this.http.post(`${this.config.baseUrl}/auth/reset`, data, {
      headers: this.getHeaders()
    }).pipe(map(result => {
      return result.status === 'ok';
    }), catchError(this.handleError));
  }
  /**
   * Activate the users' profile
   *
   * @param data: SetPasswordData
   * @return Observable<any>
   */
  activate(data) {
    return this.http.post(`${this.config.baseUrl}/auth/activate`, data, {
      headers: this.getHeaders()
    }).pipe(catchError(this.handleError));
  }
  /**
   * Is the user authenticated based on token in localStorage
   *
   * @return boolean
   */
  isAuthenticated() {
    return !!localStorage.getItem('token');
  }
  /**
   * Get the token from localStorage
   *
   * @return string
   */
  getToken() {
    return localStorage.getItem('token');
  }
  /**
   * Get the user from localStorage
   *
   * @return User
   */
  getUser() {
    return JSON.parse(localStorage.getItem('user'));
  }
  /**
   * update user details in localStorage
   * @returns Observable<boolean>
   */
  setUser(user) {
    return new Observable(subscriber => {
      localStorage.setItem('user', JSON.stringify(user));
      subscriber.next(true);
    });
  }
  /**
   * patch user details in localStorage
   * @returns Observable
   */
  patchUser(user) {
    return new Observable(subscriber => {
      if (!!localStorage.getItem('user')) {
        const prevUser = JSON.parse(localStorage.getItem('user'));
        const patchedUser = {
          ...prevUser,
          ...user
        };
        localStorage.setItem('user', JSON.stringify(patchedUser));
        subscriber.next(true);
      } else {
        subscriber.next(false);
      }
    });
  }
  /**
   * Get the AuthConfig
   *
   * @return AuthConfig
   */
  getConfig() {
    return this.config;
  }
  /**
   * If the given role exists in users' roles
   *
   * @param role: string | string[]
   * @return boolean
   */
  hasRole(role) {
    const user = this.getUser();
    const userRoles = user.roles.map(userRole => {
      return userRole.key;
    });
    if (typeof role === 'string') {
      // Check for exact match
      return userRoles.indexOf(role.toString()) > -1;
    } else if (Array.isArray(role)) {
      // Check if given roles match any user roles
      const intersection = userRoles.filter(u => role.includes(u));
      return intersection.length > 0;
    }
    return false;
  }
  /**
   * Hook to call after a succesful login
   *
   * @param response: any
   * @return void
   */
  handleSuccessfulLogin(response) {}
  /**
   * Hook to call after a succesful logout
   *
   * @param response: any
   * @return void
   */
  handleSuccessfulLogout(response) {}
  /**
   * Get the claims of the token
   *
   * @return Record<string, any>
   */
  getTokenClaims() {
    try {
      return jwtDecode(this.getToken());
    } catch (error) {
      return null;
    }
  }
  /**
   * Remove credentials from localStorage
   *
   * @return void
   */
  removeStaleCredentials() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('refresh_token');
  }
  /**
   * Get Http headers
   * @returns new HttpHeaders
   */
  getHeaders(customHeaders) {
    if (customHeaders) {
      return customHeaders;
    }
    return new HttpHeaders({
      Authorization: 'Bearer ' + this.getToken(),
      'Content-Type': 'application/json',
      Accept: 'application/json'
    });
  }
  getRefreshToken() {
    const refresh_token = localStorage.getItem('refresh_token');
    return this.http.post(`${this.config.baseUrl}/auth/refresh-token`, {
      refresh_token
    }).pipe(map(result => ({
      refreshed: true,
      ...result.data
    })));
  }
  isTokenExpired() {
    const jwt = this.getTokenClaims();
    return new Observable(observer => {
      if (jwt) {
        const tokenExpired = isJwtTokenExpired(jwt, this.offsetSeconds);
        observer.next(tokenExpired);
        observer.complete();
      } else {
        observer.error();
      }
    });
  }
  /**
   * Watch refresh token is expired, then request a new token
   * @returns Observable<RefreshTokenData>
   */
  watchRefreshToken() {
    const token = new BehaviorSubject({
      refreshed: false,
      token: null,
      refresh_token: null
    });
    if (!this.refreshTokenWachterIsStarted()) {
      this.isRefreshTokenWachterStarted.next(true);
      this.checkForRefreshToken().pipe(switchMap(expired => {
        return expired ? this.getRefreshToken() : of({
          refreshed: false,
          token: null,
          refresh_token: null
        });
      })).subscribe({
        next: result => {
          let tokenSubjectValue = {
            refreshed: false,
            token: null,
            refresh_token: null
          };
          if (result.refreshed) {
            tokenSubjectValue = {
              refreshed: true,
              token: result.token,
              refresh_token: result.refresh_token
            };
            localStorage.setItem('token', result.token);
            localStorage.setItem('refresh_token', result.refresh_token);
          }
          token.next(tokenSubjectValue);
        },
        error: () => this.logout()
      });
    }
    return token.asObservable();
  }
  checkForRefreshToken() {
    const intervalSeconds = this.intervalSeconds * 1000;
    return interval(intervalSeconds).pipe(filter(() => this.isRefreshTokenWachterStarted.value), switchMap(() => this.isTokenExpired()), takeUntil(this.isRefreshTokenWachterStarted.asObservable().pipe(filter(value => !value))));
  }
  refreshTokenWachterIsStarted() {
    return this.isRefreshTokenWachterStarted.getValue();
  }
  /**
   * Handle the thrown error
   *
   * @param error: any
   * @return Observable<never>
   */
  handleError(error) {
    return throwError(error);
  }
  static {
    this.ɵfac = function AuthService_Factory(t) {
      return new (t || AuthService)(i0.ɵɵinject(AuthConfig), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(AUTH_USE_REFRESH_TOKENS, 8));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AuthService,
      factory: AuthService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AuthService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: AuthConfig
  }, {
    type: i2.HttpClient
  }, {
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [AUTH_USE_REFRESH_TOKENS]
    }]
  }], null);
})();
class AuthGuard {
  constructor(authService, router, config, authRefreshToken) {
    this.authService = authService;
    this.router = router;
    this.config = config;
    this.authRefreshToken = authRefreshToken;
  }
  canActivate(route, state) {
    if (this.authService.isAuthenticated()) {
      this.checkUseRefreshToken();
      return true;
    }
    this.router.navigate([this.config.authRejectionRoute], {
      queryParams: {
        redirectUrl: window.location.pathname
      }
    });
    return false;
  }
  canActivateChild(route, state) {
    return this.canActivate(route, state);
  }
  canLoad() {
    if (!this.authService.isAuthenticated()) {
      this.router.navigate([this.config.authRejectionRoute], {
        queryParams: {
          redirectUrl: window.location.pathname
        }
      });
      return false;
    }
    this.checkUseRefreshToken();
    return true;
  }
  checkUseRefreshToken() {
    if (this.authRefreshToken.useRefreshToken || this.config?.authRefreshToken?.useRefreshToken) {
      this.authService.watchRefreshToken();
    }
  }
  static {
    this.ɵfac = function AuthGuard_Factory(t) {
      return new (t || AuthGuard)(i0.ɵɵinject(AuthService), i0.ɵɵinject(i2$1.Router), i0.ɵɵinject(AuthConfig), i0.ɵɵinject(AUTH_USE_REFRESH_TOKENS));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AuthGuard,
      factory: AuthGuard.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AuthGuard, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: AuthService
  }, {
    type: i2$1.Router
  }, {
    type: AuthConfig
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [AUTH_USE_REFRESH_TOKENS]
    }]
  }], null);
})();
class UnauthenticatedGuard {
  constructor(authService, router, config) {
    this.authService = authService;
    this.router = router;
    this.config = config;
  }
  canActivate(route, state) {
    if (!this.authService.isAuthenticated()) {
      return true;
    }
    this.router.navigate([this.config.unauthenticatedRejectionRoute]);
    return false;
  }
  canActivateChild(route, state) {
    return this.canActivate(route, state);
  }
  static {
    this.ɵfac = function UnauthenticatedGuard_Factory(t) {
      return new (t || UnauthenticatedGuard)(i0.ɵɵinject(AuthService), i0.ɵɵinject(i2$1.Router), i0.ɵɵinject(AuthConfig));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: UnauthenticatedGuard,
      factory: UnauthenticatedGuard.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UnauthenticatedGuard, [{
    type: Injectable
  }], () => [{
    type: AuthService
  }, {
    type: i2$1.Router
  }, {
    type: AuthConfig
  }], null);
})();
class AuthInterceptor {
  constructor(authService) {
    this.authService = authService;
  }
  /**
   * Intercept the Http request and check for a 401 Unauthorized code
   *
   * @param request: HttpRequest<any>
   * @param next: HttpHandler
   * @return Observable<HttpEvent<any>>
   */
  intercept(request, next) {
    return next.handle(request).pipe(catchError(error => {
      if (error.status && error.status === 401) {
        this.authService.setAuthenticationState(false);
        this.authService.removeStaleCredentials();
      }
      return throwError(error);
    }));
  }
  static {
    this.ɵfac = function AuthInterceptor_Factory(t) {
      return new (t || AuthInterceptor)(i0.ɵɵinject(AuthService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AuthInterceptor,
      factory: AuthInterceptor.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AuthInterceptor, [{
    type: Injectable
  }], () => [{
    type: AuthService
  }], null);
})();
class TokenInterceptor {
  constructor(authService) {
    this.authService = authService;
    this.excludedRequests = ['assets/i18n'];
  }
  intercept(request, next) {
    const token = this.authService.getToken();
    const isExcluded = this.excludedRequests.some(item => request.url.includes(item));
    if (token && !isExcluded) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }
    return next.handle(request);
  }
  static {
    this.ɵfac = function TokenInterceptor_Factory(t) {
      return new (t || TokenInterceptor)(i0.ɵɵinject(AuthService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TokenInterceptor,
      factory: TokenInterceptor.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TokenInterceptor, [{
    type: Injectable
  }], () => [{
    type: AuthService
  }], null);
})();
class IfAuthenticatedDirective {
  constructor(templateRef, viewContainer, authService) {
    this.templateRef = templateRef;
    this.viewContainer = viewContainer;
    this.authService = authService;
    this.hasView = false;
    this.destroy$ = new Subject();
    this.state$ = this.authService.getAuthenticationState();
  }
  ngOnInit() {
    this.changeDisplay(this.authService.isAuthenticated());
    this.state$.pipe(takeUntil(this.destroy$)).subscribe(state => this.changeDisplay(state));
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  /**
   * Toggle the display of the element
   *
   * @param authenticated: boolean
   * @return void
   */
  changeDisplay(authenticated) {
    if (authenticated && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!authenticated && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
  static {
    this.ɵfac = function IfAuthenticatedDirective_Factory(t) {
      return new (t || IfAuthenticatedDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(AuthService));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: IfAuthenticatedDirective,
      selectors: [["", "capIfAuthenticated", ""]]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IfAuthenticatedDirective, [{
    type: Directive,
    args: [{
      selector: '[capIfAuthenticated]'
    }]
  }], () => [{
    type: i0.TemplateRef
  }, {
    type: i0.ViewContainerRef
  }, {
    type: AuthService
  }], null);
})();
class UserIdleConfig {}
class IdleUserService {
  constructor(config, ngZone) {
    this.config = config;
    this.ngZone = ngZone;
    /** idle buffer in seconds. Default 1 second */
    this.idleBufferMillisec = 1000;
    /** idle duration in seconds. Default 10 mins */
    this.idleMillisec = 600 * 1000;
    /** timeout in seconds. Default 5 mins */
    this.timeout = 300;
    this.timerStart$ = new BehaviorSubject(null);
    this.idleStateKey = 'user.idle.expiry';
    this.onIdleStart = new Subject();
    this.onIdleEnd = new Subject();
    this.onTimeoutWarning = new Subject();
    this.onTimeout = new Subject();
    if (this.config) {
      this.setConfig(config);
    }
  }
  ngOnDestroy() {
    this.stopWatching();
  }
  watchIdle() {
    this._interruptEvents$ = merge(fromEvent(window, 'mousemove'), fromEvent(window, 'resize'), fromEvent(document, 'keydown'), fromEvent(document, 'click'), fromEvent(document, 'mousedown'), fromEvent(document, 'DOMMouseScroll'), fromEvent(document, 'mousewheel'), fromEvent(document, 'touchmove'), fromEvent(document, 'MSPointerMove'));
    if (this._idleSubscription) {
      this._idleSubscription.unsubscribe();
    }
    this._idleSubscription = from(this._interruptEvents$).pipe(bufferTime(this.idleBufferMillisec),
    // Milliseconds before detecting idle user
    filter$1(events => !events.length && !this._isIdle && !this._isInactivityTimer), tap(() => {
      this._isIdle = true;
      this.setIdleExpirationDate();
      this.onIdleStart.next(true);
    }), switchMap(() => {
      return this.ngZone.runOutsideAngular(() => {
        return interval(1000).pipe(switchMap(() => {
          return this.isIdleExpired();
        }), takeUntil(merge(this._interruptEvents$, this.timerStart$.asObservable().pipe(filter$1(Boolean)))), finalize(() => {
          this._isIdle = false;
          if (!this._isInactivityTimer) {
            this.setIdleExpirationDate();
          }
          this.onIdleEnd.next(true);
        }));
      });
    })).subscribe(expired => {
      if (expired) {
        this._isInactivityTimer = true;
        this.timerStart$.next(true);
        this.lastTimestamp = new Date().getTime();
      }
    });
    this.setupTimer();
  }
  stopWatching() {
    this.stopTimer();
    if (this._idleSubscription) {
      this._idleSubscription.unsubscribe();
    }
    this.setIdleExpiration();
  }
  stopTimer() {
    this._isInactivityTimer = false;
    this.timerStart$.next(false);
  }
  resetTimer() {
    this.stopTimer();
    this.setupTimer();
  }
  getIdleTimeout() {
    return this.onTimeout.asObservable();
  }
  getIdleTimeoutWarning() {
    return this.onTimeoutWarning.asObservable();
  }
  getIdleStart() {
    return this.onIdleStart.asObservable();
  }
  getIdleEnd() {
    return this.onIdleEnd.asObservable();
  }
  setupTimer() {
    this.ngZone.runOutsideAngular(() => {
      this.timer$ = interval(1000).pipe(tap(ellapsed => {
        const now = new Date().getTime();
        if (this.lastTimestamp) {
          const diff = now - this.lastTimestamp;
          if (diff / 1000 > this.timeout || this.getForceIdleTimeout()) {
            this.onTimeout.next(true);
            this.lastTimestamp = null;
            this.stopWatching();
            return;
          }
        }
        this.lastTimestamp = now;
        if (ellapsed >= this.timeout) {
          this.onTimeout.next(true);
          this.stopWatching();
          return;
        }
        this.onTimeoutWarning.next(ellapsed);
      }), takeWhile(() => Boolean(this.timerStart$.value)));
    });
    this._idleSubscription?.add(this.timerStart$.asObservable().pipe(distinctUntilChanged(), switchMap(start => start ? this.timer$ : of(null))).subscribe());
  }
  setConfig(config) {
    if (config.idleDuration) {
      this.idleMillisec = config.idleDuration * 1000;
    }
    if (config.idleBuffer) {
      this.idleBufferMillisec = config.idleBuffer * 1000;
    }
    if (config.timeout) {
      this.timeout = config.timeout;
    }
  }
  setIdleExpirationDate() {
    const expirationDate = this.nowDate().getTime() + this.idleMillisec + this.idleBufferMillisec;
    this.lastIdleExpirationDate(new Date(expirationDate));
  }
  isIdleExpired() {
    const isExpired = this.lastIdleExpirationDate() <= this.nowDate().getTime();
    return of(isExpired);
  }
  lastIdleExpirationDate(value) {
    if (value) {
      this.setIdleExpiration(value);
    }
    return this.getIdleExpiration();
  }
  nowDate() {
    return new Date();
  }
  setIdleExpiration(value) {
    if (value) {
      localStorage.setItem(this.idleStateKey, JSON.stringify(value.getTime()));
    } else {
      localStorage.removeItem(this.idleStateKey);
    }
  }
  getIdleExpiration() {
    const expiry = localStorage.getItem(this.idleStateKey);
    if (expiry) {
      return new Date(parseInt(expiry, 10)).getTime();
    }
    return null;
  }
  getForceIdleTimeout() {
    const timeoutInMilliseconds = this.timeout * 1000;
    return this.nowDate().getTime() >= this.lastIdleExpirationDate() + timeoutInMilliseconds;
  }
  static {
    this.ɵfac = function IdleUserService_Factory(t) {
      return new (t || IdleUserService)(i0.ɵɵinject(UserIdleConfig, 8), i0.ɵɵinject(i0.NgZone));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: IdleUserService,
      factory: IdleUserService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IdleUserService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: UserIdleConfig,
    decorators: [{
      type: Optional
    }]
  }, {
    type: i0.NgZone
  }], null);
})();
function apiListResultToMapItem(result, valueProperty = 'key', labelProperty = 'label') {
  return result.data.map(item => {
    return {
      label: item[labelProperty],
      value: item[valueProperty]
    };
  });
}
function toMapItems(source, valueProperty = 'key', labelProperty = 'label') {
  return new Observable(subscriber => {
    source.subscribe({
      next(value) {
        if (value !== undefined && value !== null) {
          subscriber.next(apiListResultToMapItem(value, valueProperty, labelProperty));
        }
      },
      error(error) {
        subscriber.error(error);
      },
      complete() {
        subscriber.complete();
      }
    });
  });
}
function responseData(source) {
  return new Observable(subscriber => {
    source.subscribe({
      next(value) {
        if (value) {
          subscriber.next(value.data);
        }
        subscriber.complete();
      }
    });
  });
}
class TwoFactorAuthService {
  constructor(config, http, sanitizer, authService) {
    this.config = config;
    this.http = http;
    this.sanitizer = sanitizer;
    this.authService = authService;
    this.endpoint = 'auth';
    this.endpoint = `${this.config.baseUrl}/auth`;
  }
  handleSuccessfulLogin(result) {
    if (result.status === 'ok') {
      if (result.user) {
        localStorage.setItem('user', JSON.stringify(result.user));
      }
      localStorage.setItem('token', result.token);
      this.authService.setAuthenticationState(true);
    }
  }
  list2faMethods() {
    return this.http.get(`${this.endpoint}/2fa/methods`, {
      headers: this.authService.getHeaders()
    }).pipe(toMapItems);
  }
  login(postData) {
    return this.http.post(`${this.endpoint}/login2fa`, postData, {
      headers: this.authService.getHeaders()
    }).pipe(map(result => {
      if (result.status === 'ok' && result.user) {
        if (result.user.roles !== undefined && result.user.roles.data !== undefined) {
          result.user.roles = result.user.roles.data;
        }
        return result;
      }
      return null;
    }, catchError(error => throwError(error))), tap$1(result => this.handleSuccessfulLogin(result)));
  }
  getOneTimePasswordQrCode() {
    return this.http.get(`${this.endpoint}/2fa/setup/google`, {
      headers: this.authService.getHeaders()
    }).pipe(map(response => ({
      ...response.data,
      qrCode: this.sanitizer.bypassSecurityTrustResourceUrl(response.data.qrCode)
    })));
  }
  confirm2fa(method, key) {
    return this.http.post(`${this.endpoint}/2fa/confirm`, {
      method,
      key
    }, {
      headers: this.authService.getHeaders()
    }).pipe(responseData);
  }
  setupEmail() {
    return this.http.get(`${this.endpoint}/2fa/setup/email`, {
      headers: this.authService.getHeaders()
    }).pipe(responseData);
  }
  register(method, identifier) {
    return this.http.post(`${this.endpoint}/2fa/register`, {
      method,
      identifier
    }, {
      headers: this.authService.getHeaders()
    }).pipe(responseData);
  }
  setupSms() {
    return this.http.get(`${this.endpoint}/2fa/setup/sms`, {
      headers: this.authService.getHeaders()
    }).pipe(responseData);
  }
  send2faResetMail(user) {
    return this.http.post(`${this.endpoint}/2fa/send-disable-email`, {
      user_id: user.id
    }, {
      headers: this.authService.getHeaders()
    }).pipe(responseData);
  }
  disable2fa(token) {
    return this.http.post(`${this.endpoint}/2fa/disable?token=${token}`, {
      token
    }, {
      headers: this.authService.getHeaders()
    }).pipe(responseData);
  }
  requestDisable() {
    return this.http.get(`${this.endpoint}/2fa/request-disable`, {
      headers: this.authService.getHeaders()
    }).pipe(responseData);
  }
  confirmDisable(method, key) {
    return this.http.post(`${this.endpoint}/2fa/confirm-disable`, {
      method,
      key
    }, {
      headers: this.authService.getHeaders()
    }).pipe(responseData);
  }
  static {
    this.ɵfac = function TwoFactorAuthService_Factory(t) {
      return new (t || TwoFactorAuthService)(i0.ɵɵinject(AuthConfig), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.DomSanitizer), i0.ɵɵinject(AuthService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TwoFactorAuthService,
      factory: TwoFactorAuthService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TwoFactorAuthService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: AuthConfig
  }, {
    type: i2.HttpClient
  }, {
    type: i3.DomSanitizer
  }, {
    type: AuthService
  }], null);
})();
class AuthModule {
  static forRoot(config) {
    return {
      ngModule: AuthModule,
      providers: [AuthGuard, AuthService, IdleUserService, {
        provide: AuthConfig,
        useValue: config
      }, {
        provide: AuthService,
        useClass: config.authService || AuthService
      }, {
        provide: TwoFactorAuthService,
        useClass: config.twoFAService || TwoFactorAuthService
      }, {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true
      }, {
        provide: UserIdleConfig,
        useValue: config.userIdleConfig
      }, {
        provide: AUTH_USE_REFRESH_TOKENS,
        useValue: config.authRefreshToken || new AuthConfig().authRefreshToken
      }]
    };
  }
  static {
    this.ɵfac = function AuthModule_Factory(t) {
      return new (t || AuthModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: AuthModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [HttpClientModule, FormsModule, ReactiveFormsModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AuthModule, [{
    type: NgModule,
    args: [{
      declarations: [IfAuthenticatedDirective],
      imports: [HttpClientModule, FormsModule, ReactiveFormsModule],
      exports: [IfAuthenticatedDirective]
    }]
  }], null, null);
})();
var TwoFactorMethod;
(function (TwoFactorMethod) {
  TwoFactorMethod["application"] = "application";
  TwoFactorMethod["oneTimePassword"] = "google";
  TwoFactorMethod["authenticator"] = "authenticator";
  TwoFactorMethod["google"] = "google";
  TwoFactorMethod["microsoft"] = "microsoft";
  TwoFactorMethod["email"] = "email";
  TwoFactorMethod["sms"] = "sms";
})(TwoFactorMethod || (TwoFactorMethod = {}));

/*
 * Public API Surface of auth
 */

/**
 * Generated bundle index. Do not edit.
 */

export { AUTH_USE_REFRESH_TOKENS, AuthConfig, AuthGuard, AuthInterceptor, AuthModule, AuthService, IdleUserService, IfAuthenticatedDirective, TokenInterceptor, TwoFactorAuthService, TwoFactorMethod, UnauthenticatedGuard, getJwtTokenExpirationDate, isJwtTokenExpired };
