import { Component, Input } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-horizontal-input',
  templateUrl: 'horizontal-input.component.html',
  styleUrls: ['horizontal-input.component.scss'],
  standalone: true,
  imports: [NgClass],
})
export class HorizontalInputComponent {
  @Input() public label: string;
  @Input() public labelSize = 4;
  @Input() public class = 'align-items-baseline';
  @Input() public required: boolean;
  @Input() public hasIconSuffix = false;
}
