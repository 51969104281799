import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CapturumCheckboxModule } from '@capturum/ui/checkbox';

@Component({
  selector: 'app-checkbox-filter',
  templateUrl: './checkbox-filter.component.html',
  styleUrls: ['checkbox-filter.component.scss'],
  standalone: true,
  imports: [CapturumCheckboxModule, FormsModule],
})
export class CheckboxFilterComponent {
  @Input() public label: string;
  @Input() public value: boolean;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() public onChange: EventEmitter<any> = new EventEmitter<any>();
}
