<div class="basic-block" [ngClass]="styleClass">
  <div class="basic-block__header">
    <ng-container
      [ngTemplateOutlet]="headerTemplate || defaultTemplate"
      [ngTemplateOutletContext]="{ $implicit: heading }"></ng-container>
  </div>

  <div class="basic-block__body">
    <ng-content></ng-content>
  </div>
</div>

<ng-template #defaultTemplate let-header>
  {{ header }}
</ng-template>
