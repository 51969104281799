<div class="row form-group" [ngClass]="class">
  <div [ngClass]="'col-md-' + labelSize" class="pt-1 cap-form-field-label">
    {{ label }}
    @if (required) {
      <span class="required">*</span>
    }
  </div>

  <div [ngClass]="'col-md-' + (12 - labelSize)" [class.horizontal-input-with-icon]="hasIconSuffix">
    <ng-content></ng-content>
  </div>
</div>
