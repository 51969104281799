import { Directive, HostListener, Input } from '@angular/core';
import { NumbersNotation } from '@shared/models/numbers-notation.model';
import { SharedService } from '@shared/services/shared.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[numbersNotation]',
  standalone: true,
})
export class NumbersNotationDirective {
  @Input() public notationType: NumbersNotation;
  @Input() public numberLengthAfterComma: number;

  private specialKeys = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight'];

  @HostListener('keydown', ['$event'])
  public onKeyDown(event: KeyboardEvent | any): void {
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    const currentValue: string = event.target.value;
    const nextChar: string = event.key;

    if (!this.isCharValid(nextChar, currentValue)) {
      event.preventDefault();
    }

    if (this.isMinus(nextChar) && currentValue.includes('-')) {
      event.preventDefault();
    }

    if (this.numberLengthAfterComma && event.target.value.includes(',') && this.notationType === 'eu') {
      const parts = event.target.value.split(',');

      parts[1].length == 1 ? event.preventDefault() : '';
    }
  }

  @HostListener('keyup', ['$event'])
  public onKeyUp(event: KeyboardEvent | any): void {
    let currentValue: string = event.target.value;

    // Prevent leading zeros like 0100
    while (
      currentValue.length > 1 &&
      currentValue.charAt(0) === '0' &&
      currentValue.charAt(1) !== ',' &&
      currentValue.charAt(1) !== '.'
    ) {
      currentValue = currentValue.substr(1);
    }

    if (currentValue.includes('-') && currentValue[0] !== '-') {
      currentValue = currentValue.replace('-', '');
    }

    event.target.value = currentValue;
  }

  @HostListener('focusout', ['$event'])
  public onFocusOut(event: KeyboardEvent | any): void {
    if (this.notationType === 'eu') {
      let currentValue: string = event.target.value;

      currentValue = currentValue
        .replace(SharedService.dotRemovalPattern, '')
        .replace(SharedService.commaRemovalPattern, '.');

      if (currentValue !== '') {
        currentValue = parseFloat(currentValue).toLocaleString('en', { maximumFractionDigits: 20 });
        const parts = currentValue.split('.');

        parts[0] = parts[0].replace(SharedService.commaRemovalPattern, '.');
        currentValue = parts.join(',');
      }

      event.target.value = currentValue;
    }
  }

  private isCharValid(char: string, currentValue: string): boolean {
    return this.isNumber(char) || this.isSingleSpecialChar(char, currentValue, this.notationType) || this.isMinus(char);
  }

  private isNumber(char: string): boolean {
    return ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'].includes(char);
  }

  private isSingleSpecialChar(char: string, currentValue: string, notationType: NumbersNotation): boolean {
    switch (notationType) {
      case 'comma':
        return currentValue.includes(',') ? false : [','].includes(char);
      case 'eu':
        return currentValue.includes(',') ? false : [','].includes(char);
      default:
        return false;
    }
  }

  private isMinus(char: string): boolean {
    return char === '-';
  }
}
