import { AfterViewInit, Component, ContentChildren, Input, QueryList, TemplateRef } from '@angular/core';
import { CapturumTemplateDirective } from '@capturum/ui/api';
import { NgClass, NgTemplateOutlet } from '@angular/common';

@Component({
  selector: 'app-basic-block',
  templateUrl: 'basic-block.component.html',
  standalone: true,
  imports: [NgClass, NgTemplateOutlet],
})
export class BasicBlockComponent implements AfterViewInit {
  @Input()
  public heading: any;

  @Input()
  public styleClass: string;

  @ContentChildren(CapturumTemplateDirective)
  public templates: QueryList<CapturumTemplateDirective>;

  public headerTemplate: TemplateRef<any>;

  public ngAfterViewInit(): void {
    const headerTemplate = this.templates.find((template) => {
      return template.getType() === 'header';
    });

    if (headerTemplate) {
      this.headerTemplate = headerTemplate.template;
    }
  }
}
