import * as i0 from '@angular/core';
import { Injectable, NgModule } from '@angular/core';
import * as i2 from '@angular/common/http';
import { HttpHeaders, HttpEventType, HttpResponse, HttpClientModule } from '@angular/common/http';
import { EMPTY, throwError } from 'rxjs';
import { catchError, map, switchMap, filter } from 'rxjs/operators';
import * as i3 from '@capturum/auth';
import { format } from 'date-fns';
class ApiConfig {
  constructor() {
    this.baseUrl = '';
    this.production = false;
    this.dateInterceptor = {
      dateProperties: [],
      blackListedURLs: [],
      blackListedStatusCodes: [],
      displayFormat: 'YYYY-MM-DD HH:mm:ss',
      sendFormat: 'YYYY-MM-DDTHH:mm:ssZZ'
    };
    this.onAuthError = () => {
      if (!this.production) {
        console.warn('onAuthError has to be set in order to redirect to login page');
      }
    };
  }
}
class ApiHttpService {
  constructor(config, http, authService) {
    this.config = config;
    this.http = http;
    this.authService = authService;
  }
  getConfig() {
    return this.config;
  }
  get(url, options) {
    return this.http.get(`${this.config.baseUrl}${url}`, {
      params: options?.params,
      headers: options?.headers ?? this.getHeaders(),
      responseType: options?.responseType ?? 'json'
    }).pipe(catchError(response => this.handleError(response)));
  }
  getBlob(url, type) {
    const headers = this.getHeaders();
    headers.delete('Content-Type');
    return this.http.get(`${this.config.baseUrl}${url}`, {
      headers: headers,
      responseType: 'blob'
    }).pipe(catchError(response => this.handleError(response)));
  }
  post(url, data, options) {
    return this.http.post(`${this.config.baseUrl}${url}`, data, {
      params: options?.params,
      headers: options?.headers ?? this.getHeaders(),
      responseType: options?.responseType ?? 'json'
    }).pipe(catchError(response => this.handleError(response)));
  }
  put(url, data, options) {
    return this.http.put(`${this.config.baseUrl}${url}`, data, {
      params: options?.params,
      headers: options?.headers ?? this.getHeaders(),
      responseType: options?.responseType ?? 'json'
    }).pipe(catchError(response => this.handleError(response)));
  }
  patch(url, data, options) {
    return this.http.patch(`${this.config.baseUrl}${url}`, data, {
      params: options?.params,
      headers: options?.headers ?? this.getHeaders(),
      responseType: options?.responseType ?? 'json'
    }).pipe(catchError(response => this.handleError(response)));
  }
  delete(url, options) {
    return this.http.delete(`${this.config.baseUrl}${url}`, {
      params: options?.params,
      headers: options?.headers ?? this.getHeaders(),
      responseType: options?.responseType ?? 'json'
    }).pipe(catchError(response => this.handleError(response)));
  }
  uploadFiles(url, files) {
    let headers = this.getHeaders();
    const formData = new FormData();
    headers = headers.delete('Content-Type');
    if (files === null || files.length === 0) {
      return this.handleError('No files were added.');
    }
    files.forEach((file, index) => {
      formData.append(`file[${index}]`, file);
    });
    return this.http.post(`${this.config.baseUrl}/${url}`, formData, {
      headers,
      responseType: 'json'
    });
  }
  getHeaders(customHeaders) {
    if (customHeaders) {
      return customHeaders;
    }
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json'
    });
    const token = this.authService.getToken();
    if (token) {
      headers = headers.append('Authorization', 'Bearer ' + token);
    }
    return headers;
  }
  handleError(error) {
    if (error.status === 401) {
      this.config.onAuthError();
      return EMPTY;
    }
    return throwError(error);
  }
  static {
    this.ɵfac = function ApiHttpService_Factory(t) {
      return new (t || ApiHttpService)(i0.ɵɵinject(ApiConfig), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.AuthService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ApiHttpService,
      factory: ApiHttpService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ApiHttpService, [{
    type: Injectable
  }], () => [{
    type: ApiConfig
  }, {
    type: i2.HttpClient
  }, {
    type: i3.AuthService
  }], null);
})();
class RecursiveIteration {
  constructor() {
    this.dateProperties = [];
    this.dateFormat = 'yyyy-MM-dd HH:mm:ss';
  }
  recursiveParse(item) {
    if (item instanceof Blob) {
      return item;
    } else {
      const mappedItem = {
        ...item
      };
      if (item) {
        Object.keys(item).forEach(key => {
          const itemValue = item[key];
          if (Array.isArray(itemValue)) {
            if (itemValue.every(value => typeof value === 'object' && !(value instanceof Date))) {
              mappedItem[key] = itemValue.map(itemObj => this.recursiveParse(itemObj));
            }
            if (this.dateProperties.includes(key)) {
              mappedItem[key] = itemValue.map(itemObj => this.getFormattedValue(itemObj, key));
            }
          } else if (typeof itemValue === 'object' && itemValue instanceof Date) {
            mappedItem[key] = this.getFormattedValue(itemValue, key);
          } else if (itemValue) {
            switch (typeof itemValue) {
              case 'object':
                return this.recursiveParse(itemValue);
              default:
                mappedItem[key] = this.getFormattedValue(itemValue, key);
                break;
            }
          }
        });
      }
      return mappedItem;
    }
  }
  getFormattedValue(item, key) {
    if (this.dateProperties.includes(key)) {
      if (isNaN(new Date(item).getTime())) {
        return item;
      }
      return item && format(new Date(item), this.dateFormat);
    }
    return item;
  }
}
class ApiService extends RecursiveIteration {
  static generateQuery(options) {
    if (!options) {
      return '';
    }
    const parts = [];
    if (options.include) {
      parts.push('_meta[include]=' + options.include.join(','));
    }
    if (options.exclude) {
      parts.push('_meta[exclude]=' + options.exclude.join(','));
    }
    if (options.page) {
      parts.push('_meta[page]=' + options.page);
    }
    if (options.perPage) {
      parts.push('_meta[perPage]=' + options.perPage);
    }
    if (options.search) {
      if (Array.isArray(options.search) && options.search[0]) {
        parts.push(`_meta[search]=${encodeURIComponent(options.search[0])}`);
      } else {
        parts.push(`_meta[search]=${encodeURIComponent(options.search)}`);
      }
    }
    if (options.parameters) {
      options.parameters.forEach(parameter => {
        if (typeof parameter.value === 'object') {
          parts.push(`${parameter.field}=${encodeURIComponent(JSON.stringify(parameter.value))}`);
        } else {
          parts.push(`${parameter.field}=${encodeURIComponent(parameter.value)}`);
        }
      });
    }
    if (options.filters) {
      options.filters.forEach((filter, index) => {
        const fieldFilters = options.filters.filter(f => f.field === filter.field);
        let fieldIndex = '';
        if (fieldFilters.length > 1) {
          const i = fieldFilters.findIndex(fieldFilter => fieldFilter === filter);
          fieldIndex = `[${i}]`;
        }
        if (filter.operator !== undefined && filter.operator !== null) {
          parts.push(`_meta[filter][${filter.field}]${fieldIndex}[operator]=${filter.operator}`);
        }
        if (Array.isArray(filter.value)) {
          filter.value.forEach((value, i) => {
            parts.push(`_meta[filter][${filter.field}]${fieldIndex}[value][${i}]=${encodeURIComponent(value)}`);
          });
        } else {
          parts.push(`_meta[filter][${filter.field}]${fieldIndex}[value]=${encodeURIComponent(filter.value)}`);
        }
      });
    }
    if (options.sort) {
      options.sort.forEach((value, index) => {
        parts.push(`_meta[sort][${index}][${value.field}]=${value.direction}`);
      });
    }
    if (options.has) {
      options.has.forEach((has, index) => {
        if (has.operator !== undefined && has.operator !== null) {
          parts.push(`_meta[has][${has.relation}][operator]=${has.operator}`);
        }
        if (has.count !== undefined && has.count !== null) {
          parts.push(`_meta[has][${has.relation}][count]=${has.count}`);
        }
        if ((has.operator === undefined || has.operator === null) && (has.count === undefined || has.count === null)) {
          parts.push(`_meta[has][${has.relation}]`);
        }
      });
    }
    if (options.count) {
      parts.push('_meta[count]=' + options.count.join(','));
    }
    return '?' + parts.join('&');
  }
  constructor(apiHttp) {
    super();
    this.apiHttp = apiHttp;
    if (this.apiHttp.getConfig().dateInterceptor) {
      this.dateProperties = this.apiHttp.getConfig().dateInterceptor.dateProperties;
      this.dateFormat = this.apiHttp.getConfig().dateInterceptor.sendFormat;
    }
  }
  get(id, options) {
    return this.apiHttp.get(`/${this.endpoint}/${id}` + this.getOptionsQuery(options)).pipe(map(result => result.hasOwnProperty('data') ? result.data : result));
  }
  index(options) {
    return this.apiHttp.get(`/${this.endpoint}` + this.getOptionsQuery(options));
  }
  list(options) {
    return this.apiHttp.get(`/${this.endpoint}/list` + this.getOptionsQuery(options));
  }
  create(data, options) {
    return this.apiHttp.post(`/${this.endpoint}` + this.getOptionsQuery(options), this.recursiveParse(data)).pipe(map(result => result.hasOwnProperty('data') ? result.data : result));
  }
  update(data, options) {
    return this.apiHttp.put(`/${this.endpoint}/${data.id}` + this.getOptionsQuery(options), this.recursiveParse(data)).pipe(map(result => result.hasOwnProperty('data') ? result.data : result));
  }
  updatePatch(data, options) {
    return this.apiHttp.patch(`/${this.endpoint}/${data.id}` + this.getOptionsQuery(options), this.recursiveParse(data)).pipe(map(result => result.hasOwnProperty('data') ? result.data : result));
  }
  delete(id) {
    return this.apiHttp.delete(`/${this.endpoint}/${id}`);
  }
  uploadFiles(url, files) {
    return this.apiHttp.uploadFiles(`/${this.endpoint}/${url}`, files);
  }
  toFormData(formValue) {
    const formData = new FormData();
    const keys = Object.keys(formValue);
    for (const key of keys) {
      const value = formValue[key];
      if (Array.isArray(value)) {
        value.forEach(item => formData.append(key, item));
      } else if (typeof value === 'object' && !(value instanceof File)) {
        formData.append(key, JSON.stringify(value));
      } else {
        formData.append(key, value);
      }
    }
    return formData;
  }
  getOptionsQuery(options) {
    return ApiService.generateQuery(options);
  }
  static {
    this.ɵfac = function ApiService_Factory(t) {
      return new (t || ApiService)(i0.ɵɵinject(ApiHttpService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ApiService,
      factory: ApiService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ApiService, [{
    type: Injectable
  }], () => [{
    type: ApiHttpService
  }], null);
})();

/**
 * VaporFileUploadService
 *
 * @description
 *
 * This service provides the functionality to upload a file to an S3 bucket as specified in the Laravel Vapor documentation.
 * The uploadFile function will first retrieve a storage location url from the backend.
 * This will return an url, and the file will be uploaded to the returned url.
 *
 * @example
 * ```
 *  this.vaporUploadService.uploadFile(file).subscribe((event) => {
 *    if (event.type === HttpEventType.UploadProgress) {
 *      // Do something with the progress inside event.data.progress
 *    } else if (event.type === HttpEventType.Response) {
 *      // DO something with the result in event.data
 *    }
 *  });
 *  ```
 */
class VaporFileUploadService {
  constructor(apiHttpService, http) {
    this.apiHttpService = apiHttpService;
    this.http = http;
    /**
     * The url used to retrieve the storage location url
     */
    this.vaporSignedStorageUrl = '/vapor/signed-storage-url';
  }
  /**
   * Upload the file to an AWS S3 bucket and return the uuid of the storage location
   *
   * @param file - the file to upload
   * @param options - extra options to retrieve the storage location
   */
  uploadFile(file, options) {
    return this.getStorageData(file, options).pipe(switchMap(storageDataResponse => {
      return this.uploadToVapor(storageDataResponse.url, file, new HttpHeaders(storageDataResponse.headers), storageDataResponse.uuid);
    }));
  }
  /**
   * Get the storage url of where to store the file
   *
   * @param file - the file to upload
   * @param options - extra options to retrieve the storage location
   */
  getStorageData(file, options) {
    return this.apiHttpService.post(this.vaporSignedStorageUrl, {
      bucket: options?.bucket || '',
      content_type: options?.contentType || file.type,
      expires: options?.expires || '',
      visibility: options?.visibility || ''
    });
  }
  /**
   * Upload the file to the given url and report the progress
   *
   * @param url - the url to which the file should be uploaded
   * @param file - the file to be uploaded
   * @param headers - http headers to be used with the request
   * @param uuid - the uuid of the storage location
   */
  uploadToVapor(url, file, headers, uuid) {
    if (headers.get('Host')) {
      headers = headers.delete('Host');
    }
    return this.http.put(url, file, {
      headers,
      reportProgress: true,
      observe: 'events'
    }).pipe(map(event => {
      if (event.type === HttpEventType.UploadProgress) {
        return {
          type: HttpEventType.UploadProgress,
          data: {
            progress: Math.round(100 * event.loaded / event.total)
          }
        };
      } else if (event.type === HttpEventType.Response) {
        return {
          type: HttpEventType.Response,
          data: {
            uuid
          }
        };
      }
      return {
        type: event.type,
        data: event
      };
    }));
  }
  static {
    this.ɵfac = function VaporFileUploadService_Factory(t) {
      return new (t || VaporFileUploadService)(i0.ɵɵinject(ApiHttpService), i0.ɵɵinject(i2.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: VaporFileUploadService,
      factory: VaporFileUploadService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(VaporFileUploadService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: ApiHttpService
  }, {
    type: i2.HttpClient
  }], null);
})();
class DateTimezoneInterceptor extends RecursiveIteration {
  constructor(config) {
    super();
    this.config = config;
    this.blackListedURLs = [];
    this.blackListedStatusCodes = [401, 204];
    if (this.config.dateInterceptor) {
      this.dateProperties = this.config.dateInterceptor.dateProperties;
      this.blackListedURLs = this.config.dateInterceptor.blackListedURLs;
      this.blackListedStatusCodes = this.config.dateInterceptor.blackListedStatusCodes;
      this.dateFormat = this.config.dateInterceptor.displayFormat;
    }
  }
  intercept(request, next) {
    return next.handle(request).pipe(filter(event => event instanceof HttpResponse), map(event => {
      const blackListUrl = this.blackListedURLs.some(item => event.url.includes(item));
      const blackListCode = this.blackListedStatusCodes.includes(+event.status);
      if (blackListUrl || blackListCode) {
        return event;
      } else {
        return event.clone({
          body: this.recursiveParse(event.body)
        });
      }
    }));
  }
  static {
    this.ɵfac = function DateTimezoneInterceptor_Factory(t) {
      return new (t || DateTimezoneInterceptor)(i0.ɵɵinject(ApiConfig));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: DateTimezoneInterceptor,
      factory: DateTimezoneInterceptor.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DateTimezoneInterceptor, [{
    type: Injectable
  }], () => [{
    type: ApiConfig
  }], null);
})();
class ApiModule {
  static forRoot(config) {
    return {
      ngModule: ApiModule,
      providers: [ApiService, ApiHttpService, DateTimezoneInterceptor, {
        provide: ApiConfig,
        useValue: config
      }]
    };
  }
  static {
    this.ɵfac = function ApiModule_Factory(t) {
      return new (t || ApiModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: ApiModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [HttpClientModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ApiModule, [{
    type: NgModule,
    args: [{
      imports: [HttpClientModule],
      declarations: [],
      exports: []
    }]
  }], null, null);
})();
var VaporUploadVisibility;
(function (VaporUploadVisibility) {
  VaporUploadVisibility["private"] = "private";
  VaporUploadVisibility["publicRead"] = "public-read";
  VaporUploadVisibility["publicReadWrite"] = "public-read-write";
  VaporUploadVisibility["awsExecRead"] = "awsExecRead";
  VaporUploadVisibility["authenticatedRead"] = "authenticated-read";
  VaporUploadVisibility["bucketOwnerRead"] = "bucket-owner-read";
  VaporUploadVisibility["bucketOwnerFullControl"] = "bucket-owner-full-control";
  VaporUploadVisibility["logDeliveryWrite"] = "log-delivery-write";
})(VaporUploadVisibility || (VaporUploadVisibility = {}));

/*
 * Public API Surface of api
 */

/**
 * Generated bundle index. Do not edit.
 */

export { ApiConfig, ApiHttpService, ApiModule, ApiService, DateTimezoneInterceptor, VaporFileUploadService, VaporUploadVisibility };
