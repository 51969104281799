import * as i0 from '@angular/core';
import { Component, Input, NgModule } from '@angular/core';
import { SkeletonShape } from '@capturum/ui/api';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
const _c0 = (a0, a1, a2) => ({
  shape: a0,
  circle: a1,
  square: a2
});
const _c1 = (a0, a1) => ({
  width: a0,
  height: a1
});
class CapturumSkeletonComponent {
  constructor() {
    /**
     * Skeleton shape type: circle, square or bar (default)
     */
    this.type = SkeletonShape.bar;
    this.SkeletonShape = SkeletonShape;
  }
  static {
    this.ɵfac = function CapturumSkeletonComponent_Factory(t) {
      return new (t || CapturumSkeletonComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CapturumSkeletonComponent,
      selectors: [["cap-skeleton"]],
      inputs: {
        type: "type",
        width: "width",
        height: "height"
      },
      decls: 1,
      vars: 9,
      consts: [[1, "loader", 3, "ngClass", "ngStyle"]],
      template: function CapturumSkeletonComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "span", 0);
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction3(2, _c0, ctx.type !== ctx.SkeletonShape.bar, ctx.type === ctx.SkeletonShape.circle, ctx.type === ctx.SkeletonShape.square))("ngStyle", i0.ɵɵpureFunction2(6, _c1, ctx.width, ctx.height || ctx.width));
        }
      },
      dependencies: [i1.NgClass, i1.NgStyle],
      styles: [".loader[_ngcontent-%COMP%]{box-sizing:border-box;overflow:hidden;position:relative;animation:_ngcontent-%COMP%_progress 2s ease-in-out infinite;background:#0000000d no-repeat;background-image:linear-gradient(90deg,#fff0,#fff9,#fff0);background-size:200px 100%;border-radius:4px;width:100%;height:20px;display:inline-block;margin-bottom:10px}.loader[_ngcontent-%COMP%]:after, .loader[_ngcontent-%COMP%]:before{box-sizing:border-box}.loader.shape[_ngcontent-%COMP%]{width:40px;height:40px;margin:5px}.loader.circle[_ngcontent-%COMP%]{border-radius:50%}.loader.square[_ngcontent-%COMP%]{border-radius:4px}@keyframes _ngcontent-%COMP%_progress{0%{background-position:-200px 0}to{background-position:calc(200px + 100%) 0}}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CapturumSkeletonComponent, [{
    type: Component,
    args: [{
      selector: 'cap-skeleton',
      template: "<span class=\"loader\"\n    [ngClass]=\"{shape: type !== SkeletonShape.bar, circle: type === SkeletonShape.circle, square: type === SkeletonShape.square}\"\n    [ngStyle]=\"{width: width, height: height || width}\">\n</span>\n",
      styles: [".loader{box-sizing:border-box;overflow:hidden;position:relative;animation:progress 2s ease-in-out infinite;background:#0000000d no-repeat;background-image:linear-gradient(90deg,#fff0,#fff9,#fff0);background-size:200px 100%;border-radius:4px;width:100%;height:20px;display:inline-block;margin-bottom:10px}.loader:after,.loader:before{box-sizing:border-box}.loader.shape{width:40px;height:40px;margin:5px}.loader.circle{border-radius:50%}.loader.square{border-radius:4px}@keyframes progress{0%{background-position:-200px 0}to{background-position:calc(200px + 100%) 0}}\n"]
    }]
  }], null, {
    type: [{
      type: Input
    }],
    width: [{
      type: Input
    }],
    height: [{
      type: Input
    }]
  });
})();
class CapturumSkeletonModule {
  static {
    this.ɵfac = function CapturumSkeletonModule_Factory(t) {
      return new (t || CapturumSkeletonModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CapturumSkeletonModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CapturumSkeletonModule, [{
    type: NgModule,
    args: [{
      declarations: [CapturumSkeletonComponent],
      exports: [CapturumSkeletonComponent],
      imports: [CommonModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CapturumSkeletonComponent, CapturumSkeletonModule };
