import * as i0 from '@angular/core';
import { Component, Input, NgModule, Injectable } from '@angular/core';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i2 from 'primeng/progressspinner';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { BehaviorSubject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
function LoaderComponent_p_progressSpinner_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "p-progressSpinner", 4);
  }
}
function LoaderComponent_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelement(1, "img", 5);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("src", ctx_r0.image, i0.ɵɵsanitizeUrl);
  }
}
function LoaderComponent_span_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 6);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r0.text, " ");
  }
}
class LoaderComponent {
  static {
    this.ɵfac = function LoaderComponent_Factory(t) {
      return new (t || LoaderComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: LoaderComponent,
      selectors: [["cap-loader"]],
      inputs: {
        text: "text",
        image: "image",
        styleClass: "styleClass"
      },
      decls: 4,
      vars: 4,
      consts: [[1, "cap-loader", 3, "ngClass"], ["styleClass", "cap-progress-spinner", 4, "ngIf"], [4, "ngIf"], ["class", "cap-loader-text", 4, "ngIf"], ["styleClass", "cap-progress-spinner"], ["alt", "loading spinner", 1, "cap-loader-image", 3, "src"], [1, "cap-loader-text"]],
      template: function LoaderComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵtemplate(1, LoaderComponent_p_progressSpinner_1_Template, 1, 0, "p-progressSpinner", 1)(2, LoaderComponent_ng_container_2_Template, 2, 1, "ng-container", 2)(3, LoaderComponent_span_3_Template, 2, 1, "span", 3);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngClass", ctx.styleClass);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", !ctx.image);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.image);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.text);
        }
      },
      dependencies: [i1.NgClass, i1.NgIf, i2.ProgressSpinner],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LoaderComponent, [{
    type: Component,
    args: [{
      selector: 'cap-loader',
      template: "<div class=\"cap-loader\" [ngClass]=\"styleClass\">\n    <p-progressSpinner *ngIf=\"!image\" styleClass=\"cap-progress-spinner\"></p-progressSpinner>\n\n    <ng-container *ngIf=\"image\">\n        <img class=\"cap-loader-image\" [src]=\"image\" alt=\"loading spinner\" />\n    </ng-container>\n\n    <span class=\"cap-loader-text\" *ngIf=\"text\">\n        {{ text }}\n    </span>\n</div>\n"
    }]
  }], null, {
    text: [{
      type: Input
    }],
    image: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }]
  });
})();
class CapturumLoaderModule {
  static {
    this.ɵfac = function CapturumLoaderModule_Factory(t) {
      return new (t || CapturumLoaderModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CapturumLoaderModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, ProgressSpinnerModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CapturumLoaderModule, [{
    type: NgModule,
    args: [{
      declarations: [LoaderComponent],
      imports: [CommonModule, ProgressSpinnerModule],
      exports: [LoaderComponent]
    }]
  }], null, null);
})();

/**
 * LoadingService
 *
 * @description
 * This is the loading service. This service can be used to display a loading spinner across the entire screen. To display a loading spinner simply call the toggleLoading method.
 * Here you can pass whether to show or hide the loader,
 * what text you want to display, what image to display (by default it uses the PrimeNG spinner) and you can add a styleClass to the loader to apply your own styles.
 */
class LoadingService {
  constructor(injector, appRef, componentFactoryResolver) {
    this.injector = injector;
    this.appRef = appRef;
    this.componentFactoryResolver = componentFactoryResolver;
    this.loading$ = new BehaviorSubject({
      loading: false,
      text: null,
      image: null,
      styleClass: null
    });
    this.loading$.subscribe(loading => {
      if (loading.loading === true && !this.isLoaderShown) {
        this.showLoader(loading.text, loading.image, loading.styleClass);
      }
    });
  }
  /**
   * Display or hide the loading spinner
   *
   * @param loading - whether to show or hide the loading spinner
   * @param text - the text displayed under the loading icon/image
   * @param image - the image you want to display as the loading icon. (By default it uses primeNG circle spinner)
   * @param styleClass - styleClass to add your own custom styles to the loader
   */
  toggleLoading(loading, text, image, styleClass) {
    this.loading$.next({
      loading: loading === undefined ? !this.loading$.getValue().loading : loading,
      text,
      image,
      styleClass
    });
  }
  hideLoader() {
    this.loading$.next({
      loading: false,
      text: null,
      image: null,
      styleClass: null
    });
  }
  showLoader(text, image, styleClass) {
    this.isLoaderShown = true;
    const componentRef = this.componentFactoryResolver.resolveComponentFactory(LoaderComponent).create(this.injector);
    componentRef.instance.text = text;
    componentRef.instance.image = image;
    componentRef.instance.styleClass = styleClass;
    // Attach component to the appRef so that it's inside the ng component tree
    this.appRef.attachView(componentRef.hostView);
    // Get DOM element from component
    const domElem = componentRef.hostView.rootNodes[0];
    // Append DOM element to the body
    document.body.appendChild(domElem);
    // Destroy element when loading ends
    this.loading$.pipe(filter(loading => !loading.loading), take(1)).subscribe(() => {
      this.isLoaderShown = false;
      this.appRef.detachView(componentRef.hostView);
      componentRef.destroy();
    });
  }
  static {
    this.ɵfac = function LoadingService_Factory(t) {
      return new (t || LoadingService)(i0.ɵɵinject(i0.Injector), i0.ɵɵinject(i0.ApplicationRef), i0.ɵɵinject(i0.ComponentFactoryResolver));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: LoadingService,
      factory: LoadingService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LoadingService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i0.Injector
  }, {
    type: i0.ApplicationRef
  }, {
    type: i0.ComponentFactoryResolver
  }], null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CapturumLoaderModule, LoaderComponent, LoadingService };
