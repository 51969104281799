import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'asPercentUnit',
  standalone: true,
})
export class PercentConvertPipe implements PipeTransform {
  public transform(value: any = ''): string {
    return value ? `% ${value.toString().replace('.', ',')}` : '% 0';
  }
}
