import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arraySort',
  standalone: true,
})
export class ArraySortPipe implements PipeTransform {
  public transform(array: unknown[], field: string, sortDirection: 'asc' | 'desc' = 'asc'): unknown[] {
    if (!Array.isArray(array)) {
      return array;
    }

    array.sort((a: unknown, b: unknown) => {
      if (a[field] < b[field]) {
        return sortDirection === 'asc' ? -1 : 1;
      } else if (a[field] > b[field]) {
        return sortDirection === 'asc' ? 1 : -1;
      } else {
        return 0;
      }
    });

    return array;
  }
}
