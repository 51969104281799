import { Injectable } from '@angular/core';
import { ToastService } from '@capturum/ui/api';
import { ToastOptions } from '@node_modules/@capturum/ui/api/models/toast-options.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationService extends ToastService {
  public error(title: string, detail: string, options: Partial<ToastOptions> = { life: 999999 }): void {
    super.error(title, detail, options);
  }
}
