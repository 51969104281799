import { NG_VALUE_ACCESSOR, NG_VALIDATORS, FormGroup } from '@angular/forms';
import * as i0 from '@angular/core';
import { forwardRef, Pipe, Injectable, Inject, Directive, Input, EventEmitter, Output, HostListener, Attribute, HostBinding, Component, ChangeDetectionStrategy, NgModule } from '@angular/core';
import { isObservable, of, forkJoin, Observable, Subject, concatMap, from, takeUntil, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import * as i1 from '@ngx-translate/core';
import { TranslateModule } from '@ngx-translate/core';
import * as i2 from '@angular/common';
import { DatePipe, DOCUMENT, CommonModule } from '@angular/common';
import * as i1$1 from 'primeng/api';
import * as i1$2 from '@angular/router';
import { RouterLink, NavigationEnd } from '@angular/router';
import { DynamicDialogComponent, DynamicDialogConfig, DynamicDialogRef, DynamicDialogInjector } from 'primeng/dynamicdialog';
import { KeyFilter } from 'primeng/keyfilter';
const _c0 = a0 => ({
  "row-action__items--open": a0
});
function CapturumRowActionComponent_div_0_ng_container_3_li_1_p_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "p", 10);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const description_r5 = ctx.ngIf;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", description_r5, " ");
  }
}
function CapturumRowActionComponent_div_0_ng_container_3_li_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "li", 2);
    i0.ɵɵlistener("click", function CapturumRowActionComponent_div_0_ng_container_3_li_1_Template_li_click_0_listener() {
      i0.ɵɵrestoreView(_r3);
      const action_r4 = i0.ɵɵnextContext().$implicit;
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.executeAction(action_r4, ctx_r1.item));
    });
    i0.ɵɵelementStart(1, "div", 6);
    i0.ɵɵelement(2, "i");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "div", 7)(4, "p", 8);
    i0.ɵɵtext(5);
    i0.ɵɵpipe(6, "observablePipe");
    i0.ɵɵpipe(7, "async");
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(8, CapturumRowActionComponent_div_0_ng_container_3_li_1_p_8_Template, 2, 1, "p", 9);
    i0.ɵɵpipe(9, "observablePipe");
    i0.ɵɵpipe(10, "async");
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const action_r4 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵattribute("data-test", (action_r4 == null ? null : action_r4.key) || (action_r4 == null ? null : action_r4.value));
    i0.ɵɵadvance(2);
    i0.ɵɵclassMap(action_r4 == null ? null : action_r4.icon);
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(7, 7, i0.ɵɵpipeBind1(6, 5, action_r4 == null ? null : action_r4.label)));
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(10, 11, i0.ɵɵpipeBind1(9, 9, action_r4 == null ? null : action_r4.description)));
  }
}
function CapturumRowActionComponent_div_0_ng_container_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, CapturumRowActionComponent_div_0_ng_container_3_li_1_Template, 11, 13, "li", 5);
    i0.ɵɵpipe(2, "observablePipe");
    i0.ɵɵpipe(3, "async");
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const action_r4 = ctx.$implicit;
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(3, 3, i0.ɵɵpipeBind1(2, 1, action_r4 == null ? null : action_r4.hidden)) !== true);
  }
}
function CapturumRowActionComponent_div_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 1);
    i0.ɵɵlistener("capClickOutside", function CapturumRowActionComponent_div_0_Template_div_capClickOutside_0_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.isOpen = false);
    });
    i0.ɵɵelementStart(1, "i", 2);
    i0.ɵɵlistener("click", function CapturumRowActionComponent_div_0_Template_i_click_1_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.toggleMenu());
    });
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(2, "ul", 3);
    i0.ɵɵtemplate(3, CapturumRowActionComponent_div_0_ng_container_3_Template, 4, 5, "ng-container", 4);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵclassMap(ctx_r1.icon);
    i0.ɵɵattribute("data-test", "action_toggler_" + (ctx_r1.item == null ? null : ctx_r1.item.id));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(5, _c0, ctx_r1.isOpen));
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", ctx_r1.actions);
  }
}
function CapturumValidatorComponent_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵelementStart(1, "span", 2);
    i0.ɵɵelement(2, "i", 3)(3, "span", 4);
    i0.ɵɵpipe(4, "translate");
    i0.ɵɵelementEnd();
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(3);
    i0.ɵɵproperty("innerHTML", i0.ɵɵpipeBind2(4, 1, ctx_r0.message(ctx_r0.control == null ? null : ctx_r0.control.errors), ctx_r0.getInterpolationDataByError(ctx_r0.control == null ? null : ctx_r0.control.errors)), i0.ɵɵsanitizeHtml);
  }
}
class ValueAccessorBase {
  constructor() {
    this.changed = new Array();
    this.touched = new Array();
  }
  /**
   * Returns provider configuration that should be defined for every component that extends ValueAccessorBase.
   * @param useExisting: Existing token to return. (equivalent to injector.get(useExisting))
   * @returns Provider
   */
  static getProviderConfig(useExisting) {
    return {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => useExisting),
      multi: true
    };
  }
  get value() {
    return this.innerValue;
  }
  set value(value) {
    if (this.innerValue !== value) {
      this.innerValue = value;
    }
    this.changed.forEach(f => f(this.getValueTransform ? this.getValueTransform(value) : value));
  }
  writeValue(value) {
    this.innerValue = this.setValueTransform ? this.setValueTransform(value) : value;
  }
  registerOnChange(fn) {
    this.changed.push(fn);
  }
  registerOnTouched(fn) {
    this.touched.push(fn);
  }
  touch() {
    this.touched.forEach(f => f());
  }
  setDisabledState(isDisabled) {
    this.disabled = isDisabled;
  }
}
var CSSClasses;
(function (CSSClasses) {
  CSSClasses["EmButton"] = "cap-button";
  CSSClasses["EmIconButton"] = "cap-icon-button";
  CSSClasses["ButtonPrimary"] = "ui-button-primary";
  CSSClasses["ButtonSecondary"] = "ui-button-secondary";
  CSSClasses["ButtonError"] = "ui-button-error";
  CSSClasses["ButtonSuccess"] = "ui-button-success";
  CSSClasses["ButtonWarning"] = "ui-button-warning";
})(CSSClasses || (CSSClasses = {}));
var Directions;
(function (Directions) {
  Directions["left"] = "left";
  Directions["right"] = "right";
  Directions["top"] = "top";
  Directions["bottom"] = "bottom";
})(Directions || (Directions = {}));
var Colors;
(function (Colors) {
  Colors["Primary"] = "#dd0053";
  Colors["Secondary"] = "#333";
  Colors["Third"] = "#dddddd";
  Colors["Text"] = "#333";
  Colors["Text2"] = "#5f6062";
  Colors["Text3"] = "rgba(51,51,51,0.5)";
  Colors["Text4"] = "#ffffff";
  Colors["ToastText"] = "#212121";
  Colors["Success"] = "#4cd964";
  Colors["Warning"] = "#ff9500";
  Colors["Confirm"] = "#35c44d";
  Colors["PrimaryShadow"] = "rgba(221, 0, 83, 0.5)";
  Colors["SecondaryShadow"] = "rgba(255, 255, 255, 0.25)";
  Colors["ThirdShadow"] = "rgba(221, 221, 221, 0.25)";
  Colors["SuccessShadow"] = "rgba(76, 217, 100, 0.5)";
  Colors["WarningShadow"] = "rgba(255, 149, 0, 0.25)";
  Colors["ErrorShadow"] = "rgba(255, 59, 48, 0.25)";
  Colors["ConfirmShadow"] = "rgba(53, 196, 77, 0.5)";
  Colors["Error"] = "#ff3b30";
  Colors["Info"] = "#dd0053";
})(Colors || (Colors = {}));
var CSSVarNames;
(function (CSSVarNames) {
  CSSVarNames["Primary"] = "--cap-primary-color";
  CSSVarNames["Secondary"] = "--secondary-color";
  CSSVarNames["Third"] = "--third-color";
  CSSVarNames["Text"] = "--primary-text";
  CSSVarNames["Text2"] = "--text-color-2";
  CSSVarNames["Text3"] = "--text-color-3";
  CSSVarNames["Text4"] = "--text-color-4";
  CSSVarNames["ToastText"] = "--text-toast";
  CSSVarNames["Success"] = "--success-color";
  CSSVarNames["Warning"] = "--warning-color";
  CSSVarNames["Confirm"] = "--confirm-color";
  CSSVarNames["PrimaryShadow"] = "--cap-primary-color-shadow";
  CSSVarNames["SecondaryShadow"] = "--secondary-color-shadow";
  CSSVarNames["ThirdShadow"] = "--divider-color-shadow";
  CSSVarNames["SuccessShadow"] = "--success-color-shadow";
  CSSVarNames["WarningShadow"] = "--warning-color-shadow";
  CSSVarNames["ErrorShadow"] = "--error-color-shadow";
  CSSVarNames["ConfirmShadow"] = "--confirm-shadow-color";
  CSSVarNames["Error"] = "--error-color";
  CSSVarNames["Info"] = "--info-color";
  CSSVarNames["Font"] = "--primary-font";
  CSSVarNames["InputHeight"] = "--cap-input-height";
  // Dynamic Filters Settings
  CSSVarNames["DynamicFilterInputHeight"] = "--cap-dynamic-filter-input-height";
  CSSVarNames["DynamicFilterBorderRadius"] = "--cap-dynamic-filter-border-radius";
  CSSVarNames["DynamicFilterBorderWidth"] = "--cap-dynamic-filter-border-width";
  CSSVarNames["DynamicFilterHideFiltersOnMobile"] = "--cap-dynamic-filters-hide-filters-on-mobile";
  CSSVarNames["DynamicFilterWidth"] = "--cap-dynamic-filter-width";
  CSSVarNames["DynamicFilterLabelFontSize"] = "--cap-dynamic-filter-label-font-size";
  CSSVarNames["DynamicFilterLabelFontWeight"] = "--cap-dynamic-filter-label-font-weight";
  CSSVarNames["DynamicFilterLabelFontFamily"] = "--cap-dynamic-filter-label-font-family";
  CSSVarNames["DynamicFilterLabelFontColor"] = "--cap-dynamic-filter-label-font-color";
  CSSVarNames["DynamicFilterIconColor"] = "--cap-dynamic-filter-icon-color";
  CSSVarNames["DynamicFilterIconOpacity"] = "--cap-dynamic-filter-icon-opacity";
  CSSVarNames["DynamicFilterPlaceholderFontColor"] = "--cap-dynamic-filter-placeholder-font-color";
  CSSVarNames["DynamicFilterPlaceholderFontWeight"] = "--cap-dynamic-filter-placeholder-font-weight";
})(CSSVarNames || (CSSVarNames = {}));
var DateFormat;
(function (DateFormat) {
  DateFormat["general"] = "dd-MM-yyyy";
  DateFormat["generalReverse"] = "yyyy-MM-dd";
  DateFormat["generalWithTime"] = "dd-MM-yyyy HH:mm";
})(DateFormat || (DateFormat = {}));
var FilterMatchMode;
(function (FilterMatchMode) {
  FilterMatchMode["LESS_OR_EQUAL"] = "lessorequal";
  FilterMatchMode["GREATER_OR_EQUAL"] = "greaterorequal";
  FilterMatchMode["EQUALS"] = "equals";
  FilterMatchMode["NOT_EQUALS"] = "notequals";
  FilterMatchMode["GREATER"] = "greater";
  FilterMatchMode["LESS"] = "less";
  FilterMatchMode["BETWEEN"] = "between";
  FilterMatchMode["NOT_BETWEEN"] = "notbetween";
  FilterMatchMode["IN"] = "in";
  FilterMatchMode["NOT_IN"] = "notin";
  FilterMatchMode["LIKE"] = "like";
  FilterMatchMode["NOT_LIKE"] = "notlike";
  FilterMatchMode["DATEBETWEEN"] = "datebetween";
  FilterMatchMode["DATEEQUALS"] = "dateequals";
})(FilterMatchMode || (FilterMatchMode = {}));
var FilterType;
(function (FilterType) {
  FilterType["INPUT"] = "input";
  FilterType["DATEPICKER"] = "datepicker";
  FilterType["MULTISELECT"] = "multiselect";
  FilterType["SELECT"] = "select";
  FilterType["RANGE"] = "range";
  FilterType["CHECKBOX"] = "checkbox";
  FilterType["INPUT_SWITCH"] = "inputSwitch";
  FilterType["TEMPLATE"] = "template";
  FilterType["DROPDOWN"] = "dropdown";
})(FilterType || (FilterType = {}));
var KeyCode;
(function (KeyCode) {
  KeyCode["KeyA"] = "KeyA";
  KeyCode["KeyB"] = "KeyB";
  KeyCode["KeyC"] = "KeyC";
  KeyCode["KeyD"] = "KeyD";
  KeyCode["KeyE"] = "KeyE";
  KeyCode["KeyF"] = "KeyF";
  KeyCode["KeyG"] = "KeyG";
  KeyCode["KeyH"] = "KeyH";
  KeyCode["KeyI"] = "KeyI";
  KeyCode["KeyJ"] = "KeyJ";
  KeyCode["KeyK"] = "KeyK";
  KeyCode["KeyL"] = "KeyL";
  KeyCode["KeyM"] = "KeyM";
  KeyCode["KeyN"] = "KeyN";
  KeyCode["KeyO"] = "KeyO";
  KeyCode["KeyP"] = "KeyP";
  KeyCode["KeyQ"] = "KeyQ";
  KeyCode["KeyR"] = "KeyR";
  KeyCode["KeyS"] = "KeyS";
  KeyCode["KeyT"] = "KeyT";
  KeyCode["KeyU"] = "KeyU";
  KeyCode["KeyV"] = "KeyV";
  KeyCode["KeyW"] = "KeyW";
  KeyCode["KeyX"] = "KeyX";
  KeyCode["KeyY"] = "KeyY";
  KeyCode["KeyZ"] = "KeyZ";
  KeyCode["Digit0"] = "Digit0";
  KeyCode["Digit1"] = "Digit1";
  KeyCode["Digit2"] = "Digit2";
  KeyCode["Digit3"] = "Digit3";
  KeyCode["Digit4"] = "Digit4";
  KeyCode["Digit5"] = "Digit5";
  KeyCode["Digit6"] = "Digit6";
  KeyCode["Digit7"] = "Digit7";
  KeyCode["Digit8"] = "Digit8";
  KeyCode["Digit9"] = "Digit9";
  KeyCode["F1"] = "F1";
  KeyCode["F2"] = "F2";
  KeyCode["F3"] = "F3";
  KeyCode["F4"] = "F4";
  KeyCode["F5"] = "F5";
  KeyCode["F6"] = "F6";
  KeyCode["F7"] = "F7";
  KeyCode["F8"] = "F8";
  KeyCode["F9"] = "F9";
  KeyCode["F10"] = "F10";
  KeyCode["F11"] = "F11";
  KeyCode["F12"] = "F12";
  KeyCode["F13"] = "F13";
  KeyCode["F14"] = "F14";
  KeyCode["F15"] = "F15";
  KeyCode["F16"] = "F16";
  KeyCode["F17"] = "F17";
  KeyCode["F18"] = "F18";
  KeyCode["F19"] = "F19";
  KeyCode["F20"] = "F20";
  KeyCode["MetaLeft"] = "MetaLeft";
  KeyCode["AltLeft"] = "AltLeft";
  KeyCode["ShiftLeft"] = "ShiftLeft";
  KeyCode["ControlLeft"] = "ControlLeft";
  KeyCode["MetaRight"] = "MetaRight";
  KeyCode["AltRight"] = "AltRight";
  KeyCode["ShiftRight"] = "ShiftRight";
  KeyCode["ControlRight"] = "ControlRight";
  KeyCode["ArrowRight"] = "ArrowRight";
  KeyCode["ArrowUp"] = "ArrowUp";
  KeyCode["ArrowLeft"] = "ArrowLeft";
  KeyCode["ArrowDown"] = "ArrowDown";
  KeyCode["Function"] = "Function";
  KeyCode["Delete"] = "Delete";
  KeyCode["Home"] = "Home";
  KeyCode["End"] = "End";
  KeyCode["PageUp"] = "PageUp";
  KeyCode["PageDown"] = "PageDown";
  KeyCode["Backquote"] = "Backquote";
  KeyCode["CapsLock"] = "CapsLock";
  KeyCode["Tab"] = "Tab";
  KeyCode["Space"] = "Space";
  KeyCode["Backspace"] = "Backspace";
  KeyCode["Enter"] = "Enter";
  KeyCode["Escape"] = "Escape";
  KeyCode["Backslash"] = "Backslash";
  KeyCode["Comma"] = "Comma";
  KeyCode["Equal"] = "Equal";
  KeyCode["BracketLeft"] = "BracketLeft";
  KeyCode["Minus"] = "Minus";
  KeyCode["Period"] = "Period";
  KeyCode["Quote"] = "Quote";
  KeyCode["BracketRight"] = "BracketRight";
  KeyCode["Semicolon"] = "Semicolon";
  KeyCode["Slash"] = "Slash";
  KeyCode["Numpad0"] = "Numpad0";
  KeyCode["Numpad1"] = "Numpad1";
  KeyCode["Numpad2"] = "Numpad2";
  KeyCode["Numpad3"] = "Numpad3";
  KeyCode["Numpad4"] = "Numpad4";
  KeyCode["Numpad5"] = "Numpad5";
  KeyCode["Numpad6"] = "Numpad6";
  KeyCode["Numpad7"] = "Numpad7";
  KeyCode["Numpad8"] = "Numpad8";
  KeyCode["Numpad9"] = "Numpad9";
  KeyCode["NumLock"] = "NumLock";
  KeyCode["NumpadEqual"] = "NumpadEqual";
  KeyCode["NumpadDivide"] = "NumpadDivide";
  KeyCode["NumpadMultiply"] = "NumpadMultiply";
  KeyCode["NumpadSubtract"] = "NumpadSubtract";
  KeyCode["NumpadAdd"] = "NumpadAdd";
  KeyCode["NumpadEnter"] = "NumpadEnter";
  KeyCode["NumpadDecimal"] = "NumpadDecimal";
})(KeyCode || (KeyCode = {}));
const numberKeyCodes = [KeyCode.Digit0, KeyCode.Digit1, KeyCode.Digit2, KeyCode.Digit3, KeyCode.Digit4, KeyCode.Digit5, KeyCode.Digit6, KeyCode.Digit7, KeyCode.Digit8, KeyCode.Digit9, KeyCode.Numpad0, KeyCode.Numpad1, KeyCode.Numpad2, KeyCode.Numpad3, KeyCode.Numpad4, KeyCode.Numpad5, KeyCode.Numpad6, KeyCode.Numpad7, KeyCode.Numpad8, KeyCode.Numpad9];
var NotificationIcons;
(function (NotificationIcons) {
  NotificationIcons["success"] = "fas fa-check";
  NotificationIcons["warning"] = "fas fa-exclamation-triangle";
  NotificationIcons["error"] = "fas fa-times";
  NotificationIcons["info"] = "fas fa-exclamation";
  NotificationIcons["file"] = "far fa-file";
  NotificationIcons["appointment"] = "fas fa-calendar-alt";
})(NotificationIcons || (NotificationIcons = {}));
var PanningStates;
(function (PanningStates) {
  PanningStates["start"] = "start";
  PanningStates["move"] = "move";
  PanningStates["end"] = "end";
  PanningStates["cancel"] = "cancel";
  PanningStates["left"] = "left";
  PanningStates["right"] = "right";
  PanningStates["up"] = "up";
  PanningStates["down"] = "down";
})(PanningStates || (PanningStates = {}));
var Positions;
(function (Positions) {
  Positions["TOP_RIGHT"] = "top-right";
  Positions["TOP_LEFT"] = "top-left";
  Positions["BOTTOM_RIGHT"] = "bottom-right";
  Positions["BOTTOM_LEFT"] = "bottom-left";
})(Positions || (Positions = {}));
var ScanListTypes;
(function (ScanListTypes) {
  ScanListTypes["history"] = "history";
  ScanListTypes["checkList"] = "checkList";
})(ScanListTypes || (ScanListTypes = {}));
var SkeletonShape;
(function (SkeletonShape) {
  SkeletonShape["circle"] = "circle";
  SkeletonShape["square"] = "square";
  SkeletonShape["bar"] = "bar";
})(SkeletonShape || (SkeletonShape = {}));
var SnapPositions;
(function (SnapPositions) {
  SnapPositions[SnapPositions["open"] = 0] = "open";
  SnapPositions[SnapPositions["gap"] = 3] = "gap";
  SnapPositions[SnapPositions["threeQuarter"] = 25] = "threeQuarter";
  SnapPositions[SnapPositions["half"] = 50] = "half";
  SnapPositions[SnapPositions["quarter"] = 75] = "quarter";
  SnapPositions[SnapPositions["header"] = 90] = "header";
  SnapPositions[SnapPositions["handle"] = 96] = "handle";
  SnapPositions[SnapPositions["closed"] = 100] = "closed";
})(SnapPositions || (SnapPositions = {}));
var SprintStates;
(function (SprintStates) {
  SprintStates["Planned"] = "Planned";
  SprintStates["Active"] = "Active";
  SprintStates["Finished"] = "Finished";
})(SprintStates || (SprintStates = {}));
var StepStatus;
(function (StepStatus) {
  StepStatus["Completed"] = "completed";
  StepStatus["Active"] = "active";
  StepStatus["Inactive"] = "inactive";
})(StepStatus || (StepStatus = {}));
var TableColumnTypes;
(function (TableColumnTypes) {
  TableColumnTypes["ARRAY"] = "array";
  TableColumnTypes["BOOLEAN"] = "boolean";
  TableColumnTypes["DATE"] = "date";
  TableColumnTypes["DATETIME"] = "datetime";
  TableColumnTypes["ICON"] = "icon";
  TableColumnTypes["ICON_TEXT"] = "icon-text";
  TableColumnTypes["ENUM"] = "enum";
  TableColumnTypes["FLOAT"] = "float";
  TableColumnTypes["NUMBER"] = "number";
  TableColumnTypes["PERCENTAGE"] = "percentage";
  TableColumnTypes["STRING"] = "string";
  TableColumnTypes["DROPDOWN"] = "dropdown";
  TableColumnTypes["INPUT_TEXT"] = "input";
})(TableColumnTypes || (TableColumnTypes = {}));
var TableSortingMode;
(function (TableSortingMode) {
  TableSortingMode["DEFAULT"] = "default";
  TableSortingMode["DRAG_AND_DROP"] = "drag-and-drop";
})(TableSortingMode || (TableSortingMode = {}));
var ToastMessageTypes;
(function (ToastMessageTypes) {
  ToastMessageTypes["error"] = "error";
  ToastMessageTypes["info"] = "info";
  ToastMessageTypes["warning"] = "warning";
  ToastMessageTypes["success"] = "success";
})(ToastMessageTypes || (ToastMessageTypes = {}));
var ToastPosition;
(function (ToastPosition) {
  ToastPosition["TOP_LEFT"] = "top-left";
  ToastPosition["TOP_RIGHT"] = "top-right";
  ToastPosition["BOTTOM_RIGHT"] = "bottom-right";
  ToastPosition["BOTTOM_LEFT"] = "bottom-left";
  ToastPosition["TOP_CENTER"] = "top-center";
  ToastPosition["BOTTOM_CENTER"] = "bottom-center";
  ToastPosition["CENTER"] = "center";
})(ToastPosition || (ToastPosition = {}));
var NavigateDirection;
(function (NavigateDirection) {
  NavigateDirection["next"] = "next";
  NavigateDirection["prev"] = "prev";
})(NavigateDirection || (NavigateDirection = {}));
var DialogAction;
(function (DialogAction) {
  DialogAction["submit"] = "submit";
  DialogAction["cancel"] = "cancel";
})(DialogAction || (DialogAction = {}));
var TooltipPositions;
(function (TooltipPositions) {
  TooltipPositions["RIGHT"] = "right";
  TooltipPositions["LEFT"] = "left";
  TooltipPositions["TOP"] = "top";
  TooltipPositions["BOTTOM"] = "bottom";
})(TooltipPositions || (TooltipPositions = {}));
class TableColumn {
  /**
   * Get and set the columns
   */
  static getColumns(columns) {
    const setColumns = [];
    columns.forEach(column => {
      setColumns.push(new TableColumn(column));
    });
    return setColumns;
  }
  constructor(data) {
    /**
     * Define whether the column is readonly
     */
    this.readonly = true;
    /**
     * Define whether the dropdown and multiselect will have an input field in them to search through the options
     */
    this.filterSearchable = true;
    /**
     * Define whether the date filter should be of type range. Default is single.
     */
    this.filterDateRange = true;
    /**
     * Define whether the dropdown or multiselect filter should have virtual scroll
     */
    this.filterVirtualScroll = false;
    /**
     * Define whether the calendar should show the button bar or not.
     */
    this.filterShowButtonBar = true;
    /**
     * Define whether the calendar or multi-select should show an apply button or not.
     */
    this.filterShowApplyButton = true;
    /**
     * Define the text to be displayed in the confirm button at the range filter
     */
    this.confirmRangeButtonText = 'Ok';
    /**
     * Define the value it should emit when the checkbox filter is checked
     */
    this.checkedCheckboxValue = 1;
    /**
     * Define if range is it between dates or simple numbers
     */
    this.isRangeBetweenDates = true;
    // Set values
    this.field = data.field;
    this.header = data.header;
    this.type = data.type || TableColumnTypes.STRING;
    this.styleClass = data.styleClass || '';
    this.sortable = data.sortable !== undefined ? data.sortable : true;
    this.sortField = data.sortField || data.field;
    this.filterable = data.filterable !== undefined ? data.filterable : true;
    this.readonly = data.readonly !== undefined ? data.readonly : true;
    this.filterField = data.filterField || data.field;
    this.filterType = data.filterType || FilterType.INPUT;
    this.filterOptions = data.filterOptions || null;
    this.filterMatchMode = data.filterMatchMode || (data.filterDateRange ? FilterMatchMode.BETWEEN : FilterMatchMode.LIKE);
    this.icon = data.icon || '';
    this.isObservable = data.isObservable || false;
    this.filterPlaceholder = data.filterPlaceholder || this.header;
    this.filterSearchable = data.filterSearchable;
    this.filterDateRange = data.filterDateRange;
    this.filterVirtualScroll = data.filterVirtualScroll;
    this.filterIconClass = data.filterIconClass;
    this.filterShowButtonBar = data.filterShowButtonBar;
    this.filterShowApplyButton = data.filterShowApplyButton || true;
    this.filterMinDate = data.filterMinDate || null;
    this.filterMaxDate = data.filterMaxDate || null;
    this.filterTimeout = data.filterTimeout || 0;
    this.appendTo = data.appendTo || 'body';
    this.rangeFilterFromLabel = data.rangeFilterFromLabel || 'From';
    this.rangeFilterToLabel = data.rangeFilterToLabel || 'To';
    this.rangeFilterEmptyLabel = data.rangeFilterEmptyLabel || 'Empty is no limit';
    this.reorderable = data.reorderable || false;
    this.frozen = data.frozen || false;
    this.actions = data.actions || null;
    this.tooltip = data.tooltip || null;
    this.tooltipPosition = data.tooltipPosition || null;
    this.manageable = data.manageable;
    this.currencyFilter = data.currencyFilter;
    this.updateRangeFilterOnClose = data.updateRangeFilterOnClose;
    this.showConfirmRangeButton = data.showConfirmRangeButton;
    this.confirmRangeButtonText = data.confirmRangeButtonText || 'Ok';
    this.isRangeBetweenDates = data.isRangeBetweenDates || false;
    this.checkedCheckboxValue = data.checkedCheckboxValue === 0 ? 0 : 1;
    this.visible = data.hasOwnProperty('visible') ? data.visible : true;
    let defaultFilterShowApplyButton = true;
    if (this.filterType === FilterType.MULTISELECT) {
      defaultFilterShowApplyButton = false;
    }
    this.filterShowApplyButton = data.filterShowApplyButton || defaultFilterShowApplyButton;
    // Prefill boolean filter dropdown
    if (this.filterOptions === null && this.type === TableColumnTypes.BOOLEAN) {
      this.filterOptions = [{
        value: '',
        label: 'general.all'
      }, {
        value: '1',
        label: 'general.yes'
      }, {
        value: '0',
        label: 'general.no'
      }];
      this.filterType = FilterType.SELECT;
      this.filterMatchMode = FilterMatchMode.EQUALS;
    }
  }
}
class AllActionsIsHiddenPipe {
  transform(value) {
    const hiddenValues = value.map(action => {
      return isObservable(action.hidden) ? action.hidden : of(!!action.hidden);
    });
    return forkJoin(hiddenValues).pipe(map(values => {
      return values.every(item => !!item);
    }));
  }
  static {
    this.ɵfac = function AllActionsIsHiddenPipe_Factory(t) {
      return new (t || AllActionsIsHiddenPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "allActionsIsHidden",
      type: AllActionsIsHiddenPipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AllActionsIsHiddenPipe, [{
    type: Pipe,
    args: [{
      name: 'allActionsIsHidden'
    }]
  }], null, null);
})();

/**
 * @jsdoc Pipe
 *
 * @description
 * This pipe is used to extract property value by key
 *
 * @example
 *  customer | propertyValue: 'first_name'
 */
class CapturumPropertyValuePipe {
  transform(item, property) {
    if (property.includes('.')) {
      return property.split('.').reduce((object, key) => object ? object[key] : '-', item);
    }
    return item[property];
  }
  static {
    this.ɵfac = function CapturumPropertyValuePipe_Factory(t) {
      return new (t || CapturumPropertyValuePipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "propertyValue",
      type: CapturumPropertyValuePipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CapturumPropertyValuePipe, [{
    type: Pipe,
    args: [{
      name: 'propertyValue'
    }]
  }], null, null);
})();
class InterpolationPipe {
  constructor(propertyValuePipe) {
    this.propertyValuePipe = propertyValuePipe;
  }
  giveWords(text) {
    const regExp = /\{\{(.+?)\}\}/ig;
    return text.match(regExp);
  }
  giveWord(text) {
    const regExp = /\{{(.*)\}}/i;
    return text.match(regExp)[1];
  }
  transform(text = '', messages) {
    if (!text) {
      return;
    }
    const bracketValues = this.giveWords(text);
    if (!bracketValues) {
      return text;
    }
    bracketValues.forEach(item => {
      const clearWord = this.giveWord(item);
      if (!messages[clearWord]) {
        messages[clearWord] = '';
      }
      text = text.replace(item, this.propertyValuePipe.transform(messages, clearWord));
    });
    return `${text}`;
  }
  static {
    this.ɵfac = function InterpolationPipe_Factory(t) {
      return new (t || InterpolationPipe)(i0.ɵɵdirectiveInject(CapturumPropertyValuePipe, 16));
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "interpolation",
      type: InterpolationPipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InterpolationPipe, [{
    type: Pipe,
    args: [{
      name: 'interpolation'
    }]
  }], () => [{
    type: CapturumPropertyValuePipe
  }], null);
})();

/**
 * Transform a string|number to an Observable
 */
class ObservablePipe {
  transform(value, args) {
    if (!(value instanceof Observable)) {
      return of(value);
    }
    return value;
  }
  static {
    this.ɵfac = function ObservablePipe_Factory(t) {
      return new (t || ObservablePipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "observablePipe",
      type: ObservablePipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ObservablePipe, [{
    type: Pipe,
    args: [{
      name: 'observablePipe'
    }]
  }], null, null);
})();

/**
 * Transform a string|number to an Observable
 */
class SplitPipe {
  transform(value, splitCharacters, splitIndex) {
    if (!!value) {
      return value.split(splitCharacters)[splitIndex];
    }
    return '';
  }
  static {
    this.ɵfac = function SplitPipe_Factory(t) {
      return new (t || SplitPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "splitPipe",
      type: SplitPipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SplitPipe, [{
    type: Pipe,
    args: [{
      name: 'splitPipe'
    }]
  }], null, null);
})();

/**
 * @jsdoc Pipe
 *
 * @description
 * This pipe is used to extract TemplateRef from QueryList instance.
 *
 * @example
 *  templates | columnTemplate: 'templateName'
 */
class CapturumColumnTemplatePipe {
  transform(value, name) {
    const node = value?.find(item => item.name === name);
    return node ? node.template : null;
  }
  static {
    this.ɵfac = function CapturumColumnTemplatePipe_Factory(t) {
      return new (t || CapturumColumnTemplatePipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "columnTemplate",
      type: CapturumColumnTemplatePipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CapturumColumnTemplatePipe, [{
    type: Pipe,
    args: [{
      name: 'columnTemplate'
    }]
  }], null, null);
})();
var AssetType;
(function (AssetType) {
  AssetType["images"] = "images";
  AssetType["icons"] = "icons";
  AssetType["translations"] = "i18n";
  AssetType["fonts"] = "fonts";
  AssetType["flags"] = "flags";
})(AssetType || (AssetType = {}));
class AssetsPipe {
  transform(value, folder = 'images') {
    if (AssetType[folder]) {
      return `./assets/${AssetType[folder]}/${value}`;
    }
    return `./assets/${folder}/${value}`;
  }
  static {
    this.ɵfac = function AssetsPipe_Factory(t) {
      return new (t || AssetsPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "assets",
      type: AssetsPipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AssetsPipe, [{
    type: Pipe,
    args: [{
      name: 'assets'
    }]
  }], null, null);
})();
class DefaultPipe {
  transform(value, defaultValue = '-') {
    const isEmptyValue = typeof value !== 'number' && !!!value;
    const isEmptyArray = Array.isArray(value) && value.length === 0;
    const isEmptyObject = typeof value === 'number' ? false : this.isEmptyObject(value);
    return isEmptyValue || isEmptyArray || isEmptyObject ? defaultValue : value;
  }
  isEmptyObject(value) {
    return value && Object.keys(value).length === 0 && Object.getPrototypeOf(value) === Object.prototype;
  }
  static {
    this.ɵfac = function DefaultPipe_Factory(t) {
      return new (t || DefaultPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "default",
      type: DefaultPipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DefaultPipe, [{
    type: Pipe,
    args: [{
      name: 'default'
    }]
  }], null, null);
})();
class GetOptionPipe {
  transform(value, options, property = 'value') {
    const option = (options || []).find(item => item[property] === value);
    return option ? option : null;
  }
  static {
    this.ɵfac = function GetOptionPipe_Factory(t) {
      return new (t || GetOptionPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "getOption",
      type: GetOptionPipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GetOptionPipe, [{
    type: Pipe,
    args: [{
      name: 'getOption'
    }]
  }], null, null);
})();
class BaseDataValuePipe {
  constructor(translateService) {
    this.translateService = translateService;
  }
  transform(value, notTranslated = false) {
    const key = `base-data.${value}`;
    if (notTranslated) {
      return key;
    }
    return value ? this.translateService.instant(key) : null;
  }
  static {
    this.ɵfac = function BaseDataValuePipe_Factory(t) {
      return new (t || BaseDataValuePipe)(i0.ɵɵdirectiveInject(i1.TranslateService, 16));
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "baseDataValue",
      type: BaseDataValuePipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BaseDataValuePipe, [{
    type: Pipe,
    args: [{
      name: 'baseDataValue'
    }]
  }], () => [{
    type: i1.TranslateService
  }], null);
})();
class CapturumDatePipe {
  constructor() {
    this.datePipe = new DatePipe('en-US');
  }
  transform(value, dateFormatting = 'mediumDate', defaultValue = '-') {
    const adjustedValue = this.checkIsSafariBrowser(value);
    if (!adjustedValue || !new Date(adjustedValue).getTime()) {
      return defaultValue;
    }
    return this.datePipe.transform(new Date(adjustedValue), dateFormatting);
  }
  checkIsSafariBrowser(date) {
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari && date && !(date instanceof Date)) {
      date = date.replace(/ /g, 'T');
    }
    return date;
  }
  static {
    this.ɵfac = function CapturumDatePipe_Factory(t) {
      return new (t || CapturumDatePipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "capDate",
      type: CapturumDatePipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CapturumDatePipe, [{
    type: Pipe,
    args: [{
      name: 'capDate'
    }]
  }], null, null);
})();
class ResolveStyleClassExpressionPipe {
  transform(styleClassExpression, item) {
    if (styleClassExpression) {
      return styleClassExpression(item);
    }
    return null;
  }
  static {
    this.ɵfac = function ResolveStyleClassExpressionPipe_Factory(t) {
      return new (t || ResolveStyleClassExpressionPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "resolveStyleClassExpressionPipe",
      type: ResolveStyleClassExpressionPipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ResolveStyleClassExpressionPipe, [{
    type: Pipe,
    args: [{
      name: 'resolveStyleClassExpressionPipe'
    }]
  }], null, null);
})();
class ThemeService {
  static getPageWidth() {
    return Math.max(document.body.scrollWidth, document.documentElement.scrollWidth, document.body.offsetWidth, document.documentElement.offsetWidth, document.documentElement.clientWidth);
  }
  initializeTheme() {
    this.theme = this.getTheme();
    this.applyChanges();
  }
  setProps(props) {
    this.theme = {
      ...this.theme,
      ...props
    };
    this.applyChanges();
  }
  setRandomColors() {
    Object.keys(this.theme).forEach(key => this.theme[key] = this.getRandomColor());
    this.applyChanges();
  }
  applyChanges() {
    Object.keys(this.theme).forEach(key => document.documentElement.style.setProperty(`${key}`, this.theme[key]));
  }
  /* Can be replaced with an API call */
  getTheme() {
    return {
      [CSSVarNames.Primary]: Colors.Primary,
      [CSSVarNames.Secondary]: Colors.Secondary,
      [CSSVarNames.Third]: Colors.Third,
      [CSSVarNames.Text]: Colors.Text,
      [CSSVarNames.Text2]: Colors.Text2,
      [CSSVarNames.Text3]: Colors.Text3,
      [CSSVarNames.Text4]: Colors.Text4,
      [CSSVarNames.Success]: Colors.Success,
      [CSSVarNames.Warning]: Colors.Warning,
      [CSSVarNames.Confirm]: Colors.Confirm,
      [CSSVarNames.PrimaryShadow]: Colors.PrimaryShadow,
      [CSSVarNames.SecondaryShadow]: Colors.SecondaryShadow,
      [CSSVarNames.ThirdShadow]: Colors.ThirdShadow,
      [CSSVarNames.SuccessShadow]: Colors.SuccessShadow,
      [CSSVarNames.WarningShadow]: Colors.WarningShadow,
      [CSSVarNames.ErrorShadow]: Colors.ErrorShadow,
      [CSSVarNames.ConfirmShadow]: Colors.ConfirmShadow,
      [CSSVarNames.Error]: Colors.Error,
      [CSSVarNames.Info]: Colors.Info,
      [CSSVarNames.Font]: 'times, serif',
      [CSSVarNames.ToastText]: Colors.ToastText,
      [CSSVarNames.InputHeight]: '30px',
      [CSSVarNames.DynamicFilterInputHeight]: '30px'
    };
  }
  getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
  static {
    this.ɵfac = function ThemeService_Factory(t) {
      return new (t || ThemeService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ThemeService,
      factory: ThemeService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ThemeService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class ToastService {
  constructor(messageService) {
    this.messageService = messageService;
  }
  /**
   * Show error message
   *
   * @param title
   * @param detail
   * @param options
   *
   * @return void
   */
  error(title, detail, options) {
    this.message({
      severity: 'error',
      summary: title,
      detail,
      ...options
    });
  }
  /**
   * Show warning
   *
   * @param title
   * @param detail
   * @param options
   *
   * @return void
   */
  warning(title, detail, options) {
    this.message({
      severity: 'warn',
      summary: title,
      detail,
      ...options
    });
  }
  /**
   * Show success message
   *
   * @param title
   * @param detail
   * @param options
   *
   * @return void
   */
  success(title, detail, options) {
    this.message({
      severity: 'success',
      summary: title,
      detail,
      ...options
    });
  }
  /**
   * Show info message
   *
   * @param title
   * @param detail
   * @param options
   *
   * @return void
   */
  info(title, detail, options) {
    this.message({
      severity: 'info',
      summary: title,
      detail,
      ...options
    });
  }
  /**
   * Add message to message service
   *
   * @param message
   *
   * @return void
   */
  message(message) {
    this.messageService.add(message);
  }
  static {
    this.ɵfac = function ToastService_Factory(t) {
      return new (t || ToastService)(i0.ɵɵinject(i1$1.MessageService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ToastService,
      factory: ToastService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ToastService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1$1.MessageService
  }], null);
})();
class TableRouteConfigService {
  constructor(router, activatedRoute) {
    this.router = router;
    this.activatedRoute = activatedRoute;
    this.routeParams$ = new Subject();
    this.destroy$ = new Subject();
    this.routeParams$.asObservable().pipe(concatMap(params => from(this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: params,
      queryParamsHandling: 'merge'
    }))), takeUntil(this.destroy$)).subscribe();
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  saveConfigInParams(params) {
    this.routeParams$.next(params);
  }
  static {
    this.ɵfac = function TableRouteConfigService_Factory(t) {
      return new (t || TableRouteConfigService)(i0.ɵɵinject(i1$2.Router), i0.ɵɵinject(i1$2.ActivatedRoute));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: TableRouteConfigService,
      factory: TableRouteConfigService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TableRouteConfigService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1$2.Router
  }, {
    type: i1$2.ActivatedRoute
  }], null);
})();
const ValidationMessage = {
  required: 'validation-errors.required',
  email: 'validation-errors.email',
  minlength: 'validation-errors.minlength',
  maxlength: 'validation-errors.maxlength',
  min: 'validation-errors.min',
  max: 'validation-errors.max',
  pattern: 'validation-errors.pattern',
  password: 'validation-errors.password',
  fileType: 'validation-errors.file-type',
  requiredFileType: 'validation-errors.required-file-type',
  requiredFileSize: 'validation-errors.required-file-size',
  specialChars: 'validation-errors.no-special-characters',
  script: 'validation-errors.no-scripts'
};
class ValidatorService {
  constructor() {
    this.messages = new BehaviorSubject({
      messages: ValidationMessage
    });
    this.messages$ = this.messages.asObservable();
    this.backendValidation = new BehaviorSubject(null);
    this.backendValidation$ = this.backendValidation.asObservable();
  }
  extractFormControl(ngControl) {
    if (ngControl) {
      return ngControl.control;
    }
    return null;
  }
  setValidationMessages(messages) {
    this.messages.next({
      messages: {
        ...ValidationMessage,
        ...messages
      }
    });
  }
  setBackendErrorsOnForm(form, backendErrors) {
    const generatedErrors = {};
    Object.keys(backendErrors).forEach(key => {
      const errorKey = `BE_${key}`;
      if (form.controls[key]) {
        if (Array.isArray(backendErrors[key])) {
          form.controls[key].setErrors({
            [errorKey]: true
          });
          generatedErrors[errorKey] = backendErrors[key].join(' | ');
        }
      }
    });
    this.setValidationMessages(generatedErrors);
  }
  static {
    this.ɵfac = function ValidatorService_Factory(t) {
      return new (t || ValidatorService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ValidatorService,
      factory: ValidatorService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ValidatorService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class CapturumDialogService {
  constructor(componentFactoryResolver, appRef, injector, document) {
    this.componentFactoryResolver = componentFactoryResolver;
    this.appRef = appRef;
    this.injector = injector;
    this.document = document;
    this.dialogComponentRefMap = new Map();
  }
  open(componentType, config, containerComponent = DynamicDialogComponent) {
    const dialogRef = this.appendDialogComponentToBody(config, containerComponent);
    this.document.activeElement?.blur();
    this.dialogComponentRefMap.get(dialogRef).instance.childComponentType = componentType;
    return dialogRef;
  }
  appendDialogComponentToBody(config, containerComponent = DynamicDialogComponent) {
    const map = new WeakMap();
    map.set(DynamicDialogConfig, config);
    const dialogRef = new DynamicDialogRef();
    map.set(DynamicDialogRef, dialogRef);
    const sub = dialogRef.onClose.subscribe(() => {
      this.dialogComponentRefMap.get(dialogRef).instance.close();
    });
    const destroySub = dialogRef.onDestroy.subscribe(() => {
      this.removeDialogComponentFromBody(dialogRef);
      destroySub.unsubscribe();
      sub.unsubscribe();
    });
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(containerComponent);
    const componentRef = componentFactory.create(new DynamicDialogInjector(this.injector, map));
    this.appRef.attachView(componentRef.hostView);
    const domElem = componentRef.hostView.rootNodes[0];
    document.body.appendChild(domElem);
    this.dialogComponentRefMap.set(dialogRef, componentRef);
    return dialogRef;
  }
  removeDialogComponentFromBody(dialogRef) {
    const dialogComponentRef = this.dialogComponentRefMap.get(dialogRef);
    if (!dialogRef || !this.dialogComponentRefMap.has(dialogRef)) {
      return;
    }
    this.appRef.detachView(dialogComponentRef.hostView);
    dialogComponentRef.destroy();
    this.dialogComponentRefMap.delete(dialogRef);
  }
  static {
    this.ɵfac = function CapturumDialogService_Factory(t) {
      return new (t || CapturumDialogService)(i0.ɵɵinject(i0.ComponentFactoryResolver), i0.ɵɵinject(i0.ApplicationRef), i0.ɵɵinject(i0.Injector), i0.ɵɵinject(DOCUMENT));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: CapturumDialogService,
      factory: CapturumDialogService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CapturumDialogService, [{
    type: Injectable
  }], () => [{
    type: i0.ComponentFactoryResolver
  }, {
    type: i0.ApplicationRef
  }, {
    type: i0.Injector
  }, {
    type: Document,
    decorators: [{
      type: Inject,
      args: [DOCUMENT]
    }]
  }], null);
})();
class BaseStorageProvider {
  /**
   * Get the item from the storage.
   * If the item has an expiration date, it will be checked and removed if it is expired.
   *
   * @param key - The key of the item to get.
   * @returns The item from the storage.
   */
  getItem(key) {
    const now = new Date().getTime();
    const item = this.storage.getItem(key);
    if (item) {
      const parsedItem = JSON.parse(item);
      if (parsedItem?.expiresAt) {
        if (parsedItem?.expiresAt < now) {
          this.removeItem(key);
          return null;
        }
        return parsedItem?.value;
      }
    }
    return JSON.parse(this.storage.getItem(key));
  }
  /**
   * Set the item in the storage. If the expiration is provided, it will be set.
   * The expiration is in minutes.
   * If the expiration is not provided, the item will be set without expiration.
   *
   * @param key - The key of the item to set.
   * @param value - The value of the item to set.
   * @param expiration - The expiration of the item to set in minutes.
   */
  setItem(key, value, expiration) {
    if (expiration) {
      const now = new Date().getTime();
      const expiresAt = now + expiration * 60 * 1000;
      this.storage.setItem(key, JSON.stringify({
        value,
        expiresAt
      }));
    } else {
      this.storage.setItem(key, JSON.stringify(value));
    }
  }
  /**
   * Remove the item from the storage.
   * @param key
   */
  removeItem(key) {
    return this.storage.removeItem(key);
  }
}
class LocalStorageService extends BaseStorageProvider {
  constructor() {
    super(...arguments);
    this.storage = localStorage;
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵLocalStorageService_BaseFactory;
      return function LocalStorageService_Factory(t) {
        return (ɵLocalStorageService_BaseFactory || (ɵLocalStorageService_BaseFactory = i0.ɵɵgetInheritedFactory(LocalStorageService)))(t || LocalStorageService);
      };
    })();
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: LocalStorageService,
      factory: LocalStorageService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LocalStorageService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class SessionStorageService extends BaseStorageProvider {
  constructor() {
    super(...arguments);
    this.storage = sessionStorage;
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵSessionStorageService_BaseFactory;
      return function SessionStorageService_Factory(t) {
        return (ɵSessionStorageService_BaseFactory || (ɵSessionStorageService_BaseFactory = i0.ɵɵgetInheritedFactory(SessionStorageService)))(t || SessionStorageService);
      };
    })();
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: SessionStorageService,
      factory: SessionStorageService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SessionStorageService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class CapturumAutofocusDirective {
  constructor(el) {
    this.el = el;
    this.capAutofocus = false;
  }
  ngOnInit() {
    if (this.capAutofocus) {
      this.el.nativeElement.focus();
    }
  }
  static {
    this.ɵfac = function CapturumAutofocusDirective_Factory(t) {
      return new (t || CapturumAutofocusDirective)(i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: CapturumAutofocusDirective,
      selectors: [["", "capAutofocus", ""]],
      inputs: {
        capAutofocus: "capAutofocus"
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CapturumAutofocusDirective, [{
    type: Directive,
    args: [{
      selector: '[capAutofocus]'
    }]
  }], () => [{
    type: i0.ElementRef
  }], {
    capAutofocus: [{
      type: Input
    }]
  });
})();
class CapturumHostViewDirective {
  constructor(viewContainerRef) {
    this.viewContainerRef = viewContainerRef;
  }
  static {
    this.ɵfac = function CapturumHostViewDirective_Factory(t) {
      return new (t || CapturumHostViewDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: CapturumHostViewDirective,
      selectors: [["", "capHostView", ""]]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CapturumHostViewDirective, [{
    type: Directive,
    args: [{
      selector: '[capHostView]'
    }]
  }], () => [{
    type: i0.ViewContainerRef
  }], null);
})();
const KEYFILTER_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => CapturumKeyfilterDirective),
  multi: true
};
/** This object is no longer exported from primeng since version 9.1.3 */
const DEFAULT_MASKS = {
  pint: /[\d]/,
  int: /[\d\-]/,
  pnum: /[\d\.]/,
  money: /[\d\.\s,]/,
  num: /[\d\-\.]/,
  hex: /[0-9a-f]/i,
  email: /[a-z0-9_\.\-@]/i,
  alpha: /[a-z_]/i,
  alphanum: /[a-z0-9_]/i
};
class CapturumKeyfilterDirective extends KeyFilter {
  set pattern(_pattern) {
    this._pattern = _pattern;
    this.regex = DEFAULT_MASKS[this._pattern] || this._pattern;
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCapturumKeyfilterDirective_BaseFactory;
      return function CapturumKeyfilterDirective_Factory(t) {
        return (ɵCapturumKeyfilterDirective_BaseFactory || (ɵCapturumKeyfilterDirective_BaseFactory = i0.ɵɵgetInheritedFactory(CapturumKeyfilterDirective)))(t || CapturumKeyfilterDirective);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: CapturumKeyfilterDirective,
      selectors: [["", "capKeyFilter", ""]],
      inputs: {
        pattern: [i0.ɵɵInputFlags.None, "emKeyFilter", "pattern"]
      },
      features: [i0.ɵɵProvidersFeature([KEYFILTER_VALIDATOR]), i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CapturumKeyfilterDirective, [{
    type: Directive,
    args: [{
      selector: '[capKeyFilter]',
      providers: [KEYFILTER_VALIDATOR]
    }]
  }], null, {
    pattern: [{
      type: Input,
      args: ['emKeyFilter']
    }]
  });
})();
class CapturumScrollDirective {
  constructor() {
    this.bottomOffset = 100;
    this.topOffset = 100;
    this.onScroll = new EventEmitter();
  }
  scrolled($event) {
    this.elementScrollEvent($event);
  }
  elementScrollEvent($event) {
    const target = $event.target;
    const scrollPosition = target.scrollHeight - target.scrollTop;
    const offsetHeight = target.offsetHeight;
    const isReachingTop = target.scrollTop < this.topOffset;
    const isReachingBottom = scrollPosition - offsetHeight < this.bottomOffset;
    const emitValue = {
      isReachingBottom,
      isReachingTop,
      originalEvent: $event,
      isWindowEvent: false
    };
    this.onScroll.emit(emitValue);
  }
  static {
    this.ɵfac = function CapturumScrollDirective_Factory(t) {
      return new (t || CapturumScrollDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: CapturumScrollDirective,
      selectors: [["", "capDetectScroll", ""]],
      hostBindings: function CapturumScrollDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("scroll", function CapturumScrollDirective_scroll_HostBindingHandler($event) {
            return ctx.scrolled($event);
          });
        }
      },
      inputs: {
        bottomOffset: "bottomOffset",
        topOffset: "topOffset"
      },
      outputs: {
        onScroll: "onScroll"
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CapturumScrollDirective, [{
    type: Directive,
    args: [{
      selector: '[capDetectScroll]'
    }]
  }], null, {
    bottomOffset: [{
      type: Input
    }],
    topOffset: [{
      type: Input
    }],
    onScroll: [{
      type: Output
    }],
    scrolled: [{
      type: HostListener,
      args: ['scroll', ['$event']]
    }]
  });
})();
class CapturumTemplateDirective {
  constructor(template) {
    this.template = template;
  }
  getType() {
    return this.name;
  }
  static {
    this.ɵfac = function CapturumTemplateDirective_Factory(t) {
      return new (t || CapturumTemplateDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: CapturumTemplateDirective,
      selectors: [["", "capTemplate", ""]],
      inputs: {
        name: [i0.ɵɵInputFlags.None, "capTemplate", "name"]
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CapturumTemplateDirective, [{
    type: Directive,
    args: [{
      selector: '[capTemplate]'
    }]
  }], () => [{
    type: i0.TemplateRef
  }], {
    name: [{
      type: Input,
      args: ['capTemplate']
    }]
  });
})();
class ClickOutsideDirective {
  constructor(_elementRef) {
    this._elementRef = _elementRef;
    this.capClickOutside = new EventEmitter();
  }
  onClick(targetElement) {
    const clickedInside = this._elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.capClickOutside.emit(targetElement);
    }
  }
  static {
    this.ɵfac = function ClickOutsideDirective_Factory(t) {
      return new (t || ClickOutsideDirective)(i0.ɵɵdirectiveInject(i0.ElementRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: ClickOutsideDirective,
      selectors: [["", "capClickOutside", ""]],
      hostBindings: function ClickOutsideDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click", function ClickOutsideDirective_click_HostBindingHandler($event) {
            return ctx.onClick($event.target);
          }, false, i0.ɵɵresolveDocument);
        }
      },
      outputs: {
        capClickOutside: "capClickOutside"
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ClickOutsideDirective, [{
    type: Directive,
    args: [{
      selector: '[capClickOutside]'
    }]
  }], () => [{
    type: i0.ElementRef
  }], {
    capClickOutside: [{
      type: Output
    }],
    onClick: [{
      type: HostListener,
      args: ['document:click', ['$event.target']]
    }]
  });
})();
class DynamicDialogcontentDirective {
  constructor(viewContainerRef) {
    this.viewContainerRef = viewContainerRef;
  }
  static {
    this.ɵfac = function DynamicDialogcontentDirective_Factory(t) {
      return new (t || DynamicDialogcontentDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: DynamicDialogcontentDirective,
      selectors: [["", "capDynamicDialogContent", ""]]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DynamicDialogcontentDirective, [{
    type: Directive,
    args: [{
      selector: '[capDynamicDialogContent]'
    }]
  }], () => [{
    type: i0.ViewContainerRef
  }], null);
})();
class CapturumButtonLoadingDirective {
  set submitting(value) {
    if (value === this.isSubmitted) {
      return;
    }
    this.isSubmitted = value;
    this.cdr.detectChanges();
    this.addLoadingStuff();
  }
  get submitting() {
    return this.isSubmitted;
  }
  constructor(el, cdr, translateService) {
    this.el = el;
    this.cdr = cdr;
    this.translateService = translateService;
    this.loadingClass = 'cap-button--loading';
    this.keepLabel = true;
  }
  ngAfterViewInit() {
    this.buttonElement = this.el.nativeElement.getElementsByClassName('p-button').item(0);
  }
  addLoadingStuff() {
    this.buttonElement = this.el.nativeElement.getElementsByClassName('p-button').item(0);
    if (this.buttonElement) {
      const method = this.isSubmitted ? 'add' : 'remove';
      const label = this.isSubmitted && !this.keepLabel ? this.translateService.instant('component.loading') : this.label || '&nbsp;';
      this.buttonElement.classList[method](this.loadingClass);
      const span = this.buttonElement.getElementsByClassName('p-button-label').item(0);
      if (span) {
        span.innerText = label;
      }
      // Workaround because Primeng 14 doesn't create a span inside p-button if no label is set
      if (!this.label && !span) {
        const emptySpan = document.createElement('span');
        emptySpan.classList.add('p-button-label');
        emptySpan.innerHTML = '&nbsp;';
        this.buttonElement.appendChild(emptySpan);
      }
    }
  }
  static {
    this.ɵfac = function CapturumButtonLoadingDirective_Factory(t) {
      return new (t || CapturumButtonLoadingDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i1.TranslateService));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: CapturumButtonLoadingDirective,
      selectors: [["", "capButtonLoading", ""]],
      inputs: {
        submitting: "submitting",
        loadingClass: "loadingClass",
        label: "label",
        keepLabel: "keepLabel"
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CapturumButtonLoadingDirective, [{
    type: Directive,
    args: [{
      selector: '[capButtonLoading]'
    }]
  }], () => [{
    type: i0.ElementRef
  }, {
    type: i0.ChangeDetectorRef
  }, {
    type: i1.TranslateService
  }], {
    submitting: [{
      type: Input
    }],
    loadingClass: [{
      type: Input
    }],
    label: [{
      type: Input
    }],
    keepLabel: [{
      type: Input
    }]
  });
})();
class CapturumRouterLinkDirective extends RouterLink {
  constructor(router2, route2, tabIndex, renderer2, elementRef, locationStrategyRef) {
    super(router2, route2, tabIndex, renderer2, elementRef, locationStrategyRef);
    this.router2 = router2;
    this.route2 = route2;
    this.tabIndex = tabIndex;
    this.renderer2 = renderer2;
    this.elementRef = elementRef;
    this.locationStrategyRef = locationStrategyRef;
    this.href = null;
    this.destroy$ = new Subject();
    this.onChanges = new Subject();
    const tagName = elementRef.nativeElement.tagName?.toLowerCase();
    this.isAnAnchorElement = tagName === 'a' || tagName === 'area';
    if (this.isAnAnchorElement) {
      router2.events.pipe(takeUntil(this.destroy$)).subscribe(url => {
        if (url instanceof NavigationEnd) {
          this.updateTargetUrlAndHref();
        }
      });
    }
  }
  set routerLink(commands) {
    super.routerLink = commands;
  }
  ngOnChanges() {
    this.updateTargetUrlAndHref();
    this.onChanges.next(this);
  }
  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
  /**
   * override the default Routerlink onClick method to prevent the default behavior
   */
  onClick() {
    return false;
  }
  handleClick(event, button, ctrlKey, metaKey) {
    if (this.urlTree === null) {
      return true;
    }
    if (this.isAnAnchorElement) {
      // prevent anchor links from opening in new tab with target attribute
      if (typeof this.target === 'string' && this.target !== '_self') {
        return true;
      }
    }
    // Event.button 1 = Auxiliary button, usually the wheel button or the middle button (if present)
    if (metaKey || ctrlKey || button !== 0 && button === 1) {
      event.preventDefault();
      event.stopImmediatePropagation();
      this.openNewwindow();
      return false;
    } else {
      const extras = {
        skipLocationChange: this.skipLocationChange,
        replaceUrl: this.replaceUrl,
        state: this.state
      };
      this.router2.navigateByUrl(this.urlTree, extras);
    }
    return false;
  }
  openNewwindow() {
    const origin = window.location.origin;
    const tabUrl = `${origin}${this.urlTree}`;
    window.open(tabUrl, '_blank');
  }
  updateTargetUrlAndHref() {
    this.href = this.urlTree !== null ? this.locationStrategyRef.prepareExternalUrl(this.router2.serializeUrl(this.urlTree)) : null;
  }
  static {
    this.ɵfac = function CapturumRouterLinkDirective_Factory(t) {
      return new (t || CapturumRouterLinkDirective)(i0.ɵɵdirectiveInject(i1$2.Router), i0.ɵɵdirectiveInject(i1$2.ActivatedRoute), i0.ɵɵinjectAttribute('tabindex'), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i2.LocationStrategy));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: CapturumRouterLinkDirective,
      selectors: [["", "capRouterLink", ""], ["a", "routerLink", ""], ["area", "routerLink", ""]],
      hostVars: 2,
      hostBindings: function CapturumRouterLinkDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click", function CapturumRouterLinkDirective_click_HostBindingHandler($event) {
            return ctx.handleClick($event, $event.button, $event.ctrlKey, $event.metaKey);
          });
        }
        if (rf & 2) {
          i0.ɵɵattribute("target", ctx.target)("href", ctx.href, i0.ɵɵsanitizeUrlOrResourceUrl);
        }
      },
      inputs: {
        target: "target",
        routerLink: [i0.ɵɵInputFlags.None, "capRouterLink", "routerLink"]
      },
      features: [i0.ɵɵProvidersFeature([{
        provide: RouterLink,
        useExisting: CapturumRouterLinkDirective
      }]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CapturumRouterLinkDirective, [{
    type: Directive,
    args: [{
      selector: '[capRouterLink], a[routerLink], area[routerLink]',
      providers: [{
        provide: RouterLink,
        useExisting: CapturumRouterLinkDirective
      }]
    }]
  }], () => [{
    type: i1$2.Router
  }, {
    type: i1$2.ActivatedRoute
  }, {
    type: undefined,
    decorators: [{
      type: Attribute,
      args: ['tabindex']
    }]
  }, {
    type: i0.Renderer2
  }, {
    type: i0.ElementRef
  }, {
    type: i2.LocationStrategy
  }], {
    target: [{
      type: HostBinding,
      args: ['attr.target']
    }, {
      type: Input
    }],
    href: [{
      type: HostBinding,
      args: ['attr.href']
    }],
    routerLink: [{
      type: Input,
      args: ['capRouterLink']
    }],
    handleClick: [{
      type: HostListener,
      args: ['click', ['$event', '$event.button', '$event.ctrlKey', '$event.metaKey']]
    }]
  });
})();
class FileReaderUtils {
  static read(file, callback) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = e => callback(e.target.result);
  }
}
class NumberUtils {
  static {
    this.commaSeperatedCurrencyRegExp = new RegExp('(?=.*?\\d)^\\$?(([1-9]\\d{0,2}(,\\d{3})*)|\\d+)?(\\.\\d{1,2})?$');
  }
  static {
    this.periodSeperatedCurrencyRegExp = new RegExp('(?=.*?\\d)^\\$?(([1-9]\\d{0,2}(\\.\\d{3})*)|\\d+)?(,\\d{1,2})?$');
  }
  static convertFromCurrency(value) {
    if (NumberUtils.commaSeperatedCurrencyRegExp.test(value)) {
      return +value.toString().replace(/,/g, '');
    } else if (NumberUtils.periodSeperatedCurrencyRegExp.test(value)) {
      return +value.toString().replace(/\./g, '').replace(',', '.');
    } else {
      return value;
    }
  }
}

/**
 * This class is used to declare all common transform methods
 */
class TransformUtils {
  static toIsoDate(value) {
    const datePipe = new DatePipe('en-US');
    return datePipe.transform(value, 'yyyy-MM-ddTHH:mm:ssZZZZZ');
  }
}
class CapturumRowActionComponent {
  constructor(router) {
    this.router = router;
    this.icon = 'fas fa-ellipsis-v';
    this.actions = [];
    this.isOpen = false;
    this.clickAction = new EventEmitter();
  }
  toggleMenu() {
    this.isOpen = !this.isOpen;
  }
  executeAction(action, item) {
    this.isOpen = false;
    this.clickAction.emit({
      action,
      item
    });
    if (action && action.url) {
      this.router.navigate([action.url]);
    } else if (action.callback) {
      action.callback(item);
    }
  }
  static {
    this.ɵfac = function CapturumRowActionComponent_Factory(t) {
      return new (t || CapturumRowActionComponent)(i0.ɵɵdirectiveInject(i1$2.Router));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CapturumRowActionComponent,
      selectors: [["cap-row-action"]],
      inputs: {
        item: "item",
        icon: "icon",
        actions: "actions",
        isOpen: "isOpen"
      },
      outputs: {
        clickAction: "clickAction"
      },
      decls: 3,
      vars: 5,
      consts: [["class", "row-action", 3, "capClickOutside", 4, "ngIf"], [1, "row-action", 3, "capClickOutside"], [3, "click"], [1, "row-action__items", 3, "ngClass"], [4, "ngFor", "ngForOf"], [3, "click", 4, "ngIf"], [1, "row-action__icon"], [1, "row-action__info"], [1, "title"], ["class", "description", 4, "ngIf"], [1, "description"]],
      template: function CapturumRowActionComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, CapturumRowActionComponent_div_0_Template, 4, 7, "div", 0);
          i0.ɵɵpipe(1, "allActionsIsHidden");
          i0.ɵɵpipe(2, "async");
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngIf", (ctx.actions == null ? null : ctx.actions.length) && i0.ɵɵpipeBind1(2, 3, i0.ɵɵpipeBind1(1, 1, ctx.actions)) === false);
        }
      },
      dependencies: [i2.NgClass, i2.NgForOf, i2.NgIf, ClickOutsideDirective, i2.AsyncPipe, ObservablePipe, AllActionsIsHiddenPipe],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CapturumRowActionComponent, [{
    type: Component,
    args: [{
      selector: 'cap-row-action',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<div *ngIf=\"actions?.length && (actions | allActionsIsHidden | async) === false\" class=\"row-action\" (capClickOutside)=\"isOpen = false\">\n    <i [class]=\"icon\" (click)=\"toggleMenu()\" [attr.data-test]=\"'action_toggler_' + item?.id\"></i>\n\n    <ul class=\"row-action__items\"\n        [ngClass]=\"{'row-action__items--open': isOpen}\">\n        <ng-container *ngFor=\"let action of actions\">\n            <li *ngIf=\"(action?.hidden | observablePipe | async) !== true\"\n                (click)=\"executeAction(action, item)\"\n                [attr.data-test]=\"action?.key || action?.value\">\n                <div class=\"row-action__icon\">\n                    <i [class]=\"action?.icon\"></i>\n                </div>\n\n                <div class=\"row-action__info\">\n                    <p class=\"title\">{{ action?.label | observablePipe | async }}</p>\n                    <p *ngIf=\"action?.description | observablePipe | async as description\"\n                       class=\"description\">\n                        {{ description }}\n                    </p>\n                </div>\n            </li>\n        </ng-container>\n    </ul>\n</div>\n"
    }]
  }], () => [{
    type: i1$2.Router
  }], {
    item: [{
      type: Input
    }],
    icon: [{
      type: Input
    }],
    actions: [{
      type: Input
    }],
    isOpen: [{
      type: Input
    }],
    clickAction: [{
      type: Output
    }]
  });
})();
class CapturumValidatorComponent {
  constructor(validatorService) {
    this.validatorService = validatorService;
    this.isMat = false;
    this.validatorService.messages$.subscribe(response => this.messages = response.messages);
  }
  get containerClassName() {
    return this.isMat ? 'mat-validator' : 'validator';
  }
  /**
   * Check if the validator has a custom validation message
   *
   * @param errors
   *
   * @return string
   */
  message(errors) {
    const properties = Object.keys(errors);
    let customMessage;
    if (this.customValidation) {
      customMessage = properties.indexOf(this.customValidation.name) > -1;
    }
    return customMessage ? this.customValidation.text : this.messages[properties[0]];
  }
  /**
   * Extract control name from formGroup
   *
   * @return string
   */
  getControlName() {
    let controlName = '';
    const parent = this.control ? this.control['parent'] : '';
    if (parent instanceof FormGroup) {
      for (const name in parent.controls) {
        if (this.control === parent.controls[name]) {
          controlName = name;
        }
      }
    }
    return controlName;
  }
  getInterpolationDataByError(errors) {
    const properties = Object.keys(errors);
    const name = this.customName ? this.customName : this.convertToKebab(this.getControlName());
    switch (properties[0]) {
      case 'min':
        return {
          field: name,
          min: errors.min.min
        };
      case 'max':
        return {
          field: name,
          max: errors.max.max
        };
      case 'minlength':
        return {
          field: name,
          length: errors.minlength.requiredLength
        };
      case 'maxlength':
        return {
          field: name,
          length: errors.maxlength.requiredLength
        };
      default:
        return {
          field: name
        };
    }
  }
  /**
   * Convert string to kebab
   *
   * @param name
   *
   * @return string
   */
  convertToKebab(name) {
    return name.replace(/([a-z])([A-Z])/g, '$1-$2').replace(/[\s_]+/g, '-').toLowerCase();
  }
  static {
    this.ɵfac = function CapturumValidatorComponent_Factory(t) {
      return new (t || CapturumValidatorComponent)(i0.ɵɵdirectiveInject(ValidatorService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CapturumValidatorComponent,
      selectors: [["cap-validator"]],
      inputs: {
        control: "control",
        customName: "customName",
        customValidation: "customValidation",
        isMat: "isMat"
      },
      decls: 2,
      vars: 4,
      consts: [[3, "ngClass"], [4, "ngIf"], [1, "error-text"], [1, "fa", "fa-exclamation-triangle"], [3, "innerHTML"]],
      template: function CapturumValidatorComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵtemplate(1, CapturumValidatorComponent_ng_container_1_Template, 5, 4, "ng-container", 1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵclassProp("show", (ctx.control == null ? null : ctx.control.invalid) && (ctx.control == null ? null : ctx.control.touched));
          i0.ɵɵproperty("ngClass", ctx.containerClassName);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.control == null ? null : ctx.control.errors);
        }
      },
      dependencies: [i2.NgClass, i2.NgIf, i1.TranslatePipe],
      styles: ["[_ngcontent-%COMP%]:root{--cap-input-height: 30px;--cap-dynamic-filter-input-height: 30px;--cap-dynamic-filter-border-radius: 5px;--cap-dynamic-filter-border-width: 1px;--cap-dynamic-filters-hide-filters-on-mobile: false;--cap-dynamic-filters-filter-flex: 1;--cap-dynamic-filters-flex-wrap: wrap;--cap-dynamic-filter-width: 200px;--cap-dynamic-filter-label-font-size: 14px;--cap-dynamic-filter-label-font-weight: 600;--cap-dynamic-filter-label-font-family: \"Source Sans Pro\";--cap-dynamic-filter-label-font-color: #5f6062;--cap-dynamic-filter-placeholder-font-weight: 600;--cap-dynamic-filter-placeholder-font-color: #5f6062;--cap-dynamic-filter-icon-opacity: 1;--cap-dynamic-filter-icon-color: var(--cap-primary-color)}.validator[_ngcontent-%COMP%]{visibility:hidden;min-height:20px}.mat-validator[_ngcontent-%COMP%]{visibility:hidden;top:100%;display:block;position:absolute}.show[_ngcontent-%COMP%]{visibility:visible}.error-text[_ngcontent-%COMP%]{color:var(--error-color);font-size:.625rem;margin:0}.error-text[_ngcontent-%COMP%]   i[_ngcontent-%COMP%]{margin-right:4px}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CapturumValidatorComponent, [{
    type: Component,
    args: [{
      selector: 'cap-validator',
      template: "<div [class.show]=\"control?.invalid && control?.touched\" [ngClass]=\"containerClassName\">\n    <ng-container *ngIf=\"control?.errors\">\n        <span class=\"error-text\">\n            <i class=\"fa fa-exclamation-triangle\"></i>\n          <span [innerHTML]=\"message(control?.errors) | translate: getInterpolationDataByError(control?.errors)\"></span>\n        </span>\n    </ng-container>\n</div>\n",
      styles: [":root{--cap-input-height: 30px;--cap-dynamic-filter-input-height: 30px;--cap-dynamic-filter-border-radius: 5px;--cap-dynamic-filter-border-width: 1px;--cap-dynamic-filters-hide-filters-on-mobile: false;--cap-dynamic-filters-filter-flex: 1;--cap-dynamic-filters-flex-wrap: wrap;--cap-dynamic-filter-width: 200px;--cap-dynamic-filter-label-font-size: 14px;--cap-dynamic-filter-label-font-weight: 600;--cap-dynamic-filter-label-font-family: \"Source Sans Pro\";--cap-dynamic-filter-label-font-color: #5f6062;--cap-dynamic-filter-placeholder-font-weight: 600;--cap-dynamic-filter-placeholder-font-color: #5f6062;--cap-dynamic-filter-icon-opacity: 1;--cap-dynamic-filter-icon-color: var(--cap-primary-color)}.validator{visibility:hidden;min-height:20px}.mat-validator{visibility:hidden;top:100%;display:block;position:absolute}.show{visibility:visible}.error-text{color:var(--error-color);font-size:.625rem;margin:0}.error-text i{margin-right:4px}\n"]
    }]
  }], () => [{
    type: ValidatorService
  }], {
    control: [{
      type: Input
    }],
    customName: [{
      type: Input
    }],
    customValidation: [{
      type: Input
    }],
    isMat: [{
      type: Input
    }]
  });
})();
const BASE_DIRECTIVES = [CapturumTemplateDirective, CapturumScrollDirective, CapturumKeyfilterDirective, CapturumHostViewDirective, CapturumAutofocusDirective, ClickOutsideDirective, DynamicDialogcontentDirective, CapturumButtonLoadingDirective];
const BASE_PIPES = [InterpolationPipe, SplitPipe, ObservablePipe, CapturumColumnTemplatePipe, AllActionsIsHiddenPipe, AssetsPipe, DefaultPipe, GetOptionPipe, BaseDataValuePipe, CapturumDatePipe, CapturumPropertyValuePipe, ResolveStyleClassExpressionPipe];
class CapturumSharedModule {
  static {
    this.ɵfac = function CapturumSharedModule_Factory(t) {
      return new (t || CapturumSharedModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CapturumSharedModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [CapturumPropertyValuePipe],
      imports: [CommonModule, TranslateModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CapturumSharedModule, [{
    type: NgModule,
    args: [{
      declarations: [CapturumValidatorComponent, CapturumRowActionComponent, ...BASE_PIPES, ...BASE_DIRECTIVES],
      imports: [CommonModule, TranslateModule],
      exports: [CapturumValidatorComponent, CapturumRowActionComponent, ...BASE_PIPES, ...BASE_DIRECTIVES],
      providers: [CapturumPropertyValuePipe]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { AllActionsIsHiddenPipe, AssetsPipe, BaseDataValuePipe, CSSClasses, CSSVarNames, CapturumAutofocusDirective, CapturumButtonLoadingDirective, CapturumColumnTemplatePipe, CapturumDatePipe, CapturumDialogService, CapturumHostViewDirective, CapturumKeyfilterDirective, CapturumPropertyValuePipe, CapturumRouterLinkDirective, CapturumRowActionComponent, CapturumScrollDirective, CapturumSharedModule, CapturumTemplateDirective, CapturumValidatorComponent, ClickOutsideDirective, Colors, DEFAULT_MASKS, DateFormat, DefaultPipe, DialogAction, Directions, DynamicDialogcontentDirective, FileReaderUtils, FilterMatchMode, FilterType, GetOptionPipe, InterpolationPipe, KEYFILTER_VALIDATOR, KeyCode, LocalStorageService, NavigateDirection, NotificationIcons, NumberUtils, ObservablePipe, PanningStates, Positions, ResolveStyleClassExpressionPipe, ScanListTypes, SessionStorageService, SkeletonShape, SnapPositions, SplitPipe, SprintStates, StepStatus, TableColumn, TableColumnTypes, TableRouteConfigService, TableSortingMode, ThemeService, ToastMessageTypes, ToastPosition, ToastService, TooltipPositions, TransformUtils, ValidationMessage, ValidatorService, ValueAccessorBase, numberKeyCodes };
