import { CapturumTemplateDirective } from '@capturum/ui/api';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Input,
  QueryList,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgTemplateOutlet } from '@angular/common';
import { StepperComponent } from '../stepper/stepper.component';

@Component({
  selector: 'app-step-wizard',
  templateUrl: './step-wizard.component.html',
  styleUrls: ['./step-wizard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [StepperComponent, NgTemplateOutlet, TranslateModule],
})
export class StepWizardComponent implements AfterViewInit {
  @Input()
  public steps: { label: string; completed: boolean }[];

  @Input()
  public key: string;

  @Input()
  public activeStepIndex = 0;

  @ContentChildren(CapturumTemplateDirective)
  public templates: QueryList<CapturumTemplateDirective>;

  public templateMap = {};
  constructor(private cdr: ChangeDetectorRef) {}

  public ngAfterViewInit(): void {
    this.templates.forEach((item, index) => {
      this.templateMap[index] = item.template;
    });

    this.cdr.detectChanges();
  }

  public nextStep(): void {
    if (this.activeStepIndex >= this.steps.length - 1) {
      this.activeStepIndex++;
      this.saveData();
    }
  }

  public previousStep(): void {
    if (this.activeStepIndex > 0) {
      this.activeStepIndex++;
      this.saveData();
    }
  }

  public saveData(): void {}
}
