export enum AuthRoutes {
  auth = 'auth',
  login = 'login',
}

export enum AdminRoutes {
  admin = 'admin',
  baseData = 'base-data',
  campaigns = 'campaigns',
  characteristics = 'characteristics',
  communications = 'communications',
  clients = 'clients',
  config = 'config',
  dashboard = 'dashboard',
  exports = 'exports',
  forms = 'forms',
  callForm = 'call-form',
  inbox = 'inbox',
  leads = 'leads',
  participations = 'participations',
  projects = 'projects',
  roles = 'roles',
  settings = 'settings',
  suppliers = 'suppliers',
  tenants = 'tenants',
  translations = 'translations',
  users = 'users',
  fundEmissions = 'emissions',
  profile = 'users/profile',
}

export enum ActionRoutes {
  add = 'add',
  detail = 'detail',
  edit = 'edit',
  list = 'list',
  profile = 'profile',
  values = 'values',
  newEmission = 'new-emission',
  manage = 'manage',
}

export enum PublicRoutes {
  responseForm = 'response-form',
  result = 'result',
  success = 'success',
  error = 'error',
  notFound = '404',
}

export const AllRoutes = {
  ...AuthRoutes,
  ...AdminRoutes,
  ...ActionRoutes,
  ...PublicRoutes,
};
