<app-stepper [steps]="steps" [activeIndex]="activeStepIndex"></app-stepper>

<div class="wizard-step__window mt-3">
  @if (templateMap) {
    @for (step of steps; track step; let index = $index) {
      @if (activeStepIndex === index) {
        <div class="step-wizard__content">
          <div class="step-wizard__header">
            {{ step.label | translate }}
          </div>
          <div class="step-wizard__body">
            <ng-container [ngTemplateOutlet]="templateMap[index]"> </ng-container>
          </div>
        </div>
      }
    }
  }
</div>
