import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '@modules/address/models/address.model';

@Pipe({
  name: 'fullAddress',
  standalone: true,
})
export class FullAddressPipe implements PipeTransform {
  public transform(address: Address): string {
    let full = '';

    if (address) {
      if (address.street) {
        full += address.street;
      }

      if (address.housenumber) {
        full += ` ${address.housenumber}`;
      }

      if (address.addition) {
        full += `${address.addition}`;
      }

      if (address.zipcode) {
        full += `, ${address.zipcode}`;
      }

      if (address.city) {
        full += `, ${address.city}`;
      }

      if (address.country) {
        full += `, ${address.country}`;
      }
    }

    return full;
  }
}
