import { DropdownModule } from 'primeng/dropdown';
import { CapturumCalendarModule } from '@capturum/ui/calendar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DecimalPipe, PercentPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// Libraries, 3d party components
import { FormlyModule } from '@ngx-formly/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPermissionsModule } from 'ngx-permissions';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { AccordionModule } from 'primeng/accordion';
import { FormModule as CapturumFormlyModule } from '@capturum/formly';
import { SharedModule as EmendisSharedModule } from '@capturum/shared';
import { CapturumTableModule } from '@capturum/ui/table';
// Custom pipes
import { KebabCasePipe } from './pipes/kebab-case.pipe';
import { FullNamePipe } from '@shared/pipes/full-name.pipe';
import { ArraySortPipe } from '@shared/pipes/array-sort.pipe';
import { FullAddressPipe } from '@modules/address/pipes/full-address.pipe';
import { AccordionHeaderComponent } from '@shared/components/accordion/accordion-header/accordion-header.component';
import { ColumnBlockComponent } from '@shared/components/layout/column-block/column-block.component';
import { HorizontalInputComponent } from '@shared/components/form/horizontal-input/horizontal-input.component';
import { BasicBlockComponent } from '@shared/components/layout/basic-block/basic-block.component';
import { FileUploadComponent } from '@shared/components/file-upload/file-upload.component';
import { ObjectKeysPipe } from './pipes/object-keys.pipe';
import { PercentConvertPipe } from '@shared/pipes/percent-convert.pipe';
// Custom directives
import { CopyClipboardDirective } from '@shared/directives/copy-clipboard.directive';
import { NumbersNotationDirective } from '@shared/directives/numbers-notation.directive';
import { ValidationErrorComponent } from './components/validation-error/validation-error.component';
import { CheckboxFilterComponent } from '@shared/components/checkbox-filter/checkbox-filter/checkbox-filter.component';
import { ShowAddressFieldDirective } from '@shared/directives/show-address-field.directive';
import { AddressTypePipe } from '@shared/pipes/address-type.pipe';
import { WordMergeTypeNameRowPipe } from '@shared/pipes/word-merge-type-name-row.pipe';
import { LimitInputDirective } from './directives/limit-input.directive';
import { StepWizardComponent } from './components/step-wizard/step-wizard.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { DefaultValuePipe } from '@shared/pipes/default-value.pipe';
import { CapturumLoaderModule } from '@capturum/ui/loader';
import { CapturumFileUploadModule } from '@capturum/ui/file-upload';
import { ButtonModule } from 'primeng/button';
import { CapturumCheckboxModule } from '@capturum/ui/checkbox';
import { CapturumButtonModule } from '@capturum/ui/button';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CapturumInputModule } from '@capturum/ui/input';
import { CapturumDropdownModule } from '@capturum/ui/dropdown';
import { CapturumMultiSelectModule } from '@capturum/ui/multi-select';
import { CapturumTextareaModule } from '@capturum/ui/textarea';
import { DialogModule } from 'primeng/dialog';
import { CapturumSelectButtonModule } from '@capturum/ui/select-button';
import { CapturumInputMaskModule } from '@capturum/ui/input-mask';
import { ConfigOverviewComponent } from './components/config-overview/config-overview.component';
import { ReplaceTextPipe } from './pipes/replace-text.pipe';

const CapturumUIModules = [
  CapturumFileUploadModule,
  CapturumCheckboxModule,
  CapturumInputModule,
  CapturumButtonModule,
  CapturumDropdownModule,
  CapturumMultiSelectModule,
  CapturumTextareaModule,
  CapturumSelectButtonModule,
  CapturumLoaderModule,
  CapturumCalendarModule,
  CapturumInputMaskModule,
  CapturumTableModule,
];

const PrimeNgModules = [OverlayPanelModule, DialogModule, ConfirmDialogModule, DropdownModule];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TableModule,
    ReactiveFormsModule,
    ButtonModule,
    CapturumLoaderModule,
    FormlyModule.forRoot({
      validationMessages: [
        {
          name: 'required',
          message: 'Dit veld is verplicht',
        },
        {
          name: 'uniqueClientName',
          message: 'Deze naam is al in gebruik',
        },
        {
          name: 'email',
          message: 'Vul een geldig e-mailadres in.',
        },
        {
          name: 'password',
          message:
            'Het wachtwoord moet minstens 6 karakters lang zijn, 1 hoofdletter, 1 nummer en een speciaal teken bevatten.',
        },
      ],
    }),
    ToastModule,
    EmendisSharedModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    AccordionModule,
    NgxPermissionsModule,
    ...CapturumUIModules,
    ...PrimeNgModules,
    KebabCasePipe,
    ArraySortPipe,
    BasicBlockComponent,
    FullAddressPipe,
    FullNamePipe,
    PercentConvertPipe,
    DefaultValuePipe,
    AccordionHeaderComponent,
    ColumnBlockComponent,
    HorizontalInputComponent,
    FileUploadComponent,
    CopyClipboardDirective,
    NumbersNotationDirective,
    ShowAddressFieldDirective,
    ValidationErrorComponent,
    CheckboxFilterComponent,
    ObjectKeysPipe,
    AddressTypePipe,
    WordMergeTypeNameRowPipe,
    LimitInputDirective,
    StepWizardComponent,
    StepperComponent,
    ConfigOverviewComponent,
    ReplaceTextPipe,
  ],
  exports: [
    CommonModule,
    TableModule,
    ButtonModule,
    FormlyModule,
    CapturumFormlyModule,
    TranslateModule,
    NgxPermissionsModule,
    KebabCasePipe,
    DefaultValuePipe,
    PercentConvertPipe,
    AccordionModule,
    BasicBlockComponent,
    FullAddressPipe,
    StepperComponent,
    FullNamePipe,
    AccordionHeaderComponent,
    ColumnBlockComponent,
    HorizontalInputComponent,
    FileUploadComponent,
    ToastModule,
    CopyClipboardDirective,
    NumbersNotationDirective,
    ShowAddressFieldDirective,
    EmendisSharedModule,
    EmendisSharedModule,
    ValidationErrorComponent,
    CheckboxFilterComponent,
    ObjectKeysPipe,
    AddressTypePipe,
    WordMergeTypeNameRowPipe,
    LimitInputDirective,
    StepWizardComponent,
    ReplaceTextPipe,
    ...CapturumUIModules,
    ...PrimeNgModules,
    ArraySortPipe,
  ],
  providers: [CurrencyPipe, FullNamePipe, PercentPipe, DecimalPipe, AddressTypePipe, WordMergeTypeNameRowPipe],
})
export class SharedModule {}
