<div class="stepper d-flex">
  @for (step of steps; track step; let last = $last; let index = $index) {
    <div class="step">
      <div class="step-circle" [class.completed]="activeIndex > index">
        @if (index < activeIndex || step.completed) {
          <i class="fas fa-check"></i>
        }
        @if (index === activeIndex && !step.completed) {
          <div class="current-step"></div>
        }
      </div>
      <div class="step-label" [class.disabled]="activeIndex < index">
        {{ step.label | translate }}
      </div>
    </div>
    @if (!last) {
      <div class="step-line" [class.active]="activeIndex > index"></div>
    }
  }
</div>
