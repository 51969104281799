import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceText',
  standalone: true,
})
export class ReplaceTextPipe implements PipeTransform {
  public transform(value: string, pattern: string, replacement: string): string {
    if (!pattern) {
      return value;
    }

    const regex = new RegExp(pattern, 'gi');
    const match = value.match(regex);

    if (!match) {
      return value;
    }

    return value.replace(regex, replacement);
  }
}
