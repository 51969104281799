<div class="d-flex flex-wrap justify-content-between w-100">
  <div class="d-block">
    <ng-content></ng-content>

    @if (deletable) {
      <i (click)="delete.emit()" class="fa fa-trash text-danger ml-2"></i>
    }
  </div>

  <div class="toggle-button">
    <span class="show-expanded">{{ 'form.show-less.button' | translate }}</span>

    <span class="hide-expanded">{{ 'form.show-more.button' | translate }}</span>
  </div>
</div>
