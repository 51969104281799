import { Pipe, PipeTransform } from '@angular/core';
import { isEmpty } from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'defaultValue',
  standalone: true,
})
export class DefaultValuePipe implements PipeTransform {
  public constructor(private readonly translateService: TranslateService) {}

  public transform(value: any, defaultValue = '-'): any {
    return (typeof value !== 'number' && !value) || // is empty value
      (Array.isArray(value) && value.length === 0) || // is empty array
      (typeof value === 'number' ? false : isEmpty(value)) || // is empty object
      value === this.translateService.instant('base-data.null') // is translation for missing value
      ? defaultValue
      : value;
  }
}
