import { AddressTypeFieldsModel } from '@modules/client/models/address-type-fields.model';
import { AddressType } from '@core/enums/address-type.enum';
import { zipCodeValidator } from '@shared/validators/zipcode.validator';

export const AddressFieldConfig: AddressTypeFieldsModel[] = [
  {
    type: AddressType.PostalAddress,
    controls: [
      { name: 'street', required: true },
      { name: 'city', required: true },
      { name: 'country', required: true },
      { name: 'housenumber', required: true },
      { name: 'zipcode', required: true, validators: [zipCodeValidator()] },
      { name: 'addition', required: false },
    ],
    correspondence: true,
  },
  {
    type: AddressType.MobilePhone,
    controls: [
      { name: 'phone', required: true },
      { name: 'addressline_2', required: false },
    ],
    correspondence: false,
  },
  {
    type: AddressType.LandlinePhone,
    controls: [
      { name: 'phone', required: true },
      { name: 'addressline_2', required: false },
    ],
    correspondence: false,
  },
  {
    type: AddressType.EmailAddress,
    controls: [{ name: 'email', required: true }],
    correspondence: true,
  },
];
