import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-validation-error',
  templateUrl: './validation-error.component.html',
  styleUrls: ['./validation-error.component.scss'],
  standalone: true,
  imports: [TranslateModule],
})
export class ValidationErrorComponent {
  @Input()
  public messageKey: string;

  @Input()
  public messageProps: any = {};

  @Input()
  public onlyShowOnSubmit = true;
}
