import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-column-block',
  templateUrl: 'column-block.component.html',
  standalone: true,
})
export class ColumnBlockComponent {
  @Input() public heading: string | Observable<string>;
}
