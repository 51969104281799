@if (dropFiles) {
  <label
    [for]="inputId"
    [class.dragover]="dragover"
    (dragleave)="onDragLeave($event)"
    (dragover)="onDragOver($event)"
    (drop)="onDrop($event)"
    class="file-upload text-center">
    <i class="fas fa-upload"></i>
    <span>
      {{ 'general.drop-file.label' | translate }}
    </span>
  </label>
} @else {
  <span (click)="focusInput()">
    <ng-content></ng-content>
    @if (!content) {
      <cap-button [label]="label" [styleClass]="styleClass" [icon]="icon" [disabled]="disabled"> </cap-button>
    }
  </span>
}

<input
  [id]="inputId"
  [accept]="accept"
  #fileInput
  (change)="onChange($event)"
  type="file"
  multiple="multiple"
  class="d-none" />

@if (showFileList && !resetFiles) {
  <ul class="file-list p-3">
    @for (file of files; track file; let i = $index) {
      <li class="row">
        <div class="col">
          <div class="row">
            <div class="col-1">
              <i class="fas fa-check"></i>
            </div>
            <div class="col">
              {{ file.filename }}
            </div>
            <div class="col-1">
              <i (click)="removeFile(i)" class="fas fa-trash-alt remove"> </i>
            </div>
          </div>
        </div>
      </li>
    }
  </ul>
}
