import * as i0 from '@angular/core';
import { EventEmitter, Component, ChangeDetectionStrategy, Input, Output, HostListener, NgModule } from '@angular/core';
import { CSSClasses } from '@capturum/ui/api';
import * as i1 from 'primeng/button';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';

// import { CSSClasses } from '../api/enums/css-classes';
/**
 * A button component
 *
 * Example:
 * ```html
 *  <cap-button label="button"></cap-button>
 * ```
 */
const _c0 = ["*"];
class CapturumButtonComponent {
  constructor() {
    /**
     * The position of the icon. It could either be "left" or "right"
     */
    this.iconPos = 'left';
    /**
     * Type of the button
     */
    this.type = 'button';
    /**
     * Callback to execute when button is clicked.
     */
    this.onClick = new EventEmitter();
  }
  click(event) {
    if (this.disabled) {
      event.preventDefault();
    }
  }
  getStyleClass() {
    let styleClasses = `${this.styleClass} ${CSSClasses.EmButton}`;
    if (this.icon) {
      styleClasses += ` p-button-label-icon-${this.iconPos}`;
      if (!this.label) {
        styleClasses += ' p-button-icon-only';
      }
    }
    return styleClasses;
  }
  static {
    this.ɵfac = function CapturumButtonComponent_Factory(t) {
      return new (t || CapturumButtonComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CapturumButtonComponent,
      selectors: [["cap-button"]],
      hostBindings: function CapturumButtonComponent_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click", function CapturumButtonComponent_click_HostBindingHandler($event) {
            return ctx.click($event);
          });
        }
      },
      inputs: {
        identifier: "identifier",
        label: "label",
        icon: "icon",
        iconPos: "iconPos",
        styleClass: "styleClass",
        type: "type",
        disabled: "disabled"
      },
      outputs: {
        onClick: "onClick"
      },
      ngContentSelectors: _c0,
      decls: 2,
      vars: 6,
      consts: [[3, "onClick", "styleClass", "label", "iconPos", "type", "icon", "disabled"]],
      template: function CapturumButtonComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "p-button", 0);
          i0.ɵɵlistener("onClick", function CapturumButtonComponent_Template_p_button_onClick_0_listener() {
            return ctx.onClick.emit();
          });
          i0.ɵɵprojection(1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("styleClass", ctx.getStyleClass())("label", ctx.label)("iconPos", ctx.iconPos)("type", ctx.type)("icon", ctx.icon)("disabled", ctx.disabled);
        }
      },
      dependencies: [i1.Button],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CapturumButtonComponent, [{
    type: Component,
    args: [{
      selector: 'cap-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<p-button [styleClass]=\"getStyleClass()\"\n          [label]=\"label\"\n          [iconPos]=\"iconPos\"\n          [type]=\"type\"\n          [icon]=\"icon\"\n          (onClick)=\"onClick.emit()\"\n          [disabled]=\"disabled\">\n  <ng-content></ng-content>\n</p-button>\n"
    }]
  }], null, {
    identifier: [{
      type: Input
    }],
    label: [{
      type: Input
    }],
    icon: [{
      type: Input
    }],
    iconPos: [{
      type: Input
    }],
    styleClass: [{
      type: Input
    }],
    type: [{
      type: Input
    }],
    disabled: [{
      type: Input
    }],
    onClick: [{
      type: Output
    }],
    click: [{
      type: HostListener,
      args: ['click', ['$event']]
    }]
  });
})();
class CapturumOfflineIndicatorComponent {
  constructor() {
    this.title = 'Offline mode';
  }
  static {
    this.ɵfac = function CapturumOfflineIndicatorComponent_Factory(t) {
      return new (t || CapturumOfflineIndicatorComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CapturumOfflineIndicatorComponent,
      selectors: [["cap-offline-indicator"]],
      inputs: {
        title: "title"
      },
      decls: 4,
      vars: 1,
      consts: [[1, "offline-indicator"], [1, "fas", "fa-globe"]],
      template: function CapturumOfflineIndicatorComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0)(1, "p");
          i0.ɵɵelement(2, "i", 1);
          i0.ɵɵtext(3);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵadvance(3);
          i0.ɵɵtextInterpolate1(" ", ctx.title, " ");
        }
      },
      styles: [".offline-indicator[_ngcontent-%COMP%]{background-color:#eee;border-radius:16px;padding:7px 12px;width:max-content}@supports not (width: max-content){.offline-indicator[_ngcontent-%COMP%]{width:128px}}.offline-indicator[_ngcontent-%COMP%]   .fas[_ngcontent-%COMP%]{font-family:\"Font Awesome 5 Pro\"}.offline-indicator[_ngcontent-%COMP%]   p[_ngcontent-%COMP%]{color:var(--cap-primary-color);font-weight:600;margin:0}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CapturumOfflineIndicatorComponent, [{
    type: Component,
    args: [{
      selector: 'cap-offline-indicator',
      template: "<div class=\"offline-indicator\">\n    <p>\n        <i class=\"fas fa-globe\"></i>\n        {{ title }}\n    </p>\n\n</div>\n",
      styles: [".offline-indicator{background-color:#eee;border-radius:16px;padding:7px 12px;width:max-content}@supports not (width: max-content){.offline-indicator{width:128px}}.offline-indicator .fas{font-family:\"Font Awesome 5 Pro\"}.offline-indicator p{color:var(--cap-primary-color);font-weight:600;margin:0}\n"]
    }]
  }], null, {
    title: [{
      type: Input
    }]
  });
})();
class CapturumButtonModule {
  static {
    this.ɵfac = function CapturumButtonModule_Factory(t) {
      return new (t || CapturumButtonModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CapturumButtonModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, ButtonModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CapturumButtonModule, [{
    type: NgModule,
    args: [{
      declarations: [CapturumButtonComponent, CapturumOfflineIndicatorComponent],
      exports: [CapturumButtonComponent, CapturumOfflineIndicatorComponent],
      imports: [CommonModule, ButtonModule]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { CapturumButtonComponent, CapturumButtonModule, CapturumOfflineIndicatorComponent };
