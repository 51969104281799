import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objectKeys',
  standalone: true,
})
export class ObjectKeysPipe implements PipeTransform {
  public transform(value: Record<string, unknown>): string[] {
    return Object.keys(value);
  }
}
