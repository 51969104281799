import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'wordMergeTypeNameRow',
  standalone: true,
})
export class WordMergeTypeNameRowPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  public transform(object: any, company: boolean): string {
    if (!object) {
      return '';
    }

    let wordMergeName = this.getWordMergeName(object, company);

    if (object.partner?.last_name) {
      let partnerName = this.getWordMergeName(object.partner, company);

      partnerName = partnerName.replace(this.translateService.instant('address.tta.label'), '').trim();

      wordMergeName += ' en ';
      wordMergeName += partnerName.charAt(0).toLowerCase() + partnerName.slice(1);
    }

    return wordMergeName;
  }

  protected getWordMergeName(object: any, company: boolean): string {
    let fullLine = '';

    let namePrefixBefore: string;
    let namePrefixAfter: string;

    if (object.name_prefix_base_data_value_id) {
      // Exception: "Aan de erven van" needs to be added in front of gender
      const namePrefix = this.translateService.instant(`base-data.${object.name_prefix_base_data_value_id}`);

      namePrefixBefore = namePrefix === 'Aan de erven van' ? namePrefix : null;
      namePrefixAfter = namePrefix !== 'Aan de erven van' ? namePrefix : null;
    }

    if (company && !namePrefixBefore) {
      fullLine = this.translateService.instant('address.tta.label');
    }

    if (namePrefixBefore) {
      fullLine += namePrefixBefore;
    }

    if (object.gender_base_data_value_id) {
      const gender = this.translateService.instant(`base-data.${object.gender_base_data_value_id}`);
      let genderString;

      if (gender) {
        namePrefixAfter = namePrefixAfter?.toLowerCase() ?? null;

        switch (gender.toLowerCase()) {
          case 'man':
            genderString = ` ${this.translateService.instant(`client-registration.salutation.mr.full`)}`;
            break;
          case 'vrouw':
            genderString = ` ${this.translateService.instant(`client-registration.salutation.mrs.full`)}`;
            break;
          default:
            genderString = '';
            break;
        }

        // Exception: "hoogwelgeboren heer" doesn't need a gender
        if (namePrefixAfter === 'hoogwelgeboren heer') {
          genderString = ' De';
        }

        if (company || namePrefixBefore) {
          genderString = genderString.toLowerCase();
        }

        fullLine += genderString;
      }
    } else {
      if (company) {
        namePrefixAfter = namePrefixAfter?.toLowerCase() ?? null;
      }
    }

    if (namePrefixAfter) {
      fullLine += ` ${namePrefixAfter}`;
    }

    if (object.initials) {
      fullLine += ` ${object.initials}`;
    }

    if (object.last_name_prefix) {
      fullLine += ` ${object.last_name_prefix}`;
    }

    if (object.last_name) {
      fullLine += ` ${object.last_name}`;
    }

    if (object.name_suffix_base_data_value_id) {
      // Exception: if suffix starts with "," no space is needed
      let nameSuffix = this.translateService.instant(`base-data.${object.name_suffix_base_data_value_id}`);

      nameSuffix = nameSuffix[0] === ',' ? nameSuffix : ` ${nameSuffix}`;

      fullLine += nameSuffix;
    }

    return fullLine.trim();
  }
}
