import { generateSentryTraceHeader, dropUndefinedKeys } from '@sentry/utils';

/**
 * Convert a span to a trace context, which can be sent as the `trace` context in an event.
 */
function spanToTraceContext(span) {
  const { data, description, op, parent_span_id, span_id, status, tags, trace_id, origin } = span.toJSON();

  return dropUndefinedKeys({
    data,
    description,
    op,
    parent_span_id,
    span_id,
    status,
    tags,
    trace_id,
    origin,
  });
}

/**
 * Convert a Span to a Sentry trace header.
 */
function spanToTraceHeader(span) {
  return generateSentryTraceHeader(span.traceId, span.spanId, span.sampled);
}

export { spanToTraceContext, spanToTraceHeader };

