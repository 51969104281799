import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-accordion-header',
  templateUrl: 'accordion-header.component.html',
  styleUrl: 'accordion-header.component.scss',
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [TranslateModule],
})
export class AccordionHeaderComponent {
  @Input() public deletable = false;
  @Output() public delete: EventEmitter<void> = new EventEmitter<void>();
}
