import { Directive, ElementRef, Input } from '@angular/core';
import { Address } from '@modules/address/models/address.model';
import { MapItem } from '@capturum/ui/api';
import { AddressFieldConfig } from '@core/configs/address-field-config';
import { ViewContainerRef } from '@angular/core';
import { TemplateRef } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[showAddressField]',
  standalone: true,
})
export class ShowAddressFieldDirective {
  @Input()
  public set showAddressField(value: { address: Address; field: string; addressTypes: MapItem[] }) {
    if (!value.addressTypes) {
      this.ViewContainerRef.clear();

      return;
    }

    const type = value.addressTypes.find((addressType) => {
      return addressType.value === value.address.type_base_data_value_id;
    });
    const fieldConfig = AddressFieldConfig.find((item) => {
      return item.type === type.key;
    });

    if (
      type &&
      !!fieldConfig.controls.find((item) => {
        return item.name === value.field;
      })
    ) {
      this.ViewContainerRef.createEmbeddedView(this.templateRef);
    } else {
      this.ViewContainerRef.clear();
    }
  }

  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private ViewContainerRef: ViewContainerRef,
  ) {}
}
