import { Pipe, PipeTransform } from '@angular/core';

/**
 * Transform the given string to kebabCase
 */
@Pipe({
  name: 'kebabCase',
  standalone: true,
})
export class KebabCasePipe implements PipeTransform {
  public transform(value: string): string {
    return value
      .replace(/([a-z])([A-Z])/g, '$1-$2') // Get all lowercase letters that are near to uppercase ones
      .replace(/[\s_]+/g, '-') // Replace all spaces and low dash
      .toLowerCase();
  }
}
