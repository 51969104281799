<div class="wrapper wrapper-content">
  @for (config of configs; track config) {
    <div class="row">
      <ng-container *ngxPermissionsOnly="config.permissions">
        <div class="col-12">
          <div class="ibox">
            <div class="ibox-title">
              <h5>{{ config.title }}</h5>
            </div>
            <div class="ibox-content">
              <div class="row">
                @for (box of config.boxes; track box) {
                  <div class="col-12 col-sm-6 col-lg-3 col-xl-2 mb-4 mb-xl-0">
                    <ng-container *ngxPermissionsOnly="box.permissions">
                      <div class="config-card" (click)="box.redirect()">
                        <i [class]="box.icon"></i>
                        <div class="title">{{ box.title }}</div>
                      </div>
                    </ng-container>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  }
</div>
