import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appLimitInput]',
  standalone: true,
})
export class LimitInputDirective {
  @Input('appLimitInput')
  public limit: number;

  public excludedKeys = ['Delete', 'Backspace', 'Arrow'];

  @HostListener('keydown', ['$event'])
  public onKeyDown(event: KeyboardEvent | any): void {
    const currentValue: string = event.target.value;

    if (currentValue.length >= this.limit && !this.excludedKeys.includes(event.key) && event.key.length === 1) {
      event.preventDefault();
      event.target.value = currentValue.substring(0, this.limit).toUpperCase();

      return;
    }

    event.target.value = currentValue.trim().toUpperCase();
  }
}
