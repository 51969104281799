import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { BaseDataKeyIndexedDbModel, BaseDataKeyService } from '@capturum/complete';
import { AdminRoutes } from '@core/enums/routes';
import { NgxPermissionsModule } from 'ngx-permissions';

export interface ConfigLayout {
  title: string;
  permissions?: string[];
  boxes: {
    title: string;
    icon: string;
    redirect: () => void;
    permissions?: string[];
  }[];
}

@Component({
  selector: 'app-config-overview',
  templateUrl: './config-overview.component.html',
  styleUrls: ['./config-overview.component.scss'],
  standalone: true,
  imports: [NgxPermissionsModule],
})
export class ConfigOverviewComponent implements OnInit {
  public configs: ConfigLayout[];
  public baseDataKeys: BaseDataKeyIndexedDbModel[];

  constructor(
    private readonly translateService: TranslateService,
    private readonly router: Router,
    private readonly baseDataKeyService: BaseDataKeyService,
  ) {}

  public ngOnInit(): void {
    this.baseDataKeyService.index({ perPage: 999 }).subscribe((result) => {
      this.baseDataKeys = result.data;

      this.setConfigs();
    });
  }

  private setConfigs(): void {
    this.configs = [
      {
        title: this.translateService.instant('config.overview.title'),
        permissions: ['settings.manage'],
        boxes: [
          {
            title: this.translateService.instant('config.setting-overview.label'),
            icon: 'fas fa-sliders-h',
            redirect: () => {
              return this.router.navigate([`/${AdminRoutes.admin}/${AdminRoutes.settings}`]);
            },
            permissions: ['settings.manage'],
          },
        ],
      },
      {
        title: this.translateService.instant('config.manage.title'),
        permissions: ['user.manage', 'company.manage', 'translation.manage', 'role.manage'],
        boxes: [
          {
            title: this.translateService.instant('config.manage.users'),
            icon: 'fas fa-user',
            permissions: ['user.manage'],
            redirect: () => {
              return this.router.navigate([`/${AdminRoutes.admin}/${AdminRoutes.users}`]);
            },
          },
          {
            title: this.translateService.instant('config.manage.companies'),
            icon: 'fas fa-building',
            permissions: ['company.manage'],
            redirect: () => {
              return this.router.navigate([`/${AdminRoutes.admin}/${AdminRoutes.tenants}`]);
            },
          },
          {
            title: this.translateService.instant('config.manage.translations'),
            icon: 'fas fa-language',
            permissions: ['translation.manage'],
            redirect: () => {
              return this.router.navigate([`/${AdminRoutes.admin}/${AdminRoutes.translations}`]);
            },
          },
          {
            title: this.translateService.instant('role.overview.title'),
            icon: 'fas fa-users',
            permissions: ['role.manage'],
            redirect: () => {
              return this.router.navigate([`/${AdminRoutes.admin}/${AdminRoutes.roles}`]);
            },
          },
        ],
      },
      {
        title: this.translateService.instant('config.base_data.title'),
        permissions: ['base.data.manage'],
        boxes: this.baseDataKeys
          .map((baseDataKey) => {
            return {
              title: this.translateService.instant(
                `reyersen_van_buuren.base-data.${baseDataKey.key.replace(/_/g, '-')}`,
              ),
              icon: 'fas fa-database',
              redirect: () => {
                return this.router.navigate([`/${AdminRoutes.admin}/${AdminRoutes.baseData}/${baseDataKey.id}/values`]);
              },
            };
          })
          .sort((a, b) => {
            return a.title.localeCompare(b.title);
          }),
      },
    ];
  }
}
