import { BUILD_TIMESTAMP } from '@environments/timestamp';
const packageJson = require('@root/package.json');

export const environment = {
  appName: 'Reyersen van Buuren - CRM',
  production: false,
  hmr: false,
  name: 'test',
  baseUrl: 'https://reyersen-test.staging.emendis.nl/api',
  sentryUrl: 'https://49be2a9394054b2eb473c90cfd0abd71@o4504360297496576.ingest.sentry.io/4504377843712005',
  databaseName: 'reyersen-van-buuren.complete',
  version: packageJson.version + '.' + BUILD_TIMESTAMP,
  redirectAfterLogin: '/',
  attachmentSizeInMb: 5,
};
