import { Injectable } from '@angular/core';
import { TranslateService } from '@node_modules/@ngx-translate/core';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { Address } from '@modules/address/models/address.model';
import { AddressType } from '@core/enums/address-type.enum';
import { LocaleSettings } from 'primeng/calendar';
import { MapItem } from '@capturum/ui/api';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  public static percentagePattern = /^[0-9]*,?[0-9]{1,2}$/;
  public static commaRemovalPattern = /,/g;
  public static commaAdditionPattern = /\B(?=(\d{3})+(?!\d))/g;
  public static dotRemovalPattern = /\./g;
  private _calendarLocale$ = new BehaviorSubject<LocaleSettings>(null);
  private _existingCommunicationQuery = new BehaviorSubject<any>(null);

  constructor(private translateService: TranslateService) {
    this.setCalendarLocale();

    this.translateService.onLangChange.subscribe(() => {
      this.setCalendarLocale();
    });
  }

  public get existingCommunicationQuery$(): Observable<any> {
    return this._existingCommunicationQuery.asObservable();
  }

  public setExistingCommunicationQuery(query: any): void {
    this._existingCommunicationQuery.next(query);
  }

  public get calendarLocale$(): Observable<LocaleSettings> {
    return this._calendarLocale$.asObservable();
  }

  public getFullAddress(address: Address): string | null {
    if (address) {
      if (!address.street && !address.city && !address.housenumber && !address.zipcode && address.country) {
        return null;
      }

      return (
        `${address.street ? address.street + ' ' : ''}${address.housenumber ? address.housenumber : ''}${
          address.addition || ''
        }` +
        `${address.zipcode ? ', ' + address.zipcode + ' ' : ''}${address.city || ''}${
          address.country ? ', ' + address.country : ''
        }`
      );
    }

    return null;
  }

  public getAddressValue(address: Address, addressType: MapItem): string | null {
    if (address && addressType) {
      switch (addressType.key) {
        case AddressType.PostalAddress:
          return this.getFullAddress(address);
        case AddressType.EmailAddress:
          return address.email;
        case AddressType.MobilePhone:
        case AddressType.LandlinePhone:
          return address.phone;
        default:
          return null;
      }
    }
  }

  private setCalendarLocale(): void {
    this.translateService
      .stream('calendar-locale.week-header')
      .pipe(take(2))
      .subscribe(() => {
        const locale: LocaleSettings = {
          firstDayOfWeek: this.translateService.instant('calendar-locale.first-day-of-week') as number,
          dayNames: [
            this.translateService.instant('calendar-locale.day-names.sunday'),
            this.translateService.instant('calendar-locale.day-names.monday'),
            this.translateService.instant('calendar-locale.day-names.tuesday'),
            this.translateService.instant('calendar-locale.day-names.wednesday'),
            this.translateService.instant('calendar-locale.day-names.thursday'),
            this.translateService.instant('calendar-locale.day-names.friday'),
            this.translateService.instant('calendar-locale.day-names.saturday'),
          ],
          dayNamesShort: [
            this.translateService.instant('calendar-locale.day-names.sun'),
            this.translateService.instant('calendar-locale.day-names.mon'),
            this.translateService.instant('calendar-locale.day-names.tue'),
            this.translateService.instant('calendar-locale.day-names.wed'),
            this.translateService.instant('calendar-locale.day-names.thu'),
            this.translateService.instant('calendar-locale.day-names.fri'),
            this.translateService.instant('calendar-locale.day-names.sat'),
          ],
          dayNamesMin: [
            this.translateService.instant('calendar-locale.day-names.su'),
            this.translateService.instant('calendar-locale.day-names.mo'),
            this.translateService.instant('calendar-locale.day-names.tu'),
            this.translateService.instant('calendar-locale.day-names.we'),
            this.translateService.instant('calendar-locale.day-names.th'),
            this.translateService.instant('calendar-locale.day-names.fr'),
            this.translateService.instant('calendar-locale.day-names.sa'),
          ],
          monthNames: [
            this.translateService.instant('calendar-locale.month-names.january'),
            this.translateService.instant('calendar-locale.month-names.february'),
            this.translateService.instant('calendar-locale.month-names.march'),
            this.translateService.instant('calendar-locale.month-names.april'),
            this.translateService.instant('calendar-locale.month-names.may'),
            this.translateService.instant('calendar-locale.month-names.june'),
            this.translateService.instant('calendar-locale.month-names.july'),
            this.translateService.instant('calendar-locale.month-names.august'),
            this.translateService.instant('calendar-locale.month-names.september'),
            this.translateService.instant('calendar-locale.month-names.october'),
            this.translateService.instant('calendar-locale.month-names.november'),
            this.translateService.instant('calendar-locale.month-names.december'),
          ],
          monthNamesShort: [
            this.translateService.instant('calendar-locale.month-names.jan'),
            this.translateService.instant('calendar-locale.month-names.feb'),
            this.translateService.instant('calendar-locale.month-names.mar'),
            this.translateService.instant('calendar-locale.month-names.apr'),
            this.translateService.instant('calendar-locale.month-names.jun'),
            this.translateService.instant('calendar-locale.month-names.jul'),
            this.translateService.instant('calendar-locale.month-names.aug'),
            this.translateService.instant('calendar-locale.month-names.sep'),
            this.translateService.instant('calendar-locale.month-names.oct'),
            this.translateService.instant('calendar-locale.month-names.nov'),
            this.translateService.instant('calendar-locale.month-names.dec'),
          ],
          today: this.translateService.instant('calendar-locale.today'),
          clear: this.translateService.instant('calendar-locale.clear'),
          dateFormat: this.translateService.instant('calendar-locale.date-format'),
          weekHeader: this.translateService.instant('calendar-locale.week-header'),
        };

        this._calendarLocale$.next(locale);
      });
  }
}
