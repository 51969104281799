{
  "name": "@emendis/complete-frontend-project-reyersen-van-buuren",
  "version": "1.0.0",
  "license": "MIT",
  "scripts": {
    "ng": "ng",
    "start": "tail -f /dev/null",
    "dockerserve": "ng serve --host 0.0.0.0 --disable-host-check",
    "build": "ng build",
    "test": "ng test --code-coverage",
    "test:cc": "ng test --code-coverage --no-watch --no-progress --browsers=ChromeHeadlessNoSandbox --karma-config=karma-pipeline.conf.js",
    "test:ci": "ng test --no-watch --no-progress --browsers=ChromeHeadlessNoSandbox --karma-config=karma-pipeline.conf.js",
    "test:pipeline": "ng test --watch=false --source-map=false --browsers=ChromeHeadlessNoSandbox --karmaConfig=karma-pipeline.conf.js",
    "lint": "ng lint",
    "lint:fix": "ng lint --fix",
    "lint:quiet": "ng lint --quiet",
    "prettier": "npx prettier src/app --check",
    "prettier:fix": "npm run prettier -- --write",
    "format": "npm run prettier:fix && ng lint --fix",
    "e2e": "cypress open"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.3.11",
    "@angular/cdk": "^17.3.10",
    "@angular/common": "^17.3.11",
    "@angular/compiler": "^17.3.11",
    "@angular/core": "^17.3.11",
    "@angular/forms": "^17.3.11",
    "@angular/platform-browser": "^17.3.11",
    "@angular/platform-browser-dynamic": "^17.3.11",
    "@angular/platform-server": "^17.3.11",
    "@angular/router": "^17.3.11",
    "@angular/service-worker": "^17.3.11",
    "@capturum/api": "^17.0.0",
    "@capturum/auth": "^17.0.0",
    "@capturum/builders": "^17.0.2",
    "@capturum/complete": "^17.0.1",
    "@capturum/formly": "^17.0.3",
    "@capturum/login": "^17.0.1",
    "@capturum/shared": "^17.0.1",
    "@capturum/ui": "^17.0.3",
    "@fortawesome/fontawesome-pro": "^6.5.2",
    "@ngx-formly/bootstrap": "^6.3.5",
    "@ngx-formly/core": "^6.3.5",
    "@ngx-formly/primeng": "^6.3.5",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "@sentry/angular-ivy": "7.92",
    "bootstrap": "^5.3.3",
    "caniuse-lite": "^1.0.30001439",
    "core-js": "^3.26.1",
    "dexie": "^4.0.7",
    "file-saver": "^2.0.5",
    "jwt-decode": "^4.0.0",
    "ngx-bootstrap": "^4.1.1",
    "ngx-permissions": "^17.1.0",
    "primeicons": "^7.0.0",
    "primeng": "^17.18.1",
    "quill-image-drop-module": "^1.0.3",
    "rxjs": "^7.5.7",
    "subsink": "^1.0.1",
    "tslib": "^2.4.1",
    "uuid": "^9.0.0",
    "zone.js": "^0.14.4"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.3.8",
    "@angular-eslint/builder": "17.5.2",
    "@angular-eslint/eslint-plugin": "17.5.2",
    "@angular-eslint/eslint-plugin-template": "17.5.2",
    "@angular-eslint/schematics": "17.5.2",
    "@angular-eslint/template-parser": "17.5.2",
    "@angular/cli": "^17.3.8",
    "@angular/compiler-cli": "^17.3.11",
    "@angular/language-service": "^17.3.11",
    "@angularclass/hmr": "^3.0.0",
    "@capturum/linting": "^17.0.1",
    "@commitlint/cli": "^12.1.4",
    "@types/faker": "^4.1.9",
    "@types/jasmine": "^5.1.4",
    "@types/node": "^20.14.8",
    "@typescript-eslint/eslint-plugin": "7.11.0",
    "@typescript-eslint/parser": "7.11.0",
    "cypress": "^9.7.0",
    "eslint": "^8.57.0",
    "eslint-plugin-import": "^2.29.1",
    "eslint-plugin-prettier": "^5.1.3",
    "eslint-plugin-unused-imports": "^3.2.0",
    "faker": "^6.6.6",
    "husky": "^2.4.1",
    "jasmine-core": "^5.1.2",
    "jasmine-spec-reporter": "~7.0.0",
    "karma": "^6.4.1",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "^2.2.1",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "^2.1.0",
    "karma-spec-reporter": "0.0.36",
    "postcss": "^8.4.20",
    "pre-commit": "^1.2.2",
    "prettier": "^3.3.2",
    "protractor": "^7.0.0",
    "stylelint": "^16.6.1",
    "ts-node": "~3.0.4",
    "typescript": "~5.4.5"
  },
  "husky": {
    "hooks": {}
  }
}
