import { Pipe, PipeTransform } from '@angular/core';
import { MapItem } from '@capturum/ui/api';

@Pipe({
  name: 'addressType',
  standalone: true,
})
export class AddressTypePipe implements PipeTransform {
  public transform(value: string, addressTypes: MapItem[]): string {
    if (value && addressTypes) {
      return addressTypes.find((address) => {
        return address.value === value;
      }).label;
    } else {
      return '';
    }
  }
}
