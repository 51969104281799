import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'fullName',
  standalone: true,
})
export class FullNamePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  public transform(object: any): string {
    let fullName = '';

    if (!object) {
      return fullName;
    }

    if (object.full_name && object.full_name !== '') {
      return object.full_name;
    }

    if (object.name_prefix_base_data_value_id) {
      fullName += `${this.translateService.instant(`base-data.${object.name_prefix_base_data_value_id}`)}`;
    }

    if (object.initials) {
      fullName += ` ${object.initials}`;
    }

    if (object.last_name_prefix) {
      fullName += ` ${object.last_name_prefix}`;
    }

    if (object.last_name) {
      fullName += ` ${object.last_name}`;
    }

    if (object.name_suffix_base_data_value_id) {
      fullName += ` ${this.translateService.instant(`base-data.${object.name_suffix_base_data_value_id}`)}`;
    }

    if (object.gender_base_data_value_id) {
      const gender = ` ${this.translateService.instant(`base-data.${object.gender_base_data_value_id}`)}`;
      const genderFirstLetter = gender.charAt(1);

      if (genderFirstLetter) {
        fullName += ` (${genderFirstLetter.toLowerCase()})`;
      }
    }

    if (object.first_name) {
      fullName += ` (${object.first_name})`;
    }

    return fullName;
  }
}
