import * as i0 from '@angular/core';
import { Injectable, Component, ChangeDetectorRef, ViewChild, NgModule, Directive } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import * as i1 from 'primeng/api';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import * as i1$1 from '@ngx-translate/core';
import { LocalStorageService, TableColumn } from '@capturum/ui/api';
import { isMatch, isDate, format } from 'date-fns';
const _c0 = ["tt"];
class SharedService {
  constructor() {}
  /**
   * Store the current router url in local storage
   *
   * @param router
   *
   * @return void
   */
  storeCurrentRoute(router) {
    localStorage.setItem('lastRoute', router.url);
  }
  /**
   * Store the previous router url in local storage
   *
   * @param router
   *
   * @return void
   */
  storePreviousRoute(router) {
    if (router.url !== localStorage.getItem('lastRoute')) {
      localStorage.setItem('previousRoute', localStorage.getItem('lastRoute'));
    }
  }
  /**
   * Map the enumeration values to a dropdown list array
   *
   * @param enumeration
   *
   * @return SelectItem[]
   */
  mapEnumToSelectItems(enumeration) {
    const values = [];
    for (const key in enumeration) {
      values.push({
        label: enumeration[key],
        value: key
      });
    }
    return values;
  }
  /**
   * Transform given object to an array of SelectItems
   *
   * @param object
   * @param placeholder
   * @param sort
   *
   * @return any
   */
  mapObjectToSelectItems(object, placeholder = null, sort = false) {
    let dropdownOptions = [];
    if (placeholder) {
      dropdownOptions.push({
        label: placeholder,
        value: ''
      });
    }
    for (let key in object) {
      dropdownOptions.push({
        label: object[key],
        value: key
      });
    }
    if (sort) {
      dropdownOptions = dropdownOptions.sort((a, b) => {
        return a.label < b.label ? -1 : 1;
      });
    }
    return dropdownOptions;
  }
  /**
   * Get a preview url for uploaded images
   *
   * @param file
   *
   * @return Observable<SafeUrl>
   */
  readUrl(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Observable(observer => {
      reader.onerror = error => {
        observer.error(error);
      };
      reader.onload = onLoadEvent => {
        observer.next(this.sanitizer.bypassSecurityTrustUrl(onLoadEvent.target.result));
      };
      reader.onloadend = () => {
        observer.complete();
      };
    });
  }
  static {
    this.ɵfac = function SharedService_Factory(t) {
      return new (t || SharedService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: SharedService,
      factory: SharedService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SharedService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [], null);
})();

/**
 * @deprecated in favor of @capturum/ui/api toastService
 */
class ToastService {
  constructor(messageService) {
    this.messageService = messageService;
  }
  /**
   * Show error message
   *
   * @param title
   * @param detail
   *
   * @return void
   */
  error(title, detail) {
    this.message({
      severity: 'error',
      summary: title,
      detail
    });
  }
  /**
   * Show warning
   *
   * @param title
   * @param detail
   *
   * @return void
   */
  warning(title, detail) {
    this.message({
      severity: 'warn',
      summary: title,
      detail
    });
  }
  /**
   * Show success message
   *
   * @param title
   * @param detail
   *
   * @return void
   */
  success(title, detail) {
    this.message({
      severity: 'success',
      summary: title,
      detail
    });
  }
  /**
   * Add message to message service
   *
   * @param message
   *
   * @return void
   */
  message(message) {
    this.messageService.add(message);
  }
  /**
   * Display error message based on json error response
   *
   * @param errorResponse
   * @param title
   * @param detail
   *
   * @return void
   */
  handleError(errorResponse, title, detail = 'Item') {
    let errorMessages = '';
    let status = errorResponse.status;
    if (!errorResponse.status) {
      if (errorResponse.status_code) {
        status = errorResponse.status_code;
      } else if (errorResponse.type === 3) {
        status = 404;
      }
    }
    // Unauthorized status is handled via authError in the api service
    if (status === 401) {
      return;
    }
    switch (status) {
      case 404:
        errorMessages = detail + ' is niet gevonden.';
        break;
      case 500:
        errorMessages = 'Interne fout, neem contact op met de beheerder.';
        break;
      case 422:
        if (errorResponse.errors) {
          for (const error in errorResponse.errors) {
            if (error) {
              errorMessages += `${errorResponse.errors[error]}<br />`;
            }
          }
        } else if (errorResponse.message) {
          errorMessages += errorResponse.message;
        } else {
          errorMessages = 'Niet alle velden zijn juist ingevuld.';
        }
        break;
      default:
        if (errorResponse.errors) {
          for (const error in errorResponse.errors) {
            if (error) {
              errorMessages += `${errorResponse.errors[error]}<br />`;
            }
          }
        }
        if (errorResponse.message) {
          errorMessages += errorResponse.message;
        }
        break;
    }
    if (errorMessages !== '') {
      this.error(title, errorMessages);
    }
  }
  static {
    this.ɵfac = function ToastService_Factory(t) {
      return new (t || ToastService)(i0.ɵɵinject(i1.MessageService));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ToastService,
      factory: ToastService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ToastService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.MessageService
  }], null);
})();

/**
 * This is a base detail component.
 * The purpose of this component is to create a base class for generic detail pages.
 */
class BaseDetailComponent {
  constructor(injector, translateService) {
    this.injector = injector;
    this.translateService = translateService;
    /**
     * The method of the api service used to retrieve the model
     */
    this.getMethod = 'get';
    /**
     * The id field of the resource
     */
    this.idField = 'id';
    this.baseRouter = injector.get(Router);
    this.toastService = injector.get(ToastService);
    this.destroy$ = new Subject();
    this.includes = [];
    this.fields = [];
    this.model = {};
    this.removeDataWrappers = true;
    this.modelTranslation = this.translateService.instant('detail.model');
    this.errorMessage = this.translateService.instant('toast.error.message');
    this.errorHeaderMessage = this.translateService.instant('toast.error.title');
    this.notFoundMessage = `${this.modelTranslation} ${this.translateService.instant('detail.not_found')}`;
    this.saveMessage = this.translateService.instant('detail.item_saved');
  }
  ngOnInit() {
    this.buildForm();
    this.route.params.pipe(filter(params => !!params && params.id), takeUntil(this.destroy$)).subscribe(params => {
      this.isEdit = true;
      this.model[this.idField] = params.id;
      this.getResource(params.id);
    });
  }
  ngOnDestroy() {
    this.destroy$.next(true);
  }
  /**
   * Submit form to backend
   */
  submit() {
    if (this.formGroup.valid) {
      this.submitting = true;
      const saveFunction = this.isEdit ? this.apiService.update(this.model, {
        include: this.includes
      }) : this.apiService.create(this.model, {
        include: this.includes
      });
      saveFunction.pipe().subscribe(response => {
        this.toastService.success(this.modelTranslation, this.saveMessage);
        if (this.saveResourceCallback) {
          this.saveResourceCallback(response);
        }
        this.onCancel();
      }, error => {
        this.submitting = false;
        this.toastService.error(this.modelTranslation, this.errorMessage);
      });
    }
  }
  /**
   * Redirect to list page
   */
  onCancel() {
    if (this.cancelRedirectRoute) {
      this.baseRouter.navigateByUrl(this.cancelRedirectRoute);
    } else {
      this.baseRouter.navigate(['../'], {
        relativeTo: this.route
      });
    }
  }
  /**
   * Function to run after the resource has been retrieved
   *
   * @param response
   */
  getResourceCallback(response) {
    return response;
  }
  /**
   * Get the resource and add model data to the form
   *
   * @param id
   */
  getResource(id) {
    this.apiService[this.getMethod](id, {
      include: this.includes
    }).subscribe(response => {
      if (this.removeDataWrappers) {
        // Remove wrapping 'data' object for includes
        this.includes.forEach(include => {
          if (response[include] !== undefined && response[include]?.data) {
            const includeData = response[include].data;
            if (Array.isArray(includeData)) {
              response[include] = [...includeData];
            } else {
              response[include] = {
                ...includeData
              };
            }
          }
        });
      }
      this.model = this.getResourceCallback(response);
      this.formGroup.patchValue(this.model);
    }, error => {
      this.toastService.error(this.errorHeaderMessage, this.errorMessage);
      this.onCancel();
    });
  }
  /**
   * Build initial form
   */
  buildForm() {
    this.formGroup = new FormGroup({});
  }
  static {
    this.ɵfac = function BaseDetailComponent_Factory(t) {
      return new (t || BaseDetailComponent)(i0.ɵɵdirectiveInject(i0.Injector), i0.ɵɵdirectiveInject(i1$1.TranslateService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: BaseDetailComponent,
      selectors: [["ng-component"]],
      decls: 0,
      vars: 0,
      template: function BaseDetailComponent_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BaseDetailComponent, [{
    type: Component,
    args: [{
      template: ''
    }]
  }], () => [{
    type: i0.Injector
  }, {
    type: i1$1.TranslateService
  }], null);
})();
class BaseListComponent {
  /**
   * BaseListComponent constructor
   *
   * @return void
   */
  constructor(injector, translateService) {
    this.injector = injector;
    this.translateService = translateService;
    /**
     * Sorting properties for the table
     */
    this.sort = [];
    /**
     * Search query for the table
     */
    this.search = null;
    /**
     * Property indicating if the table is loading
     */
    this.loading = true;
    /**
     * The Count list option to be added to the index request
     */
    this.count = [];
    this.baseRouter = injector.get(Router);
    this.confirmationService = injector.get(ConfirmationService);
    this.toastService = injector.get(ToastService);
    this.localStorageService = injector.get(LocalStorageService);
    this.cdr = this.injector.get(ChangeDetectorRef);
    this.indexMethod = 'index';
    this.tableVisible = true;
    this.routePath = null;
    this.includes = [];
    this.paginator = {
      rows: null,
      total: null,
      current_page: 1,
      total_pages: 1,
      per_page: null,
      first: 0
    };
    this.filters = {};
    this.itemNames = {
      plural: 'Items',
      singular: 'Item'
    };
  }
  /**
   * Set the list columns
   *
   * @param columns
   *
   * @return void
   */
  setColumns(columns) {
    this.columns = TableColumn.getColumns(columns);
  }
  /**
   * Load the table data if received event is set
   *
   * @param event
   *
   * @return void
   */
  loadTableData(event) {
    if (event) {
      this.lazyLoadEvent = event;
      this.loadTableDataFromCurrentLazyLoadEvent();
    }
  }
  /**
   * Get the items
   *
   * @param event
   * @param items Optional object of item names
   *
   * @return Observable<any>
   */
  readItems(event, items = this.itemNames) {
    return new Observable(observer => {
      this.loading = true;
      const options = this.getListOptions();
      const indexMethod = this.indexMethodArguments ? this.apiService[this.indexMethod](options, ...this.indexMethodArguments) : this.apiService[this.indexMethod](options);
      this.requestOptions = options;
      indexMethod.subscribe(response => {
        if (response?.meta?.pagination) {
          this.paginator = {
            ...response.meta.pagination,
            rows: response.meta.pagination.per_page
          };
          if (Number.isInteger(event?.first)) {
            this.paginator.first = event.first;
          }
        }
        this.loading = false;
        observer.next(response?.data);
      }, errorResponse => {
        this.loading = false;
        observer.error(errorResponse);
      });
    });
  }
  /**
   * Open item detail screen
   *
   * @param id
   * @param routePath Optionally override default base route path
   *
   * @return Promise<boolean>
   */
  editItem(id, routePath = this.routePath) {
    if (routePath === null) {
      return;
    }
    if (routePath === this.routePath) {
      return this.baseRouter.navigate([`/${routePath}/${id}`]);
    }
    return this.baseRouter.navigate([`${routePath}`]);
  }
  /**
   * Delete an item
   *
   * @param id
   * @param item Optional
   *
   * @return void
   */
  deleteItem(id, item = this.itemNames.singular) {
    if (this.apiService === null) {
      return;
    }
    this.loading = true;
    this.apiService.delete(id).subscribe({
      next: () => {
        this.tableVisible = false;
        this.loadTableData(this.lazyLoadEvent);
        this.toastService.success(`${item}`, this.translateService.instant('list.item_deleted'));
      },
      error: error => {
        this.handleError(error);
        this.loading = false;
      }
    });
  }
  /**
   * Delete an item with confirmation dialog
   *
   * @param id
   * @param item
   * @param message
   *
   * @returns void
   */
  deleteItemWithConfirm(id, item = this.itemNames.singular, message) {
    if (message === undefined) {
      message = this.translateService.instant('list.delete_confirmation');
    }
    this.confirmationService.confirm({
      header: item,
      message,
      accept: () => {
        this.deleteItem(id, item);
      },
      reject: () => {
        this.loading = false;
      }
    });
  }
  setFilter(value, field, matchMode) {
    if (!Array.isArray(value) && value || Array.isArray(value) && value.length) {
      this.filters[field] = {
        value,
        matchMode
      };
    } else {
      delete this.filters[field];
    }
  }
  filterTable(event) {
    this.filters = {};
    for (const field in event) {
      if (event.hasOwnProperty(field)) {
        this.setFilter(event[field].value, field, event[field].matchMode);
      }
    }
    this.loadTableData({
      ...event,
      ...this.paginator
    });
  }
  onSearchEvent(searchStr, reloadFlag) {
    this.search = [searchStr];
    if (reloadFlag) {
      this.loadTableData({
        globalFilter: searchStr
      });
    }
  }
  handleError(response) {
    this.toastService.error(this.translateService.instant('toast.error.title'), this.translateService.instant('toast.error.message'));
  }
  /**
   * Format given filters
   *
   * @param filters
   */
  formatFilters(filters) {
    const apiFilters = [];
    for (const field in filters) {
      if (filters[field] === null) {
        return;
      }
      if (Array.isArray(filters[field])) {
        for (const f in filters[field]) {
          apiFilters.push(this.parseFilter(field, filters[field][f]));
        }
      } else {
        apiFilters.push(this.parseFilter(field, filters[field]));
      }
    }
    return apiFilters;
  }
  parseFilter(field, filter) {
    let value = filter.value;
    const operator = filter.matchMode;
    // Add percent signs to value for like operators
    if (['like', 'notlike'].indexOf(operator) !== -1) {
      value = `%${value}%`;
    }
    // Format date values
    if (Array.isArray(value)) {
      value = value.map(val => {
        if (isMatch(String(val), 'dd-MM-yyyy') || isDate(val)) {
          return format(new Date(val), 'yyyy-MM-dd');
        }
        return val;
      });
    }
    return {
      field: field,
      value: value,
      operator: operator
    };
  }
  /**
   * Load the table data from existing this.lazyLoadEvent object
   * @return void
   */
  loadTableDataFromCurrentLazyLoadEvent() {
    this.readItems(this.lazyLoadEvent).subscribe(data => {
      // Delete variable before recreating (IE11 fix)
      delete this.tableData;
      this.tableVisible = true;
      if (this.loadTableDataCallback !== undefined) {
        this.loadTableDataCallback(data);
      } else {
        this.tableData = data;
      }
      this.cdr.detectChanges();
    }, error => {
      this.handleError(error);
      this.sort = [];
      this.filters = {};
      this.lazyLoadEvent = null;
      this.tableVisible = true;
      if (this.onTableError !== undefined) {
        this.onTableError(error);
      }
      this.cdr.detectChanges();
    });
  }
  getListOptions() {
    const event = this.lazyLoadEvent;
    const storedFilters = this.stateKey && this.localStorageService.getItem(this.stateKey);
    this.setSorting();
    return {
      search: event.globalFilter ? [event.globalFilter] : this.search,
      sort: this.sort,
      include: this.includes,
      perPage: event.rows || storedFilters?.rows || 10,
      page: Math.floor(event.first / (event.rows || storedFilters?.rows || 10) + 1),
      parameters: this.parameters || null,
      count: this.count,
      // Merge component filters with table filters.
      filters: this.formatFilters({
        ...this.filters,
        ...event.filters
      })
    };
  }
  setSorting() {
    const event = this.lazyLoadEvent;
    const lastSortOptions = {
      field: event.sortField,
      direction: event.sortOrder === -1 ? 'desc' : 'asc'
    };
    const isLastSortOptionsSet = Boolean(event.sortField && event.sortOrder);
    // Merge sorting options when table's sortMode is multiple.
    if (this.table && this.table.sortMode === 'multiple') {
      if (Array.isArray(this.sort) && isLastSortOptionsSet) {
        this.sort = [...this.sort, lastSortOptions];
      }
    } else {
      // Replace default sorting options with lastSortOptions when table's sort mode is single.
      if (isLastSortOptionsSet) {
        this.sort = [lastSortOptions];
      }
    }
  }
  static {
    this.ɵfac = function BaseListComponent_Factory(t) {
      return new (t || BaseListComponent)(i0.ɵɵdirectiveInject(i0.Injector), i0.ɵɵdirectiveInject(i1$1.TranslateService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: BaseListComponent,
      selectors: [["ng-component"]],
      viewQuery: function BaseListComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c0, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.table = _t.first);
        }
      },
      decls: 0,
      vars: 0,
      template: function BaseListComponent_Template(rf, ctx) {},
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BaseListComponent, [{
    type: Component,
    args: [{
      template: ''
    }]
  }], () => [{
    type: i0.Injector
  }, {
    type: i1$1.TranslateService
  }], {
    table: [{
      type: ViewChild,
      args: ['tt']
    }]
  });
})();
class SharedModule {
  static forRoot(config = {}) {
    return {
      ngModule: SharedModule,
      providers: [{
        provide: MessageService,
        useClass: config.messageService || MessageService
      }, {
        provide: ToastService,
        useClass: config.toastService || ToastService
      }]
    };
  }
  static {
    this.ɵfac = function SharedModule_Factory(t) {
      return new (t || SharedModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: SharedModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [MessageService],
      imports: [ConfirmDialogModule, ToastModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SharedModule, [{
    type: NgModule,
    args: [{
      declarations: [BaseDetailComponent, BaseListComponent],
      imports: [ConfirmDialogModule, ToastModule],
      exports: [BaseDetailComponent, BaseListComponent],
      providers: [MessageService]
    }]
  }], null, null);
})();

// tslint:disable-next-line: directive-class-suffix
class DestroyBase {
  constructor() {
    this.destroy$ = new Subject();
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  static {
    this.ɵfac = function DestroyBase_Factory(t) {
      return new (t || DestroyBase)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: DestroyBase
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DestroyBase, [{
    type: Directive
  }], null, null);
})();
class CapturumMissingTranslationHandler {
  handle(params) {
    return `[[ ${params.key} ]]`;
  }
}

/*
  A media type (also known as a Multipurpose Internet Mail Extensions or MIME type) is a standard that indicates the nature and
  format of a document, file, or assortment of bytes.
  Example: <input type="file" accept='image/*, text/*'>
  https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types
*/
var GenericMimeTypes;
(function (GenericMimeTypes) {
  GenericMimeTypes["Application"] = "application/*";
  GenericMimeTypes["Audio"] = "audio/*";
  GenericMimeTypes["Font"] = "font/*";
  GenericMimeTypes["Image"] = "image/*";
  GenericMimeTypes["Model"] = "model/*";
  GenericMimeTypes["Text"] = "text/*";
  GenericMimeTypes["Video"] = "video/*";
  GenericMimeTypes["Multipart"] = "multipart/*";
  GenericMimeTypes["Message"] = "message/*";
})(GenericMimeTypes || (GenericMimeTypes = {}));
var ImageMimeTypes;
(function (ImageMimeTypes) {
  ImageMimeTypes["APNG"] = "image/apng";
  ImageMimeTypes["Bmp"] = "image/bmp";
  ImageMimeTypes["Gif"] = "image/gif";
  ImageMimeTypes["Ico"] = "image/x-icon";
  ImageMimeTypes["Jpeg"] = "image/jpeg";
  ImageMimeTypes["Png"] = "image/png";
  ImageMimeTypes["Svg"] = "image/svg";
  ImageMimeTypes["Tiff"] = "image/tiff";
  ImageMimeTypes["Webp"] = "image/webp";
})(ImageMimeTypes || (ImageMimeTypes = {}));
var AudioMimeTypes;
(function (AudioMimeTypes) {
  AudioMimeTypes["Wave"] = "audio/wave";
  AudioMimeTypes["Wav"] = "audio/wav";
  AudioMimeTypes["XWav"] = "audio/x-wav";
  AudioMimeTypes["XPnWav"] = "audio/x-pn-wav";
  AudioMimeTypes["Webm"] = "audio/webm";
  AudioMimeTypes["Ogg"] = "audio/ogg";
})(AudioMimeTypes || (AudioMimeTypes = {}));
var VideoMimeTypes;
(function (VideoMimeTypes) {
  VideoMimeTypes["Webm"] = "video/webm";
  VideoMimeTypes["Ogg"] = "video/ogg";
})(VideoMimeTypes || (VideoMimeTypes = {}));
var MulitpartMimeTypes;
(function (MulitpartMimeTypes) {
  MulitpartMimeTypes["Message"] = "message";
  MulitpartMimeTypes["Multipart"] = "multipart";
})(MulitpartMimeTypes || (MulitpartMimeTypes = {}));
var DocumentMimeTypes;
(function (DocumentMimeTypes) {
  DocumentMimeTypes["Doc"] = "application/msword";
  DocumentMimeTypes["Docx"] = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
  DocumentMimeTypes["Docm"] = "application/vnd.ms-word.document.macroEnabled.12";
  DocumentMimeTypes["Pdf"] = "application/pdf";
})(DocumentMimeTypes || (DocumentMimeTypes = {}));
var LocalStorageKey;
(function (LocalStorageKey) {
  LocalStorageKey["LastDataFetchedAt"] = "last_data_fetched_at";
  LocalStorageKey["UpdatedKeyData"] = "updated_key_data";
})(LocalStorageKey || (LocalStorageKey = {}));
var OutdatedEntityKey;
(function (OutdatedEntityKey) {
  OutdatedEntityKey["baseData"] = "baseData";
  OutdatedEntityKey["translations"] = "translations";
  OutdatedEntityKey["permissions"] = "permissions";
  OutdatedEntityKey["settings"] = "settings";
})(OutdatedEntityKey || (OutdatedEntityKey = {}));
function concatMimeTypes(mimeTypes) {
  try {
    return mimeTypes.join(',');
  } catch {
    console.error('Failed to concat following mimeTypes:', mimeTypes);
    return undefined;
  }
}
class IndexableServiceHelper {
  static shouldUpdate(updatedKey) {
    const storeValue = IndexableServiceHelper.getStoredValue(LocalStorageKey.UpdatedKeyData);
    return storeValue.hasOwnProperty(updatedKey) ? storeValue[updatedKey] : true;
  }
  static getStoredValue(storageKey) {
    const storedValue = localStorage.getItem(storageKey);
    return !!storedValue ? JSON.parse(storedValue) : {};
  }
  static updateStoredValue(storageKey, updateKey, newValue) {
    const storedValue = IndexableServiceHelper.getStoredValue(storageKey);
    const updatedValue = {
      ...storedValue,
      [updateKey]: newValue
    };
    localStorage.setItem(storageKey, JSON.stringify(updatedValue));
  }
}

/**
 * BootHelper - helper usually designed to support app.module.ts setup with auxiliary needs
 * e.g. callback methods as properties:
 * // boot-helper.ts
 * class BootHelper{
 *   public static onAuthError():void {
 *     // actual implementation
 *   }
 * }
 *
 * // app.module.ts
 * @NgModule({
 *   ...
 *   onAuthError: BootHelper.onAuthError,
 *   ....
 * })
 */
class BootHelper {
  static throwIfAlreadyLoaded(parentModule, moduleName) {
    if (parentModule) {
      throw new Error(`${moduleName} has already been loaded. Import ${moduleName} modules in the AppModule only.`);
    }
  }
  static onAuthError(href = '/auth/login') {
    window.location.href = href;
  }
}

// @dynamic
class ConverterHelper {
  static listToOption(items) {
    return items?.map(item => {
      return {
        label: item.label,
        value: item.key
      };
    });
  }
  static customObjectToOption(response, label = ['name'], value = 'id', delimiter = ' ') {
    return response?.map(item => ({
      label: label.reduce((acc, labelField) => acc += `${item[labelField] ? item[labelField] + delimiter : ''}`, '').slice(0, -delimiter.length),
      value: item[value]
    }));
  }
  static indexToOption(response, label = 'name', value = 'id') {
    return response?.data.map(item => {
      return {
        label: item[label],
        value: item[value]
      };
    });
  }
}

// @dynamic
class FormHelper {
  static checkValidity(formGroup) {
    if (formGroup.invalid) {
      FormHelper.markAsTouched(formGroup);
    }
    return formGroup.valid;
  }
  static markAsTouched(formGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({
          onlySelf: true
        });
      } else if (control instanceof FormGroup) {
        this.markAsTouched(control);
      } else if (control instanceof FormArray) {
        control.controls.forEach(arrayControl => {
          this.markAsTouched(arrayControl);
        });
      }
    });
  }
  static validFormControls(form, validatedControls) {
    return validatedControls.every(controlName => form.controls[controlName].valid);
  }
  static resetFormArray(formArray) {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
    return formArray;
  }
  static isEqualObjects(value, comparableValue) {
    return JSON.stringify(value) === JSON.stringify(comparableValue);
  }
  static getChangedPropertyKey(data, savedData) {
    const changedProperty = [];
    Object.keys(data).map(key => {
      if (data[key] !== savedData[key]) {
        changedProperty.push(key);
      }
    });
    return changedProperty || null;
  }
}
class GeolocationHelper {
  static getGeoData() {
    if (navigator.geolocation) {
      return new Observable(observer => {
        navigator.geolocation.getCurrentPosition(position => {
          const lat = position.coords.latitude;
          const lon = position.coords.longitude;
          observer.next({
            lat,
            lon
          });
        }, () => {
          observer.error();
        });
      });
    }
  }
}

/*
 * Public API Surface of shared
 */
/* Core */

/**
 * Generated bundle index. Do not edit.
 */

export { AudioMimeTypes, BaseDetailComponent, BaseListComponent, BootHelper, CapturumMissingTranslationHandler, ConverterHelper, DestroyBase, DocumentMimeTypes, FormHelper, GenericMimeTypes, GeolocationHelper, ImageMimeTypes, IndexableServiceHelper, LocalStorageKey, MulitpartMimeTypes, OutdatedEntityKey, SharedModule, SharedService, ToastService, VideoMimeTypes, concatMimeTypes };
