import { Directive, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@core/services/notification.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[copy-clipboard]',
  standalone: true,
})
export class CopyClipboardDirective {
  @Input('copy-clipboard') public payload: string;
  @Output() public copied: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private notificationService: NotificationService,
    private translateService: TranslateService,
  ) {}

  @HostListener('click', ['$event']) public onClick(event: MouseEvent): void {
    event.preventDefault();
    if (!this.payload) {
      return;
    }

    const listener = (e: ClipboardEvent) => {
      const clipboard = e.clipboardData || window['clipboardData'];

      clipboard.setData('text', this.payload.toString());
      e.preventDefault();

      this.notificationService.success(
        this.translateService.instant('general.success'),
        this.translateService.instant('toast.url-copied-to-clipboard.success'),
      );

      this.copied.emit(this.payload);
    };

    document.addEventListener('copy', listener, false);
    document.execCommand('copy');
    document.removeEventListener('copy', listener, false);
  }
}
